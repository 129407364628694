import { Modal } from "react-bootstrap";

const PopupModal = ({ show, handleClose, children, className, backdrop }) => {
  return (
    <Modal show={show} onHide={handleClose} centered className={className} backdrop={backdrop}>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default PopupModal;
