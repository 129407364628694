
import { Image } from "react-bootstrap";
import { Icon } from "@iconify/react";
import "./UserProfile.css";
import PrimaryBtn from "../../Components/CommonBtn/PrimaryBtn";
import ProfileData from "./ProfileData";
import { useDispatch, useSelector } from "react-redux";
import { userDataSelector } from "../../Redux/Slices/userDataSlice";
import { subDataSelector } from "../../Redux/Slices/subscriptionDataSlice";
import { useEffect, useState } from "react";
import axios from "axios";
import { ProfilePicture } from "../../Components/ProfilePicture/ProfilePicture";
import { t } from "i18next";
import { CancelSub } from "../../Utils/Utils";
import { showPopUpAction } from "../../Redux/Slices/loginPopUpSlice";

function UserProfile({ isPublicProfile }: { isPublicProfile: boolean }): JSX.Element {
  const userData = useSelector(userDataSelector);
  const subData = useSelector(subDataSelector);
  const [subName, setSubName] = useState("");

  // Public profile data
  const urlName = window.location.pathname.split("/")[2];
  const [userId, setUserId] = useState(0);

  useEffect(() => {
    if (isPublicProfile) {
      axios.post(import.meta.env.VITE_SERVER_ADDRESS + "getUserId", urlName).then((resp) => {
        setUserId(resp.data as number);
      });
      return;
    }
    const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
    if (!subData) {
      return;
    }
    const data = subData.SubscriptionLevel;
    axios.post(import.meta.env.VITE_SERVER_ADDRESS + "getSubName", data.toString(), config).then((resp) => {
      const currentSubName = resp.data as string;
      setSubName(currentSubName);
    });
  }, [subData, urlName]);

  const dispatch = useDispatch();
  function OnOpenLoginPopup() {
    dispatch(showPopUpAction(true));
  }
  return (
    <>
      <div className="MainContent d-margin padding-16">
        <span className="top-left-bg"> <Image src="/assets/images/top-left-bg.svg" /> </span>
        <span className="top-right-bg"> <Image src="/assets/images/top-right-bg.svg" /> </span>
        <span className="bottom-left-bg"> <Image src="/assets/images/bottom-left-bg.svg" /> </span>
        <span className="bottom-right-bg"> <Image src="/assets/images/bottom-right-bg.svg" /> </span>
        <div className="profileTitle-wrap">
          <div className='userprofileWrap'>
            <div className='userprofile-img'>
              {isPublicProfile ? <ProfilePicture userId={userId} /> : <ProfilePicture />}
            </div>
            <div className='userprofile-info'>
              {isPublicProfile ? <h6>{urlName}</h6> : <h6>{userData.Name}<span>{subName}</span></h6>}
              {/* <p>{t("create_your_personalize_ai_companion_that_can_boos")}</p> */}
            </div>
          </div>
          <div className='profileCreateai'>
            {/* <p>3/ <span>3</span></p> */}
            {localStorage.getItem("oauthToken") ?
              <PrimaryBtn child={<>{t("create_ai")} <i><Icon icon="heroicons:sparkles-solid" /></i></>} className={""} linkName={"/createaicompanion"} onClick={() => { }} /> : <PrimaryBtn child={<>{t("create_ai")} <i><Icon icon="heroicons:sparkles-solid" /></i></>} className={""} linkName={"/"} onClick={OnOpenLoginPopup} />
            }

          </div>
        </div>
        {subData.SubscriptionLevel > 0 ?
          (subData.Recurrent ?
            <div>
              <h6>On a recurrent Subscription provided by {subData.DirectDebitProvider}</h6>
              <PrimaryBtn child={<>Cancel Subscription <i><Icon icon="heroicons:sparkles-solid" /></i></>} className={""}
                linkName={"/"} onClick={CancelSub} />
            </div>
            :
              <h6>Your subscription will expire on {new Date(subData.SubscriptionExpDate).toISOString().split("T")[0]}</h6>
          )
          : ""
        }
        <hr className="divider-line" />
        <ProfileData isPublicProfile={isPublicProfile} userId={userId} />
      </div>
    </>

  );
}

export default UserProfile;
