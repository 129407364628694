import html2canvas from "html2canvas";


interface RegenerateImageParams {
    index: number;
    prompt: string;
    setAssetGenData: React.Dispatch<React.SetStateAction<any[]>>; 
    setSelectedImage: React.Dispatch<React.SetStateAction<any | null>>; 
    setLoadingStates: React.Dispatch<React.SetStateAction<{ [key: number]: boolean }>>;
    closeModal: () => void;
  }
  
  export const regenerateImage = async ({
    index,
    prompt,
    setAssetGenData,
    setSelectedImage,
    setLoadingStates,
    closeModal,
  }: RegenerateImageParams): Promise<void> => {
    setLoadingStates((prev) => ({ ...prev, [index]: true }));
  
    try {
      const response = await fetch('http://127.0.0.1:5000/generate_custom_image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      if (data.url) {
        setAssetGenData((prevData) =>
          prevData.map((item, idx) =>
            idx === index ? { ...item, url: data.url, prompt } : item
          )
        );
        setSelectedImage((prevSelectedImage: any) => ({ ...prevSelectedImage, url: data.url, prompt }));
        setLoadingStates((prev) => ({ ...prev, [index]: false }));
      }
    } catch (error) {
      console.error('Error regenerating image:', error);
      setLoadingStates((prev) => ({ ...prev, [index]: false }));
    } finally {
      closeModal();
    }
  };
  
  export const copyToClipboard = (url: string): void => {
    navigator.clipboard.writeText(url).then(
      () => alert("Image URL copied to clipboard!"),
      (err) => console.error("Could not copy text: ", err)
    );
  };
  

  export const takeScreenshot = async (): Promise<string> => {
    const iframeElement = document.querySelector(
      "#preview-desktop"
    ) as HTMLIFrameElement;
    if (!iframeElement?.contentWindow?.document.body) {
      return "";
    }

    const canvas = await html2canvas(iframeElement.contentWindow.document.body);
    const png = canvas.toDataURL("image/png");
    return png;
  };
 
  export const downloadCode =  (generatedCode: string) => {
    // Create a blob from the generated code
    const blob = new Blob([generatedCode], { type: "text/html" });
    const url = URL.createObjectURL(blob);

    // Create an anchor element and set properties for download
    const a = document.createElement("a");
    a.href = url;
    a.download = "index.html"; // Set the file name for download
    document.body.appendChild(a); // Append to the document
    a.click(); // Programmatically click the anchor to trigger download

    // Clean up by removing the anchor and revoking the Blob URL
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };