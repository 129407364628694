import { Icon } from "@iconify/react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { t } from "i18next";
import ChatbotVoice from "../../Models/ChatbotVoice";
import { useEffect, useState } from "react";
import {ChatbotData, GetChatbotVoiceByUrl, GetChatbotVoices, UpdateUserChatbot} from '../../Utils/ChatbotUtils';
import { VoiceOption } from "../../Utils/Types";
import { userDataSelector } from "../../Redux/Slices/userDataSlice";
import { useSelector } from "react-redux";
import UserChatbot from "../../Models/UserChatbot";
import Select, { SingleValue, components } from "react-select";
import { languageOptions } from "../../Utils/LanguageList";

function FCompanionsInfoMoreSetting({ chatbotData, selectedModelOption, setSelectedModelOption, modelOptions, initialVoiceParam, setSelectedLanguageOption, selectedLanguageOption}) {

  // const [selectedLanguageOption, setSelectedLanguageOption] = useState({ value: "english", label: t("English") });
  const [voices, setVoices] = useState<VoiceOption[]>([]);
  const [selectedVoice, setSelectedVoice] = useState<ChatbotVoice>();
  const [initialVoice, setInitialVoice] = useState<string>(initialVoiceParam);
  const [systemPrompt, setSystemPrompt] = useState<string>("");
  const userData = useSelector(userDataSelector);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setSystemPrompt(chatbotData.system_prompt);
    //GetChatbotVoiceByUrl(chatbotData.audio_url).then((voice) => { setInitialVoice(voice.Name); });
    if (chatbotData.user_id === undefined || (chatbotData.user_id > 0 && chatbotData.user_id !== userData.UserId))
    {

      GetChatbotVoices().then((downloadedVoices) =>
      {
        const voicesOptions = [] as VoiceOption[];
        for (const voice of downloadedVoices)
        {
          voicesOptions.push({value: voice, label: voice.Name});
        }
        setVoices(voicesOptions);
      });
    }
  }, []);

  function SetCurrentVoice(selectedOption: SingleValue<VoiceOption>) {
    if (selectedOption === null) {
      return;
    }
    const selectedVoice = selectedOption?.value;
    setSelectedVoice(selectedVoice);
  }

  async function SaveChanges() {
    if (systemPrompt !== chatbotData?.system_prompt) {
      chatbotData.system_prompt = systemPrompt;
    }

    if (selectedVoice && selectedVoice?.PlayHtVoice !== chatbotData?.audio_url) {
      chatbotData.audio_url = selectedVoice.PlayHtVoice;
    }
    setIsSaving(true);
    try {
      await UpdateUserChatbot(chatbotData as UserChatbot);
      window.location.reload();
    } catch (error) {
      console.error("Failed to update chatbot:", error);
    }finally {
      setIsSaving(false); // End the save operation
    }
  }
  
  const Option = (props) => (
    <components.Option {...props} className="country-option">
      <Icon icon={props.data.icon} />
      {props.data.label}
    </components.Option>
  );
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <Icon icon={selectedLanguageOption.icon}/>
      {children}
    </components.SingleValue>
  );
  return (
    <div className='FCompanions-Info-more-setting'>
      <Row>
        <Col lg={12}>
          <h4>{t("voice_chat_parameters")}</h4>
          <Form className='Setting-Parameters-Select'>
            <Row>
              <Col lg={4}>
                <Form.Group className='c-form-control mb-lg-0 mb-2 voiceselectmain select-1'>
                  <Form.Label>{t("language")}</Form.Label>
                  <Select
                    defaultValue={selectedLanguageOption}
                    onChange={setSelectedLanguageOption}
                    options={languageOptions}
                    styles={{
                      singleValue: (base) => ({
                        ...base,
                         "&:hover": {
                          backgroundColor: "#1a1a1a"
                        },
                        "&:focus": {
                          backgroundColor: "#1a1a1a"
                        },
                        display: "flex",
                        alignItems: "center",
                        gap: '10px',
                        color : '#fff'
                      }),
                      option: (base) => ({
                        ...base,
                        "&:hover": {
                          backgroundColor: "#1a1a1a"
                        },
                        "&:focus": {
                          backgroundColor: "#1a1a1a"
                        },
                        display: "flex",
                        alignItems: "center",
                        gap: '10px',
                        color : '#fff'
                        
                      }),
                    }}
                    components={{
                      Option,
                      SingleValue
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className='c-form-control mb-lg-0 mb-2 voiceselectmain select-2'>
                  <Form.Label>{t("voice")}</Form.Label>
                  <Select
                    isClearable={false}
                    options={voices}
                    isSearchable={false}
                    placeholder={initialVoiceParam}
                    onChange={SetCurrentVoice}
                    isDisabled={chatbotData?.user_id === undefined || (chatbotData?.user_id > 0 && chatbotData?.user_id !== userData.UserId)}
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className='c-form-control mb-lg-0 mb-2 voiceselectmain select-3'>
                  <Form.Label>{t("model")}</Form.Label>
                  <Select
                    defaultValue={selectedModelOption}
                    onChange={setSelectedModelOption}
                    options={modelOptions}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>

      </Row>
      <hr />
      <div className='mb-2 Superenhance-wrap'>
        <h4>Character prompt</h4>
        <h6>{t("superenhance")} <Form.Check className='custom-switch' type="switch" id="custom-switch" /></h6>
      </div>
      <Form.Group className='c-form-control mb-4'>
        <Form.Control as="textarea" rows={6} value={chatbotData?.user_id > 0 ? systemPrompt : chatbotData.long_description} disabled={chatbotData?.user_id === undefined || (chatbotData?.user_id > 0 && chatbotData.user_id !== userData.UserId)} onChange={(event) => setSystemPrompt(event.target.value)} />
      </Form.Group>
      {chatbotData?.user_id !== undefined && chatbotData?.user_id === userData.UserId &&
        <><Button className={"FCompanionsSettingSave"} onClick={SaveChanges} disabled={isSaving}><>{t("save_settings")}  <i><Icon icon="heroicons:sparkles-solid" /></i></></Button></>
      }
    </div>
  );
}

export default FCompanionsInfoMoreSetting;
