import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { t } from "i18next";
import { Trans } from "react-i18next";
import axios from "axios";

function UserNamePopup(props: any) {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [username, setUsername] = useState("");
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedImage(event.target.files?.[0] || null);
  };

  useEffect(() => {
    // clean-up function
    return () => {
      // revoke the data uris to avoid memory leaks
      if (selectedImage instanceof Blob) {
        URL.revokeObjectURL(URL.createObjectURL(selectedImage));
      }
    };
  }, [selectedImage]);

  const onSubmit = async () => {
    try {
      // Get auth token from local storage
      const oauthToken = localStorage.getItem("oauthToken");

      // Initialized headers
      const config = {
        headers: {
          "Authorization": `Bearer ${oauthToken}`
        }
      };

      // Check if selectedImage is not null or undefined
      if (selectedImage) {
        config.headers["Content-Type"] = selectedImage.type; // set the specific file type
        const formData = new FormData();
        formData.append("file", selectedImage); // append the selected file
        await axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}uploadFile`, formData, config);
      }

      // Only send the username if it is not an empty string
      if (username.trim() !== "") {
        const response = await axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}updateUserDetails`, {
          name: username,
        }, config);

        if (response.status === 200) {
          onNextStep();
        } else {
          // handle other status codes appropriately
        }
      } else {
        onNextStep(); // If username is empty, proceed to the next step
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        alert("Username already in use");
      } else {
        console.error(error);
      }
    }
  };


  const onNextStep = () => {
    props.nextStep(props);
  };
  const defaultImageUrl = "https://storage.googleapis.com/factories_orbofi/green-2024-01-18_16-40-44.png";
  return (
    <div className="UsernamePopup">
      <div className="login-info">
        <span><Image src="/assets/icons/orbofi-icon-white.svg" /></span>
        <h5><Trans
          i18nKey="create_your_username_and_password"
          components={{
            b: <br />
          }}
        /></h5>
        {/* <p>{t('choose_wisely_because_you_cant_change_your_name')}</p> */}
      </div>
      <Form className="UsernamePopup-form">
        <label htmlFor="UserProifle" className="UserPopupImgUpload">
          <input type="file" id="UserProifle" name="UserProifle" accept="image/*"
            onChange={handleImageChange} />
          <span><Icon icon="basil:edit-outline" /></span>
          <div className="UserProifleImg">
            {selectedImage ? (
              <img src={selectedImage ? URL.createObjectURL(selectedImage) : defaultImageUrl}
                alt={t("uploaded")} />
            ) : (
              <lord-icon
                src="https://cdn.lordicon.com/rehjpyyh.json"
                trigger="loop"
              >
              </lord-icon>
            )}
          </div>
        </label>
        <Form.Group className='c-form-control'>
          <Form.Label>{t("username")}</Form.Label>
          <div className='input-icon'>
            <i><Icon icon="lucide:user" /></i>
            <Form.Control
              type="text"
              placeholder="Enter your username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
        </Form.Group>
        <Button variant="" onClick={onSubmit} className="btn-primary btn-full">{t("continue")}</Button>
      </Form>
    </div>
  );
}

export default UserNamePopup;
