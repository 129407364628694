import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CategoriesBox.css";
import { t } from "i18next";
function CategoriesBox() {


  return (
    <Row>
      <Col lg={4}>
        <Link
          to="/search-category?subcat=24"
          className='CategoriesBox-item'
        >
          <div className="CategoriesBox-Img"><Image src="/images/world-box.jpg" /></div>
          <div className="CategoriesBox-item-info">
            <h5>{t("ai_clone_of_every_person")}</h5>
          </div>
        </Link>
      </Col>
      <Col lg={4}>
        <Link to="/search-category?subcat=17"
          className='CategoriesBox-item CategoriesBox-item-pink'>
          <div className="CategoriesBox-Img"><Image src="/images/anime-box.jpg" /></div>
          <div className="CategoriesBox-item-info">
            <h5>{t("ai_clone_of_every_Anime_characater")}</h5>
          </div>

        </Link>
      </Col>
      <Col lg={4}>
        <Link to="/search-category?subcat=16"
          className='CategoriesBox-item CategoriesBox-item-yellow'>
          <div className="CategoriesBox-Img"><Image src="/images/fiction-box.jpg" /></div>
          <div className="CategoriesBox-item-info">
            <h5>{t("ai_clone_of_every_fiction_characater")}</h5>
          </div>
        </Link>
      </Col>

    </Row>
  );
}

export default CategoriesBox;
