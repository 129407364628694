import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./CompanionsBox.css";
import { t } from "i18next";
import {ChatbotData, SerializeChatbot, ToggleFavouriteChatbot} from "../../Utils/ChatbotUtils";
import { useEffect, useState } from "react";
import SmoothImage from 'react-smooth-image';
import {updateDirtyFlags} from "../../Redux/Slices/dirtyFlagsSlice";
import {useDispatch} from "react-redux";

function CompanionsBox({ chatbotData }: { chatbotData: ChatbotData }) {

  if (chatbotData?.description) {
    chatbotData.description = chatbotData.description.substring(0, 100) + (chatbotData.description.length > 100 ? '...' : '');
  }

  const [isActive, setIsActive] = useState(false);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1330);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="FCompanions-Main w-100">
      {loading ? (
        <Link to={`/chat/${chatbotData?.string_id}`} >
          <div className="FCompanions-Img skeleton">
            <Image src={chatbotData?.img} className="opacity-0" />
          </div>
          <div className="FCompanions-Info">
            <div className="FCompanions-Info-Title">
              <div className="skeleton ">
                <h6 className="opacity-0">{chatbotData?.name}</h6>
              </div>
              <div className="skeleton ml-auto">
                <Link to="javascript:void(0)" onClick={()=>{ToggleFavouriteChatbot(chatbotData).then((isFav)=>{setIsActive(isFav); dispatch(updateDirtyFlags({ FavouriteChatbotsDirty: true }));} );}} className={`FavoriteLink opacity-0 favicon ${isActive ? 'active' : ''}`}> <Icon icon={isActive ? "mingcute:star-fill" : "mingcute:star-line"} /></Link>
                <Link to={`/chat/${chatbotData?.string_id}`} className="opacity-0"><Icon icon="ep:top-right" /></Link>
              </div>
            </div>
            <div className="skeleton ">
              <p className="opacity-0">{chatbotData?.description || "build in seconds any kind of voice-powered AI agents and AI clones"}</p>
            </div>
            <div className="FCompanions-Wrap">
              <div className="skeleton ">
                <pre className="opacity-0"><span><Image src="/images/OrboxIcon.jpg" /></span>Orbofi</pre>
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <Link to={`/chat/${chatbotData?.string_id}`}>
          <div className="FCompanions-Img">
            <SmoothImage src={chatbotData?.img} transitionTime={1.0} />
          </div>
          <div className="FCompanions-Info">
            <div className="FCompanions-Info-Title">
              <h6>{chatbotData?.name}</h6>
              <Link to="javascript:void(0)" onClick={()=>{ToggleFavouriteChatbot(chatbotData).then((isFav)=>{setIsActive(isFav); dispatch(updateDirtyFlags({ FavouriteChatbotsDirty: true }));} );}} className={`FavoriteLink favicon ${isActive ? 'active' : ''}`}> <Icon icon={isActive ? "mingcute:star-fill" : "mingcute:star-line"} /></Link>
              <Link to={`/chat/${chatbotData?.string_id}`}><Icon icon="ep:top-right" /></Link>
            </div>

            <p style={{ "fontSize": "11.1px" }}>{chatbotData?.description}</p>
          </div>
        </Link>
      )}
    </div>
  );
}

export default CompanionsBox;
