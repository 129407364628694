import React, { useEffect, useRef, useState } from "react";
import "./MychatbotPage.css";
import ChatbotsList from "../ChatbotsPage/ChatbotsList";
import { Image, Nav, Tab } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { ChatbotData, GetCurrentChatbot, GetFavouriteChatbotList, GetLocalUserChatbots, GetPremiumChatbots, GetRecentChatbotList, RecentChatbots } from "../../Utils/ChatbotUtils";
import { subDataSelector } from "../../Redux/Slices/subscriptionDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { dirtyFlagsSelector, updateDirtyFlags } from "../../Redux/Slices/dirtyFlagsSlice";
import { userDataSelector } from "../../Redux/Slices/userDataSlice";
import User from "../../Models/User";
import { Link } from "react-router-dom";

function MychatbotPage() {
    const subData = useSelector(subDataSelector);
    const [premiumChatbots, setPremiumChatbots] = useState<ChatbotData[]>([]);
    const [favouriteChatbots, setFavouriteChatbots] = useState<RecentChatbots[]>([]);
    const [userChatbots, setUserChatbots] = useState<ChatbotData[]>([]);
    const [currentChatbot, setCurrentChatbot] = useState<RecentChatbots>();
    const [recentChatbots, setRecentChatbots] = useState<RecentChatbots[]>([]);
    const dirtyFlags = useSelector(dirtyFlagsSelector);
    const dispatch = useDispatch();
    const userData: User = useSelector(userDataSelector);

    useEffect(() => {
        if (dirtyFlags.UserChatbotsDirty) {
            if (userData?.UserId > 0) {
                GetLocalUserChatbots().then((chatbots) => {
                    dispatch(updateDirtyFlags({ UserChatbotsDirty: false }));
                    setUserChatbots(chatbots.reverse());
                });
            }
            else {
                setUserChatbots([]);
            }
        }
    }, [userData, dirtyFlags.UserChatbotsDirty]);

    const getFavouriteChatbots = () => {
        if (userData?.UserId > 0) {
            GetFavouriteChatbotList().then((chatbots) => {
                dispatch(updateDirtyFlags({ FavouriteChatbotsDirty: false }));
                setFavouriteChatbots(chatbots);
                dispatch(updateDirtyFlags({ HaveFavouriteBotsLoaded: true }));
            });
        }
        else {
            setFavouriteChatbots([]);
        }
    };
    useEffect(() => {
        GetPremiumChatbots().then((chatbots) => { setPremiumChatbots(chatbots); });
    }, []);

    // Get favourite chatbots on dirty flag change
    useEffect(() => {
        if (dirtyFlags.FavouriteChatbotsDirty) {
            getFavouriteChatbots();
        }
    }, [dirtyFlags.FavouriteChatbotsDirty]);

    useEffect(() => {
        if (dirtyFlags.RecentChatbotsDirty) {
            if (userData?.UserId > 0) {
                GetRecentChatbotList().then((chatbots) => {
                    dispatch(updateDirtyFlags({ RecentChatbotsDirty: false }));
                    setRecentChatbots(chatbots.reverse());
                });
            }
            else {
                setRecentChatbots([]);
            }
        }
    }, [dirtyFlags.RecentChatbotsDirty]);

    useEffect(() => {
        if (dirtyFlags.CurrentChatbotDirty) {
            if (userData?.UserId > 0) {
                GetCurrentChatbot().then((chatbot) => {
                    dispatch(updateDirtyFlags({ CurrentChatbotDirty: false }));
                    setCurrentChatbot(chatbot);
                }
                );
            }
            else {
                setCurrentChatbot(undefined);
            }
        }
    }, [dirtyFlags.CurrentChatbotDirty]);
    return (
        <div className="MainContent d-margin padding-16">
            <span className="top-left-bg"> <Image src="/assets/images/top-left-bg.svg" /> </span>
            <span className="top-right-bg"> <Image src="/assets/images/top-right-bg.svg" /> </span>
            <span className="bottom-left-bg"> <Image src="/assets/images/bottom-left-bg.svg" /> </span>
            <span className="bottom-right-bg"> <Image src="/assets/images/bottom-right-bg.svg" /> </span>
            <div className="titleText mb-0 text-start MychatbotPageTitle">
                <h2 className="text-start">My chatbots</h2>
            </div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav variant="pills" className="c-tabs chatbotList-tabs MychatbotPageTabs">
                    <Nav.Item>
                        <Nav.Link eventKey="first">Favourite</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="third">Recent</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content className="mt-3">
                    <Tab.Pane eventKey="first">
                        <ul className="MychatbotListWrap userChatbotWrapProfile">
                            {subData?.SubscriptionLevel === 3 && premiumChatbots.filter(chatbot => chatbot.id === 1).map((chatbot) => (
                                <li key={chatbot.chatbot_id} className="MychatbotListItem">
                                    <a href={`/chat/${chatbot.name}?id=${chatbot.chatbot_id}`} >
                                        <div className="CompanionShorts-Main">
                                            <div className="CompanionShorts-Img"><Image src={chatbot.img} /></div>
                                            <div className="CompanionShorts-Info">
                                                <div className="CompanionShorts-Info-Title">
                                                    <h6>{chatbot.name} <i><Icon icon="material-symbols:star" /></i></h6>
                                                </div>
                                                <p>{chatbot.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            ))}
                            {localStorage.getItem("oauthToken") && favouriteChatbots?.map((chatbot) => (
                                <li key={chatbot.chatbot_id} className="MychatbotListItem">
                                    {console.log(chatbot)}
                                    {chatbot.user_created ? (
                                        <a href={`/chat/${chatbot.name}?id=${chatbot.chatbot_id}`} >
                                            <div className="CompanionShorts-Main">
                                                <div className="CompanionShorts-Img"><Image src={chatbot.img} /></div>
                                                <div className="CompanionShorts-Info">
                                                    <div className="CompanionShorts-Info-Title">
                                                        <h6>{chatbot.name} <i><Icon icon="material-symbols:star" /></i></h6>
                                                    </div>
                                                    <p>{chatbot.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    ) : (
                                        <a href={`/chat/${chatbot.string_id}`} >
                                            <div className="CompanionShorts-Main">
                                                <div className="CompanionShorts-Img"><Image src={chatbot.img} /></div>
                                                <div className="CompanionShorts-Info">
                                                    <div className="CompanionShorts-Info-Title">
                                                        <h6>{chatbot.name} <i><Icon icon="material-symbols:star" /></i></h6>
                                                    </div>
                                                    <p>{chatbot.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    )}
                                </li>
                            ))}
                            {localStorage.getItem("oauthToken") && userChatbots?.map((chatbot) => (
                                <li key={chatbot.id} className="MychatbotListItem">
                                    <a href={`/chat/${chatbot.name}?id=${chatbot.id}`} >
                                        <div className="CompanionShorts-Main">
                                            <div className="CompanionShorts-Img"><Image src={chatbot.img} /></div>
                                            <div className="CompanionShorts-Info">
                                                <div className="CompanionShorts-Info-Title">
                                                    <h6>{chatbot.name} <i><Icon icon="material-symbols:star" /></i></h6>
                                                </div>
                                                <p>{chatbot.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                        <ul className="MychatbotListWrap userChatbotWrapProfile">
                            {localStorage.getItem("oauthToken") && currentChatbot && !recentChatbots?.includes(currentChatbot) && (
                                <li key={currentChatbot.chatbot_id} className="MychatbotListItem">
                                    {currentChatbot.user_created ?
                                        <a href={`/chat/${currentChatbot.name}?id=${currentChatbot.chatbot_id}`}>
                                            <div className="CompanionShorts-Main">
                                                <div className="CompanionShorts-Img"><Image src={currentChatbot.img} /></div>
                                                <div className="CompanionShorts-Info">
                                                    <div className="CompanionShorts-Info-Title">
                                                        <h6 className="mb-0 me-auto">{currentChatbot.name}</h6>
                                                        <Link to="#"><Icon icon="ep:top-right" /></Link>
                                                    </div>
                                                    <p>{currentChatbot.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                        :
                                        <a href={`/chat/${currentChatbot.string_id}`}>
                                            <div className="CompanionShorts-Main">
                                                <div className="CompanionShorts-Img"><Image src={currentChatbot.img} /></div>
                                                <div className="CompanionShorts-Info">
                                                    <div className="CompanionShorts-Info-Title">
                                                        <h6 className="mb-0 me-auto">{currentChatbot.name}</h6>
                                                        <Link to="#"><Icon icon="ep:top-right" /></Link>
                                                    </div>
                                                    <p>{currentChatbot.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    }
                                </li>
                            )}
                            {localStorage.getItem("oauthToken") && recentChatbots?.map((chatbot) => (
                                <li key={chatbot.chatbot_id}>
                                    {chatbot.user_created ?
                                        <a href={`/chat/${chatbot.name}?id=${chatbot.chatbot_id}`} >
                                            <div className="CompanionShorts-Main">
                                                <div className="CompanionShorts-Img"><Image src={chatbot.img} /></div>
                                                <div className="CompanionShorts-Info">
                                                    <div className="CompanionShorts-Info-Title">
                                                        <h6 className="mb-0 me-auto">{chatbot.name}</h6>
                                                        <Link to="#"><Icon icon="ep:top-right" /></Link>
                                                    </div>
                                                    <p>{chatbot.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                        :
                                        <a href={`/chat/${chatbot.string_id}`} >
                                            <div className="CompanionShorts-Main">
                                                <div className="CompanionShorts-Img"><Image src={chatbot.img} /></div>
                                                <div className="CompanionShorts-Info">
                                                    <div className="CompanionShorts-Info-Title">
                                                        <h6 className="mb-0 me-auto">{chatbot.name}</h6>
                                                        <Link to="#"><Icon icon="ep:top-right" /></Link>
                                                    </div>
                                                    <p>{chatbot.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    }
                                </li>
                            ))}
                        </ul>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
            {/* <ChatbotsList chatbots={chatbots} loadMore={loadMore} records={records} hasMore={hasMore} /> */}
        </div>
    );
}

export default MychatbotPage;
