import { Link } from "react-router-dom";
import Products from "../../../../Models/Products";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { ChatbotData, GetPremiumChatbots } from "../../../../Utils/ChatbotUtils";
import CompanionCard from "../../../../Components/Cards/CompanionCard";

function PremiumProductCard({ paymentToggle, productDetails, setProduct }: { paymentToggle: () => void, productDetails: Products, setProduct: Dispatch<SetStateAction<Products>> }): JSX.Element {
  function setProductToCheckout() {
    setProduct(productDetails);
    paymentToggle();
  }
  return (
    <div className="PricingCardMain">
      <div className="PricingCardHead">
        <h6 className="text-neon mb-1">{productDetails.Name}</h6>
        <h3><i>$</i>{productDetails.Price} <span>USD</span>{productDetails.Level === 3 ? <><del style={{ fontStyle: 'italic', color: 'red', fontSize: '28px' }}>40 USD</del><small style={{ color: 'red', fontSize: '16px' }}>End in 7 Days</small></> : ''} </h3>
        {productDetails.Level === 3 ? '' : ''}
      </div>
      <ul className="PricingCardBody">
        {productDetails.FeaturesArray.map((featureDescription, index) =>
        (
          <li key={index}><i><Icon icon="tabler:check" /></i> {featureDescription}</li>
        )
        )}
      </ul>
      <div className="PricingCardFooter">
        <Link to="javascript:void(0)" onClick={setProductToCheckout} className="btn-primary btn-buy-product"><i className="mb-0"><lord-icon src="https://cdn.lordicon.com/colzhtfe.json" colors="primary:#ffffff,secondary:#ffffff" trigger="loop"></lord-icon></i> Buy {productDetails.Name}</Link>
      </div>
    </div>
  );
}

function PremiumProducteBox({ paymentToggle, premiumProducts, setProduct }: { paymentToggle: () => void, premiumProducts: Products[], setProduct: Dispatch<SetStateAction<Products>> }) {
  const [selectedPremium, setSelectedPremium] = useState<ChatbotData>("");
  const [premiumChatbots, setPremiumChatbots] = useState<ChatbotData[]>([]);
  const pricingRef = useRef(null);
  useEffect(() => {
    GetPremiumChatbots().then((chatbots) => { setPremiumChatbots(chatbots); });
  }, []);
  const handleScrollToPricing = () => {
    const offset = -150; // Adjust this value to your needs
    const element = pricingRef.current;
    const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset + offset;

    window.scrollTo({ top: yCoordinate, behavior: 'smooth' });
  };
  return (
    <>
      <div className="d-flex chatbotmain">
        <Row className="justify-content-center">
          {!selectedPremium && premiumChatbots.map((chatbot, index) => index === 0 && (
            <>
              <Link key={chatbot.chatbot_id} className="col-lg-3 col-md-4 col-sm-7" style={{position: 'relative'}} to="#" onClick={() => setSelectedPremium(chatbot)}>
                <span className="premioumbtn"><Icon icon="fluent:checkmark-starburst-16-filled" /></span>
                <CompanionCard chatbotData={chatbot} />
              </Link>
              <Col lg={3} md={4} sm={6} xs={6}>
                <Link to="#" className="CommingSoonChatbot">
                  <lord-icon
                    src="https://cdn.lordicon.com/getzhpti.json"
                    trigger="hover"
                    colors="primary:#ffffff,secondary:#ffffff">
                  </lord-icon>
                </Link>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6}>
                <Link to="#" className="CommingSoonChatbot">
                  <lord-icon
                    src="https://cdn.lordicon.com/getzhpti.json"
                    trigger="hover"
                    colors="primary:#ffffff,secondary:#ffffff">
                  </lord-icon>
                </Link>
              </Col>
            </>
          ))}
        </Row>
      </div>

      {selectedPremium ? (
        <>
          <div className="DannyPriceWrapBox py-5">
            <div className="DannyPriceVideo">
              <video
                src="https://orbofi-web-test.s3.ap-southeast-1.amazonaws.com/Danny+x+Orbofi+2+Version+7.0.mp4"
                type="video/mp4"
                autoPlay
                controls
                loop
                playsInline
                preload="auto"
              />
            </div>
            <div className="DannyPriceVideoInfo">
              <h3>The most badass AI companion</h3>
              <p>One of the most advanced AI agents, and the most badass one, voiced by Danny Trejo himself</p>
              <Link to="#" onClick={handleScrollToPricing} className="btn-primary justify-content-center btn-buy-product"><i><lord-icon src="https://cdn.lordicon.com/colzhtfe.json" colors="primary:#ffffff,secondary:#ffffff" trigger="loop"></lord-icon></i>Get Yours Now</Link>
            </div>
          </div>
          <Row className="my-5">
            <Col lg={4} md={6} sm={6}>
              <div className="DannyProductbenefitCard">
                <i><lord-icon src="https://cdn.lordicon.com/rvrpukwa.json" trigger="loop"></lord-icon></i>
                <h6>search, get answers, and generate anything</h6>
                <p>Same way Ironman is having his Jarvis, you're having your smart AI companion, as Danny Trejo, with his real voice</p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={6}>
              <div className="DannyProductbenefitCard">
                <i><lord-icon src="https://cdn.lordicon.com/klauvpet.json" trigger="loop"></lord-icon></i>
                <h6>Powerful Image generation</h6>
                <p>Generate HQ images using the most powerful image generation models</p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={6}>
              <div className="DannyProductbenefitCard">
                <i><lord-icon src="https://cdn.lordicon.com/momsnocb.json" trigger="loop" ></lord-icon></i>
                <h6>Multimodality</h6>
                <p>Your Danny AI is powered by State-of-art AI models, infused with personality and voice</p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={6}>
              <div className="DannyProductbenefitCard">
                <i><lord-icon src="https://cdn.lordicon.com/fmjvulyw.json" trigger="loop" colors="primary:#121331,secondary:#ebe6ef,tertiary:#3a3347,quaternary:#d59f80,quinary:#f9c9c0,senary:#f24c00" ></lord-icon></i>
                <h6>Vision Power</h6>
                <p>send any kind of image, ask about them, and get answers</p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={6}>
              <div className="DannyProductbenefitCard">
                <i><lord-icon src="https://cdn.lordicon.com/diaztnay.json" colors="primary:#ffffff,secondary:#f9c9c0" trigger="loop" ></lord-icon></i>
                <h6>Voice-powered and Live experience</h6>
                <p>Experience AI generation and interaction in a voice-powered live experience, on mobile or desktop</p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={6}>
              <div className="DannyProductbenefitCard">
                <i><lord-icon src="https://cdn.lordicon.com/lsrcesku.json" trigger="loop" colors="primary:#b4b4b4,secondary:#ffffff,tertiary:#ffc738,quaternary:#ebe6ef"></lord-icon></i>
                <h6>Productivity enabled</h6>
                <p>Use it for productive tasks such as generating long-form text, generate code and websites, and analyze documents</p>
              </div>
            </Col>
          </Row>
          <Row className="dannyPricingCards py-5" id="dannyPricingCards" ref={pricingRef}>
            {premiumProducts.map((product, index) => (
              <Col lg={4} md={6} key={index}>
                <PremiumProductCard paymentToggle={paymentToggle} productDetails={product} setProduct={setProduct} />
              </Col>
            ))}
          </Row>
        </>
      )
        : ""}

    </>
  );
}

export default PremiumProducteBox;