export const languageOptions = [
    { value: "english", label: "English", msLangCode: "en-US", icon: "twemoji:flag-us-outlying-islands" },
    { value: "mandarin", label: "Mandarin", msLangCode: "zh-CN" , icon : "emojione-v1:flag-for-china" },
    { value: "spanish", label: "Spanish", msLangCode: "es-ES" , icon : "emojione-v1:flag-for-spain" },
    { value: "hindi", label: "Hindi", msLangCode: "hi-IN" , icon : "twemoji:flag-india" },
    { value: "french", label: "French", msLangCode: "fr-FR" , icon : "twemoji:flag-france" },
    { value: "arabic", label: "Arabic", msLangCode: "ar-SA" , icon : "emojione-v1:flag-for-saudi-arabia" },
    // { value: "bengali", label: "Bengali", msLangCode: "bn-IN" , icon : },
    { value: "russian", label: "Russian", msLangCode: "ru-RU" , icon : "emojione-v1:flag-for-russia" },
    { value: "portuguese", label: "Portuguese", msLangCode: "pt-BR" , icon : "twemoji:flag-portugal" },
    { value: "indonesian", label: "Indonesian", msLangCode: "id-ID" , icon : "twemoji:flag-indonesia" },
    { value: "japanese", label: "Japanese", msLangCode: "ja-JP" , icon : "twemoji:flag-japan" },
    { value: "german", label: "German", msLangCode: "de-DE" , icon : "twemoji:flag-germany" },
    { value: "korean", label: "Korean", msLangCode: "ko-KR" , icon : "emojione-v1:flag-for-south-korea" },
    { value: "italian", label: "Italian", msLangCode: "it-IT" , icon : "emojione-v1:flag-for-italy" },
    { value: "turkish", label: "Turkish", msLangCode: "tr-TR" , icon : "emojione-v1:flag-for-turkey" },
    // { value: "vietnamese", label: "Vietnamese", msLangCode: "vi-VN" , icon : },
    // { value: "thai", label: "Thai", msLangCode: "th-TH" , icon : },
    { value: "dutch", label: "Dutch", msLangCode: "nl-NL" , icon : "emojione-v1:flag-for-netherlands" },
    { value: "swedish", label: "Swedish", msLangCode: "sv-SE" , icon : "emojione-v1:flag-for-sweden" },
    { value: "czech", label: "Czech", msLangCode: "cs-CZ" , icon : "emojione-v1:flag-for-czechia" },
    { value: "polish", label: "Polish", msLangCode: "pl-PL" , icon : "emojione-v1:flag-for-poland" },
    { value: "ukrainian", label: "Ukrainian", msLangCode: "uk-UA" , icon : "emojione-v1:flag-for-ukraine" },
    // { value: "greek", label: "Greek", msLangCode: "el-GR" , icon : },
    // { value: "hebrew", label: "Hebrew", msLangCode: "he-IL" , icon : },
    // { value: "serbian", label: "Serbian", msLangCode: "sr-RS" , icon : },
    { value: "croatian", label: "Croatian", msLangCode: "hr-HR" , icon : "emojione-v1:flag-for-croatia" },
    { value: "hungarian", label: "Hungarian", msLangCode: "hu-HU" , icon : "emojione-v1:flag-for-hungary" },
    { value: "bulgarian", label: "Bulgarian", msLangCode: "bg-BG" , icon : "emojione-v1:flag-for-bulgaria" },
    { value: "albanian", label: "Albanian", msLangCode: "sq-AL" , icon : "emojione-v1:flag-for-albania" },
    { value: "afrikaans", label: "Afrikaans", msLangCode: "af-ZA" , icon : "twemoji:flag-south-africa" },
    { value: "xhosa", label: "Xhosa", msLangCode: "xh-ZA" , icon : "emojione-v1:flag-for-zimbabwe" },
    { value: "amharic", label: "Amharic", msLangCode: "am-ET" , icon : "emojione-v1:flag-for-ethiopia" },
    { value: "catalan", label: "Catalan", msLangCode: "ca-ES" , icon : "emojione-v1:flag-for-spain" },
    { value: "galician", label: "Galician", msLangCode: "gl-ES" , icon : "openmoji:galicia-flag" },
    { value: "tagalog", label: "Tagalog", msLangCode: "fil-PH" , icon : "emojione-v1:flag-for-philippines" },
    { value: "malay", label: "Malay", msLangCode: "ms-MY" , icon : "emojione-v1:flag-for-malaysia" },
    { value: "danish", label: "Danish", msLangCode: "da-DK", icon : "emojione-v1:flag-for-denmark"}
  ]

export const translations = {
  "en-US": {
    value: "english",
    icon: "twemoji:flag-us-outlying-islands",
    prompt: "To enhance the quality of the voice clone, say this:",
    sentence: `"At midnight, Sally found a raccoon in her attic wearing her grandma's pearls and sipping tea. A note said, 'Your cat invited me.' Her cat just meowed lazily. Sally sighed and went back to bed, wondering what other secrets her pets hid."`
  },
  "zh-CN": {
    value: "mandarin",
    icon: "emojione-v1:flag-for-china",
    prompt: "为了提高语音克隆的质量，请说这句话：",
    sentence: `"在午夜，莎莉发现阁楼里有一只浣熊，戴着她奶奶的珍珠项链，还在喝茶。 一张纸条上写着：“你的小猫邀请了我。” 她的猫懒洋洋地喵了一声。 莎莉叹了口气，回到了床上，想着她的宠物还隐藏着什么其他秘密。"`
  },
  "es-ES": {
    value: "spanish",
    icon: "emojione-v1:flag-for-spain",
    prompt: "Para mejorar la calidad del clon de voz, di esto:",
    sentence: `"A medianoche, Sally encontró un mapache en su ático que llevaba las perlas de su abuela y bebía té. Una nota decía: 'Tu gato me invitó'. Su gato solo maulló perezosamente. Sally suspiró y volvió a la cama, preguntándose qué otros secretos escondían sus mascotas."`
  },
  "hi-IN": {
    value: "hindi",
    icon: "twemoji:flag-india",
    prompt: "आवाज क्लोन की गुणवत्ता बढ़ाने के लिए, यह कहें:",
    sentence: `"आधी रात को, सैली ने अपने अटारी में एक रैकून पाया जो उसकी दादी के मोती पहने हुए थे और चाय पी रहा था। एक नोट में लिखा था, 'आपकी बिल्ली ने मुझे आमंत्रित किया है।' उसकी बिल्ली ने बस आलस्य से म्याऊं किया। सैली ने लंबी सांस ली और बिस्तर पर वापस चली गई, सोच रही थी कि उसके पालतू जानवरों ने और कौन से रहस्य छिपाए हुए हैं।"`
  },
  "fr-FR": {
    value: "french",
    icon: "twemoji:flag-france",
    prompt: "Pour améliorer la qualité du clone vocal, dites ceci :",
    sentence: `"À minuit, Sally a trouvé un raton laveur dans son grenier portant les perles de sa grand-mère et buvant du thé. Une note disait: 'Votre chat m'a invité.' Son chat a simplement miaulé paresseusement. Sally a soupiré et est retournée se coucher, se demandant quels autres secrets ses animaux de compagnie cachaient."`
  },
  "ar-SA": {
    value: "arabic",
    icon: "emojione-v1:flag-for-saudi-arabia",
    prompt: "لتحسين جودة استنساخ الصوت، قل هذا:",
    sentence: `"عند منتصف الليل، وجدت سالي راكونًا في العلية يرتدي لؤلؤ جدتها ويحتسي الشاي. وجدت ملاحظة تقول: 'قطتك دعتني'. قطتها اكتفت بالمواء بكسل. تنهدت سالي وعادت إلى السرير، متسائلةً عن الأسرار الأخرى التي تخفيها حيواناتها الأليفة."`
  },
  "ru-RU": {
    value: "russian",
    icon: "emojione-v1:flag-for-russia",
    prompt: "Чтобы улучшить качество голосового клона, скажите это:",
    sentence: `"В полночь Салли нашла енота на чердаке, который носил жемчуг ее бабушки и пил чай. В записке было написано: «Твоя кошка пригласила меня». Кошка только лениво мяукнула. Салли вздохнула и вернулась в постель, размышляя, какие еще секреты скрывают ее питомцы."`
  },
  "pt-BR": {
    value: "portuguese",
    icon: "twemoji:flag-portugal",
    prompt: "Para melhorar a qualidade do clone de voz, diga isto:",
    sentence: `"À meia-noite, Sally encontrou um guaxinim em seu sótão usando as pérolas de sua avó e tomando chá. Uma nota dizia: 'Seu gato me convidou'. Seu gato apenas miou preguiçosamente. Sally suspirou e voltou para a cama, imaginando que outros segredos seus animais escondiam."`
  },
  "id-ID": {
    value: "indonesian",
    icon: "twemoji:flag-indonesia",
    prompt: "Untuk meningkatkan kualitas kloning suara, katakan ini:",
    sentence: `"Pada tengah malam, Sally menemukan rakun di lotengnya mengenakan mutiara neneknya dan minum teh. Sebuah catatan berbunyi, 'Kucingmu mengundangku.' Kucingnya hanya mengeong malas. Sally menghela nafas dan kembali ke tempat tidur, bertanya-tanya rahasia apa lagi yang disembunyikan hewan peliharaannya."`
  },
  "ja-JP": {
    value: "japanese",
    icon: "twemoji:flag-japan",
    prompt: "声のクローンの品質を向上させるために、次のように言ってください:",
    sentence: `"真夜中、サリーは屋根裏部屋でアライグマが祖母の真珠を身に着け、お茶を飲んでいるのを見つけました。 メモには「あなたの猫が私を招待した」と書かれていました。 彼女の猫はただ怠惰に鳴いた。 サリーはため息をつき、ペットが隠している他の秘密について考えながらベッドに戻った。" `
  },
  "de-DE": {
    value: "german",
    icon: "twemoji:flag-germany",
    prompt: "Um die Qualität des Stimmklons zu verbessern, sagen Sie dies:",
    sentence: `"Um Mitternacht fand Sally einen Waschbären auf ihrem Dachboden, der die Perlen ihrer Großmutter trug und Tee trank. Auf einer Notiz stand: 'Deine Katze hat mich eingeladen.' Ihre Katze miaute nur faul. Sally seufzte und ging zurück ins Bett und fragte sich, welche anderen Geheimnisse ihre Haustiere versteckten."`
  },
  "ko-KR": {
    value: "korean",
    icon: "emojione-v1:flag-for-south-korea",
    prompt: "음성 복제의 품질을 높이려면 이렇게 말하세요:",
    sentence: `"자정에 샐리는 다락방에서 너구리를 발견했습니다. 그 너구리는 할머니의 진주 목걸이를 걸고 차를 마시고 있었습니다. 쪽지에는 '네 고양이가 나를 초대했어'라고 적혀 있었습니다. 그녀의 고양이는 그냥 게으르게 울었습니다. 샐리는 한숨을 쉬고 침대로 돌아가며 그녀의 애완동물들이 다른 비밀을 숨기고 있는지 궁금해 했습니다."`
  },
  "it-IT": {
    value: "italian",
    icon: "emojione-v1:flag-for-italy",
    prompt: "Per migliorare la qualità del clone vocale, dì questo:",
    sentence: `"A mezzanotte, Sally ha trovato un procione nella sua soffitta che indossava le perle di sua nonna e sorseggiava tè. Un biglietto diceva: 'Il tuo gatto mi ha invitato'. Il suo gatto ha solo miagolato pigramente. Sally sospirò e tornò a letto, chiedendosi quali altri segreti nascondessero i suoi animali domestici."`
  },
  "tr-TR": {
    value: "turkish",
    icon: "emojione-v1:flag-for-turkey",
    prompt: "Ses klonunun kalitesini artırmak için şunu söyleyin:",
    sentence: `"Gece yarısı, Sally çatı katında büyükannesinin incilerini takan ve çay içen bir rakun buldu. Bir notta, 'Kediniz beni davet etti' yazıyordu. Kedisi sadece tembelce miyavladı. Sally iç çekti ve diğer evcil hayvanlarının ne gibi sırlar sakladığını merak ederek yatağına geri döndü."`
  },
  "nl-NL": {
    value: "dutch",
    icon: "emojione-v1:flag-for-netherlands",
    prompt: "Om de kwaliteit van de stemklonen te verbeteren, zeg dit:",
    sentence: `"Om middernacht vond Sally een wasbeer op haar zolder die haar oma's parels droeg en thee dronk. Een briefje zei: 'Je kat heeft me uitgenodigd.' Haar kat miauwde alleen maar lui. Sally zuchtte en ging terug naar bed, zich afvragend welke andere geheimen haar huisdieren verborgen."`
  },
  "sv-SE": {
    value: "swedish",
    icon: "emojione-v1:flag-for-sweden",
    prompt: "För att förbättra kvaliteten på röstklonen, säg detta:",
    sentence: `"Vid midnatt hittade Sally en tvättbjörn på sin vind som bar hennes mormors pärlor och drack te. En lapp sa: 'Din katt bjöd in mig.' Hennes katt jamade bara lat. Sally suckade och gick tillbaka till sängen och undrade vilka andra hemligheter hennes husdjur gömde."`
  },
  "cs-CZ": {
    value: "czech",
    icon: "emojione-v1:flag-for-czechia",
    prompt: "Pro zlepšení kvality hlasového klonu řekněte toto:",
    sentence: `"O půlnoci našla Sally na půdě mývala, který měl na sobě perly její babičky a pil čaj. Na lístku stálo: „Tvá kočka mě pozvala.“ Její kočka jen líně zamňoukala. Sally si povzdechla a vrátila se do postele a přemýšlela, jaká další tajemství její mazlíčci skrývají."`
  },
  "pl-PL": {
    value: "polish",
    icon: "emojione-v1:flag-for-poland",
    prompt: "Aby poprawić jakość klona głosu, powiedz to:",
    sentence: `"O północy Sally znalazła na strychu szopa pracza, który nosił perły swojej babci i pił herbatę. Na notatce było napisane: „Twój kot mnie zaprosił”. Jej kot tylko leniwie miauknął. Sally westchnęła i wróciła do łóżka, zastanawiając się, jakie inne sekrety skrywają jej zwierzęta."`
  },
  "uk-UA": {
    value: "ukrainian",
    icon: "emojione-v1:flag-for-ukraine",
    prompt: "Щоб покращити якість клонування голосу, скажіть це:",
    sentence: `"Опівночі Саллі знайшла на своєму горищі єнота, який носив перли її бабусі та пив чай. На записці було написано: «Твоя кішка мене запросила». Її кішка тільки ліниво нявкнула. Саллі зітхнула і повернулася в ліжко, замислюючись, які ще секрети приховують її домашні тварини."`
  },
  "hr-HR": {
    value: "croatian",
    icon: "emojione-v1:flag-for-croatia",
    prompt: "Da biste poboljšali kvalitetu glasovne kopije, recite ovo:",
    sentence: `"U ponoć je Sally na tavanu pronašla rakuna koji je nosio bakin biser i pio čaj. Na bilješci je pisalo: 'Tvoja me mačka pozvala.' Njena mačka samo je lijeno zamjaukala. Sally je uzdahnula i vratila se u krevet, pitajući se koje još tajne njezini ljubimci kriju."`
  },
  "hu-HU": {
    value: "hungarian",
    icon: "emojione-v1:flag-for-hungary",
    prompt: "A hangklón minőségének javítása érdekében mondja ezt:",
    sentence: `"Éjfélkor Sally talált egy mosómedvét a padlásán, aki a nagymamája gyöngyét viselte és teát kortyolgatott. Egy feljegyzés szerint: „A macskád hívott meg.” A macskája csak lustán nyávogott. Sally felsóhajtott, és visszament az ágyba, azon töprengve, milyen más titkokat rejtegetnek a háziállatai."`
  },
  "bg-BG": {
    value: "bulgarian",
    icon: "emojione-v1:flag-for-bulgaria",
    prompt: "За да подобрите качеството на клонирането на глас, кажете това:",
    sentence: `"В полунощ Сали намери миеща мечка на тавана си, която носеше перлите на баба ѝ и пиеше чай. В бележката пишеше: „Твоето коте ме покани“. Нейната котка само мързеливо измяука. Сали въздъхна и се върна в леглото, чудейки се какви други тайни крият нейните домашни любимци."`
  },
  "sq-AL": {
    value: "albanian",
    icon: "emojione-v1:flag-for-albania",
    prompt: "Për të përmirësuar cilësinë e klonit të zërit, thuaj këtë:",
    sentence: `"Në mesnatë, Sally gjeti një rakun në papafingon e saj që mbante perlat e gjyshes së saj dhe pinte çaj. Një shënim thoshte: 'Më ftoi macja jote.' Macja e saj vetëm mjaulliti përtueshëm. Sally psherëtiu dhe u kthye në shtrat, duke u menduar se çfarë sekretesh të tjera fshehnin kafshët e saj."`
  },
  "af-ZA": {
    value: "afrikaans",
    icon: "twemoji:flag-south-africa",
    prompt: "Om die kwaliteit van die stemkloon te verbeter, sê dit:",
    sentence: `"Teen middernag het Sally 'n wasbeer op haar solder gevind wat haar ouma se pêrels gedra het en tee gedrink het. 'n Nota het gesê: 'Jou kat het my genooi.' Haar kat het net lui gemiaau. Sally sug en gaan terug bed toe, wonder wat ander geheime haar troeteldiere wegsteek."`
  },
  "xh-ZA": {
    value: "xhosa",
    icon: "emojione-v1:flag-for-zimbabwe",
    prompt: "Ukuphucula umgangatho wokulinganisa ilizwi, yithi oku:",
    sentence: `"Ngobusuku, uSally wafumana i-raccoon kwi-attic yakhe enxibe iiperile zikaninakhulu kwaye esela iti. Kwinqaku kwakuthiwa, 'Ikati yakho indimemile.' Ikati yakhe yayimnandi nje yamemeza ivakalelo. USally wakhwaza wabuya emandlalweni, ezibuza ukuba zeziphi ezinye iimfihlelo ezizifihlileyo izilwanyana zakhe zasekhaya."`
  },
  "am-ET": {
    value: "amharic",
    icon: "emojione-v1:flag-for-ethiopia",
    prompt: "የድምፅ ክሎን ጥራትን ለማሻሻል፣ ይህን በሉ:",
    sentence: `"በእኩለ ሌሊት ሳሊ በሰልጣኑ ላይ የአዳዲሱን እንቁላል እንባልሳ እንዲሁም ሻይ ሲጠጣ አግኝታት ፡፡ በቀጥታው ግንቦት ይህ ነበር፡፡ 'የእኔ የአንበሳውን ሙሉ እንክሊል'፡፡"`
  },
  "ca-ES": {
    value: "catalan",
    icon: "emojione-v1:flag-for-spain",
    prompt: "Per millorar la qualitat del clon de veu, digues això:",
    sentence: `"A mitjanit, la Sally va trobar un ós rentador a les golfes que portava les perles de la seva àvia i bevia te. Una nota deia: 'El teu gat m'ha convidat.' El seu gat només va miolar mandrosament. La Sally va sospirar i va tornar al llit, preguntant-se quins altres secrets amagaven les seves mascotes."`
  },
  "gl-ES": {
    value: "galician",
    icon: "openmoji:galicia-flag",
    prompt: "Para mellorar a calidade do clon de voz, di isto:",
    sentence: `"Á medianoite, Sally atopou un mapache no faiado coa perla da súa avoa e bebendo té. Unha nota dicía: 'O teu gato convidoume.' O seu gato só miou perezosamente. Sally suspirou e volveu á cama, preguntándose que outros segredos escondían as súas mascotas."`
  },
  "fil-PH": {
    value: "tagalog",
    icon: "emojione-v1:flag-for-philippines",
    prompt: "Upang mapahusay ang kalidad ng voice clone, sabihin ito:",
    sentence: `"Hatinggabi, natagpuan ni Sally ang isang raccoon sa kanyang attic na suot ang mga perlas ng kanyang lola at umiinom ng tsaa. Isang tala ang nagsabi, 'Inimbitahan ako ng pusa mo.' Ang kanyang pusa ay tamad lang na umangal. Napabuntong-hininga si Sally at bumalik sa kama, iniisip kung ano pang mga lihim ang itinatago ng kanyang mga alagang hayop."`
  },
  "ms-MY": {
    value: "malay",
    icon: "emojione-v1:flag-for-malaysia",
    prompt: "Untuk meningkatkan kualiti klon suara, katakan ini:",
    sentence: `"Pada tengah malam, Sally menemui rakun di lotengnya memakai mutiara neneknya dan meminum teh. Satu nota berbunyi, 'Kucing awak jemput saya.' Kucingnya hanya mengiau dengan malas. Sally mengeluh dan kembali tidur, tertanya-tanya rahsia lain yang disembunyikan haiwan peliharaannya."`
  },
  "da-DK": {
    value: "danish",
    icon: "emojione-v1:flag-for-denmark",
    prompt: "For at forbedre kvaliteten af ​​stemmeklonen, sig dette:",
    sentence: `"Ved midnat fandt Sally en vaskebjørn på sit loft, som bar hendes bedstemors perler og drak te. En seddel sagde: 'Din kat inviterede mig.' Hendes kat miavede bare dovent. Sally sukkede og gik tilbage i seng og spekulerede på, hvilke andre hemmeligheder hendes kæledyr skjulte."`
  }
};

  