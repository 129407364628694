import { Button, Image } from "react-bootstrap";
import { t } from "i18next";
import LoaderLastStep from "../../Loader/LoaderLastStep";

function Personalizing() {
  const goToFeed = () => {
    const originUrl = localStorage.getItem("originAccessUrl");
    if (originUrl) {
      window.location.href = originUrl;
    }
  };

  return (
    <div className="PersonalizingPopup">
      <div className="login-info">
        {/* <span><Image src="/assets/images/orbofi-logo-chat-animation.gif" /></span> */}
        <LoaderLastStep />
        <h5>{t("proccess_completed")}</h5>
        <p>{t("thank_you_for_register_on_orboficom")}</p>
      </div>
      <Button variant="" type="submit" onClick={goToFeed} className="btn-primary btn-full">{t("finish")}</Button>
    </div>
  );
}
export default Personalizing;
