import { ReactElement } from "react";

interface LordIconProps {
  src: string;
  trigger: string;
  colors: string;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  module JSX {
    interface IntrinsicElements {
      "lord-icon": LordIconProps;
    }
  }
}

function LordIcon({ src, trigger, colors }: LordIconProps): ReactElement {
  return (
    <lord-icon src={src} trigger={trigger} colors={colors}></lord-icon>
  );
}

export default LordIcon;