import { Button, Col, Image, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import Select, { ActionMeta } from "react-select";
import ValueType from "react-select";
import OptionTypeBase from "react-select";
import PrimaryBtn from "../CommonBtn/PrimaryBtn";
import LordIcon from "../LordIcon/LordIcon";
import { t } from "i18next";
import "./GenerateAnything.css";

interface CustomOptionType extends OptionTypeBase {
    label: string;
    value: string;
    icon: JSX.Element;
}

function GenerateAnything() {
    const options: CustomOptionType[] = [
        { label: "Orbofi", value: "Orbofi", icon: <Image src="images/logo-icon.png" className="orbofiicon" /> },
        { label: "Coffee", value: "coffee", icon: <Icon icon="heroicons:sparkles-solid" /> },
        { label: "Apple", value: "apple", icon: <Icon icon="heroicons:sparkles-solid" /> },
    ];
    const handleChange = (
        selectedOption: ValueType<CustomOptionType>,
        actionMeta: ActionMeta<CustomOptionType>
    ) => {
    };

    return (
        <div className="GenerateAnything-Main">
            <div className="GenerateAnything-info">
                <h4>{t('generate_anything')}</h4>
                <p>{t('use_any_ai_agent_or_your_own_ai_clone_to_generate')}</p>
            </div>
            <div className="GenerateAnything-Search-box">
                <Select
                    className="reactSelect"
                    options={options}
                    onChange={handleChange}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    formatOptionLabel={(option) => (
                        <div>
                            {option.icon} {option.label}
                        </div>
                    )}
                />
                <input type="text" placeholder={t('create_your_ai_companion')} />
                <PrimaryBtn child={<>{t('generate_btn')} <i><Icon icon="heroicons:sparkles-solid" /></i></>} className={'HomeStartAI-btn'} linkName={'/'} />
            </div>
            <ul className="ChatScreenOptions mb-0 mt-2 justify-content-center ChatScreenOptionsHome ">
                <li><Button variant=""><i>
                    <LordIcon src="https://cdn.lordicon.com/obawqdgi.json" trigger="loop" colors="primary:#ffffff,secondary:#ebe6ef,tertiary:#54abb1"></LordIcon>
                </i> {t("ask_and_chat")}</Button></li>
                <li><Button className='active' variant=""><i>
                    <LordIcon src="https://cdn.lordicon.com/pbhjpofq.json" trigger="loop" colors="primary:#ffffff,secondary:#54abb1,tertiary:#4bb3fd,quaternary:#ffc738,quinary:#f24c00,senary:#ffffff,septenary:#f9c9c0"></LordIcon>
                </i>{t('image')} </Button></li>
                <li><Button variant=""><i>
                    <LordIcon src="https://cdn.lordicon.com/pwwdvajw.json" trigger="loop" colors="primary:#ffffff,secondary:#ffffff,tertiary:#54abb1"></LordIcon>
                </i> {t('longform_text')} </Button></li>
                <li><Button variant=""><i>
                    <LordIcon src="https://cdn.lordicon.com/ddexfvmy.json" trigger="loop" colors="primary:#ffffff,secondary:#ffffff,tertiary:#54abb1"></LordIcon>
                </i> {t('apps')} </Button></li>
                <li><Button variant=""><i>
                    <LordIcon src="https://cdn.lordicon.com/obawqdgi.json" trigger="loop" colors="primary:#ffffff,secondary:#ebe6ef,tertiary:#54abb1"></LordIcon>
                </i> {t('more')}</Button></li>
            </ul>
        </div>
    );
}

export default GenerateAnything;
