
import "./Loader.css";
import Lottie from 'react-lottie';
import animationData from './logo.json';
import React from 'react'
import ReactPlayer from 'react-player/lazy'

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div className="loaderMain">
      <Lottie options={defaultOptions} />
    </div>
  );
};

export default Loader;
