import { Col, Image, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import "./Categories.css";
import { t } from "i18next";
import Slider from "react-slick";
import { useEffect, useRef, useState } from "react";
import { ChatbotData, GetChatbotList } from "../../Utils/ChatbotUtils";

function Categories() {

  const [chatbotList, setChatbotList] = useState<ChatbotData[]>([]);

  useEffect(() => {
    GetChatbotList().then((chatbots) => setChatbotList(chatbots.slice(0, 12))).catch(console.error);
  }, []);



  const settingscategories = {
    infinite: false,
    centerPadding: "60px",
    slidesToShow: 1.5,
  };
  const sliderRef = useRef<Slider | null>(null);
  const goToPreviousSlide = () => {
    sliderRef?.current?.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef?.current?.slickNext();
  };

  return (
    <>
      <div className="maincategory">
        <Row className="categoriesdesktop">
          {chatbotList.map((chatbot) => (
            <>
              <Col lg={3} md={3} sm={4} key={chatbot.id} >
                <Link to={`/chat/${chatbot?.string_id}`} className='Categories-item'>
                  <span><Image src={chatbot.img} /></span>
                  <div className="d-flex flex-column">
                    <h6>{chatbot.name}</h6>
                  </div>
                  <i><Icon icon="ri:arrow-right-line" /></i>
                </Link>
              </Col>
            </>
          ))}

        </Row>


        {chatbotList.length > 0 && (
          <Slider ref={sliderRef} {...settingscategories} className="mobilecategory">
            {/* Iterate over chatbotList in chunks of three */}
            {[...Array(Math.ceil(chatbotList.length / 3)).keys()].map(chunkIndex => (
              <div className="chunk" key={chunkIndex}>
                {/* Loop through three chatbots in this chunk */}
                {[0, 1, 2].map(innerIndex => {
                  const chatbotIndex = chunkIndex * 3 + innerIndex;
                  if (chatbotIndex < chatbotList.length) {
                    const chatbot = chatbotList[chatbotIndex];
                    return (
                      <Link key={chatbotIndex} to={`/chat/${chatbot.string_id}`} className='Categories-item'>
                        <span><Image src={chatbot.img} /></span>
                        <div>
                          <h6>{chatbot.name}</h6>
                          <p title={chatbot.description}>
                            {chatbot.description
                              ?.split(" ")
                              .slice(0, 20)
                              .join(" ")
                              .concat(chatbot.description.length > 20 ? "..." : "") ?? "Description not available"}
                          </p>
                        </div>
                        <i><Icon icon="ri:arrow-right-line" /></i>
                      </Link>
                    );
                  } else {
                    // If there are less than three chatbots in the last chunk, render empty divs
                    return <div key={innerIndex} className="empty"></div>;
                  }
                })}
              </div>
            ))}
          </Slider>
        )}

        <div className="d-flex gap-2 mobileArrows">
          <div className="SliderPrevbtn" onClick={goToPreviousSlide}>
            <Icon icon="ep:arrow-left-bold" />
          </div>
          <div className="Slidernextbtn" onClick={goToNextSlide}>
            <Icon icon="ep:arrow-right-bold" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Categories;
