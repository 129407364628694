import React from "react";
import { Icon } from "@iconify/react";
import "./MobileNavigation.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userDataSelector } from "../../Redux/Slices/userDataSlice";
import { updateDirtyFlags } from "../../Redux/Slices/dirtyFlagsSlice";
import { Button } from "react-bootstrap";

function MobileNavigation() {
    const location = useLocation();
    const userData = useSelector(userDataSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Determine the active index based on the current path
    const activeIndex = location.pathname === '/' ? 0 : 
                        location.pathname.includes('/Mychatbot') ? 1 : 
                        location.pathname === '/createaicompanion' ? 2 : 
                        location.pathname === '/explore' ? 3 : 4;

    return (
        location.pathname.toLowerCase().split("/")[1] !== ("chat") ? (
        <div className="mobile-navigation">
            <nav className="nav">
                <Link to="/" className={`nav-item ${activeIndex === 0 ? 'is-active' : ''}`}>
                    <i><Icon icon="majesticons:home" /></i>
                    <span>Home</span>
                </Link>
                <Button variant="" onClick={() => {
                    navigate('/Mychatbot')
                    dispatch(updateDirtyFlags({UserChatbotsDirty: true, FavouriteChatbotsDirty: true, RecentChatbotsDirty: true, CurrentChatbotDirty: true }))
                }} className={`nav-item ${activeIndex === 1 ? 'is-active' : ''}`}>
                    <i><Icon icon="ph:chats-fill" /></i>
                    <span>Chatbots</span>
                </Button>
                <Link to="/createaicompanion" className={`nav-item ${activeIndex === 2 ? 'is-active' : ''}`}>
                    <i><Icon icon="octicon:feed-plus-16" /></i>
                    <span>Create</span>
                </Link>
                <Link to="/explore" className={`nav-item ${activeIndex === 3 ? 'is-active' : ''}`}>
                    <i><Icon icon="material-symbols:explore" /></i>
                    <span>Explore</span>
                </Link>
                <Link to={`/profile/${userData.Name}`} className={`nav-item ${activeIndex === 4 ? 'is-active' : ''}`}>
                    <i><Icon icon="iconamoon:profile-fill" /></i>
                    <span>Profile</span>
                </Link>
            </nav>
        </div>
        ) : ''
    );
}

export default MobileNavigation;
