import { Col, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import "./SubscriptionBox.css";
import Products from "../../../../Models/Products";
import { useSelector } from "react-redux";
import { subDataSelector } from "../../../../Redux/Slices/subscriptionDataSlice";
import { Dispatch, SetStateAction } from "react";

const titleColours = ["text-blue", "text-purple", "text-green", "text-neon"];
function SubscriptionCard({ paymentToggle, titleText, productDetails, setProduct }: { paymentToggle: () => void, titleText: string, productDetails: Products, setProduct: Dispatch<SetStateAction<Products>> }): JSX.Element {
  const subData = useSelector(subDataSelector);

  function setProductToCheckout() {
    setProduct(productDetails);
    paymentToggle();
  }

  return (
    <div className="PricingCardMain">
      <div className="PricingCardHead">
        <h6 className={titleText}>{productDetails.Name}</h6>
        <h3><i>$</i> {productDetails.Price} <span>USD</span></h3>
      </div>
      <ul className="PricingCardBody">
        {productDetails.FeaturesArray.map((featureDescription, index) =>
        (
          <li key={index}><i><Icon icon="tabler:check" /></i> {featureDescription}</li>
        )
        )}
      </ul>
      <div className="PricingCardFooter">
        {productDetails.Price !== 0 ?
          <Link to="javascript:void(0)" onClick={setProductToCheckout} className="btn-border-white">Subscribe</Link>
          :
          ""
        }
      </div>
    </div>
  );
}


function SubscriptionBox({ onPaymentToggle, subscriptionProducts, setProduct }: { onPaymentToggle: () => void, subscriptionProducts: Products[], setProduct: Dispatch<SetStateAction<Products>> }) {
  return (
    <Row>
      {subscriptionProducts.map((product, index) => (
        <Col lg={3} md={6} key={index}>
          <SubscriptionCard paymentToggle={onPaymentToggle} titleText={titleColours[index]} productDetails={product} setProduct={setProduct} />
        </Col>
      ))}
    </Row>
  );
}

export default SubscriptionBox;
