import PopupModal from "../PopUpBase";
import { Button, Image } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginPopUpSelector, showPopUpAction } from "../../../Redux/Slices/loginPopUpSlice";
import PrimaryBtn from "../../CommonBtn/PrimaryBtn";
import "./LoginPopup.css";
import { t } from "i18next";
import { useAccount } from "wagmi";
import { toast } from "react-hot-toast";

export function LoginPopUp() {
  const dispatch = useDispatch();
  const showPopUp = useSelector(loginPopUpSelector);
  const { isConnected } = useAccount()

  // This function registers or login the user into our backend, the backend handles the authentication by any oauth that we pass into it. We get the jwtauth token back that we need to get user details whenever we need them
  async function RegisterOrLoginUser(loginType: string) {
    window.location.href = import.meta.env.VITE_SERVER_ADDRESS + "auth/" + loginType + "/login";
    localStorage.setItem("originAccessUrl", window.location.href);

  }

  function handleLoginForWallet() {

    if (! isConnected) {
      toast.error("You've to login to connect your wallet")
    }
    dispatch(showPopUpAction(false));
  }

  return (
    <>
      <PopupModal show={showPopUp} handleClose={{}} className="default-popup login-popup login-popup-img" backdrop={undefined}>
        <Link to="javascript:void(0)" className="CloseBtn" onClick={() => dispatch(showPopUpAction(false))}>
          <Icon icon="solar:close-circle-linear" />
        </Link>
        <div className="login-info">
          <span><Image src="/assets/icons/orbofi-icon-white.svg" /></span>
          <h5>{t("join_orbofi_for_free")}</h5>
          <p>{t("welcome_please_fill_in_the_details_to_get_started")}</p>
        </div>
        <div className="login-wrap-btns">
          <div className="login-wallet-btn">
            
            <PrimaryBtn child={<>{t("connect_wallet")} <i><Image src="/assets/icons/meta-wallet-icon.png" /></i></>} className={"LoginWalletBtn"} linkName={"/"} onClick={() => {
              handleLoginForWallet();
            }} />
          </div>
          <div className="or-line">
            <span></span>
            <p>{t("or_start_exploring")}</p>
            <span></span>
          </div>
          <div className="social-login-btns">
            <Button variant="" className="google-login-btn" type="button" onClick={() => RegisterOrLoginUser("google")}><i><Icon icon="flat-color-icons:google" /></i>{t("google")} </Button>
            <Button variant="" className="discord-login-btn" type="button" onClick={() => RegisterOrLoginUser("discord")}><i><Icon icon="logos:discord-icon" /></i>{t("discord")} </Button>
            <Button variant="" className="twitter-login-btn" type="button" onClick={() => RegisterOrLoginUser("twitter")}><i><Icon icon="fa6-brands:x-twitter" /></i>{t("twitter")} </Button>
          </div>
        </div>

        <div className="ModalWrap d-none">
          <Button
            variant=""
            type="button"
            className="GoogleLogin BtnDefault"
            onClick={() => RegisterOrLoginUser("google")}
          >
            <span>
              <Icon icon="logos:google-icon" />
            </span>{" "}
            {t("google")}
          </Button>
          <Button
            variant=""
            type="button"
            className="DiscordLogin btn BtnDefault"
            onClick={() => RegisterOrLoginUser("discord")}
          >
            <span>
              <Icon icon="ic:baseline-discord" color="white" />
            </span>{" "}
            {t("discord")}
          </Button>

          {/*<Button*/}
          {/*    variant=""*/}
          {/*    type="button"*/}
          {/*    className="AppleLogin btn BtnDefault"*/}
          {/*    onClick={() => RegisterOrLoginUser("apple")}*/}
          {/*>*/}
          {/*    <span>*/}
          {/*        <Icon icon="ic:baseline-apple" color="white" />*/}
          {/*    </span>{" "}*/}
          {/*    Apple*/}
          {/*</Button>*/}

          {/*<Button*/}
          {/*    variant=""*/}
          {/*    type="button"*/}
          {/*    className="FacebookLogin BtnDefault"*/}
          {/*    onClick={() => RegisterOrLoginUser("facebook")}*/}
          {/*>*/}
          {/*    <span>*/}
          {/*        <Icon icon="mdi:facebook" color="white" />*/}
          {/*    </span>{" "}*/}
          {/*    Facebook*/}
          {/*</Button>*/}

          <Button
            variant=""
            type="button"
            className="TwitterLogin BtnDefault"
            onClick={() => RegisterOrLoginUser("twitter")}
          >
            <span>
              <Icon icon="bytesize:twitter" color="white" />
            </span>{" "}
            {t("twitter")}
          </Button>
        </div>
        <p className="policyText text-center">
          By signing up, you agree to <a target="_blank" rel="noreferrer" href="https://wiki.orbofi.com/learn/terms-of-services-and-privacy/terms-of-services">Terms of services, </a> and
          <a target="_blank" rel="noreferrer" href="https://wiki.orbofi.com/learn/terms-of-services-and-privacy/privacy-policy"> Privacy Policy</a>
        </p>
      </PopupModal>
    </>
  );
}