import {createSlice} from "@reduxjs/toolkit";
import Subscription from "../../Models/Subscription";

const initialStruct: {
    id: number;
    UserId: number;
    SubscriptionLevel: number;
    SubscriptionExpDate: string;
    RemainingSubMonths: number;
    DirectDebitProvider: string;
    CreatedAt: string;
    Recurrent: boolean;
} = {
  id: -1,
  UserId: -1,
  SubscriptionLevel: -1,
  SubscriptionExpDate: "",
  RemainingSubMonths: -1,
  DirectDebitProvider: "",
  CreatedAt: "",
  Recurrent: false,
};

export const subscriptionDataSlice = createSlice({
  name: "subscriptionData",
  initialState: {
    value: initialStruct
  },
  reducers: {
    updateSubInfo: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload;
    }
  }
});

export const { updateSubInfo } = subscriptionDataSlice.actions;

export default subscriptionDataSlice.reducer;

export const subDataSelector = (state: { subscriptionData: { value: Subscription; }; })  => state.subscriptionData.value;