import { configureStore } from "@reduxjs/toolkit";
import onboardingReducer from "./Slices/onboardingSlice";
import {loginPopUpSlice} from "./Slices/loginPopUpSlice";
import {userDataSlice} from "./Slices/userDataSlice";
import {themeSlice} from "./Slices/themeSlice";
import {subscriptionDataSlice} from "./Slices/subscriptionDataSlice";
import {dirtyFlagsSlice} from "./Slices/dirtyFlagsSlice";

export const store = configureStore({
  reducer: {
    loginPopUpState: loginPopUpSlice.reducer,
    userData: userDataSlice.reducer,
    themeState: themeSlice.reducer,
    subscriptionData: subscriptionDataSlice.reducer,
    onboarding: onboardingReducer,
    dirtyFlags: dirtyFlagsSlice.reducer,
  }
});
