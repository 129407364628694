

// Keep in sync with backend (prompts.py)
export enum GeneratedCodeConfig {
  HTML_TAILWIND = "html_tailwind",
  REACT_TAILWIND = "react_tailwind",
  BOOTSTRAP = "bootstrap",
  IONIC_TAILWIND = "ionic_tailwind",
  GENERIC = "generic",
  GAME_GEN = "game_gen",
  TEXT2CODE = "text2code"
}

export interface Settings {

  isImageGenerationEnabled: boolean;
  generatedCodeConfig: GeneratedCodeConfig;
  accessCode: string | null;
}


export enum AppState {
  INITIAL = "INITIAL",
  CODING = "CODING",
  CODE_READY = "CODE_READY",
  CODEGEN_LOADING = "CODEGEN_LOADING",
  CODEGEN_READY = "CODEGEN_READY"
}

export interface AssetData {
  url: string;
  prompt?: string;
}
