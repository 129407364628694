import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Products from "../../../../Models/Products";
import { Dispatch, SetStateAction } from "react";

function OrboxCard({ paymentToggle, productDetails, setProduct }: { paymentToggle: () => void, productDetails: Products, setProduct: Dispatch<SetStateAction<Products>> }): JSX.Element {
  function setProductToCheckout() {
    setProduct(productDetails);
    paymentToggle();
  }

  return (
    <div className="PricingCardMain">
      <div className="d-flex align-items-center">
        <div className="PricingCardHead">
          <h6 className="text-neon mb-1">{productDetails.Name}</h6>
          <h3><i>$</i> {productDetails.Price} <span>USD</span></h3>
        </div>
        {productDetails.Name === "100 Orbox" ? <Image src="/images/orbobox1.png" /> : (productDetails.Name === "200 Orbox" ? <Image src="/images/orbobox2.png" /> : (productDetails.Name === "500 Orbox" ? <Image src="/images/orbobox3.png" /> : (productDetails.Name === "1000 Orbox" ? <Image src="/images/orbobox4.png" /> : (productDetails.Name === "2000 Orbox" ? <Image src="/images/orbobox5.png" /> : (productDetails.Name === "3000 Orbox" ? <Image src="/images/orbobox6.png" /> : '')))))}

      </div>
      <div className="PricingCardFooter">
        {localStorage.getItem("oauthToken") ?
          <Link to="javascript:void(0)" onClick={setProductToCheckout} className="btn-border-white">Buy {productDetails.Name}</Link>
          : ""
        }
      </div>
    </div>
  );
}

function OrboxPriceBox({ paymentToggle, orboxProducts, setProduct }: { paymentToggle: () => void, orboxProducts: Products[], setProduct: Dispatch<SetStateAction<Products>> }) {
  return (
    <Row>
      {orboxProducts.map((product, index) => (
        <Col lg={4} md={6} sm={6} key={index}>
          <OrboxCard paymentToggle={paymentToggle} productDetails={product} setProduct={setProduct} />
        </Col>
      ))}
    </Row>
  );
}

export default OrboxPriceBox;



