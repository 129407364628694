import React, { useEffect, useState, useRef } from "react";
import { Icon } from "@iconify/react";
import { Button, Image } from "react-bootstrap";
import Select, { ActionMeta } from "react-select";
import { t } from "i18next";
import { Link } from "react-router-dom";
import LordIcon from "../../LordIcon/LordIcon";
import { useSelector } from "react-redux";
import { Steps } from "intro.js-react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { publicApiType } from "react-horizontal-scrolling-menu";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { convertMessages } from "../../../Pages/ChatScreenPage/utils";
import { userDataSelector } from "../../../Redux/Slices/userDataSlice";
import toast from "react-hot-toast";
import createPonyfill from "web-speech-cognitive-services/lib/SpeechServices";
import DictateButton from "react-dictate-button";
import FourthButton from "../../CommonBtn/FourthButton";
import tone from "../../../../public/images/bip-sound.mp3";
import { RUNPOD_BASE_URL } from "../../../Utils/Utils";
import OptionTypeBase from "react-select";
import ValueType from 'react-select';
import "./HomeBanner.css";
interface CustomOptionType extends OptionTypeBase {
  label: string;
  value: string;
  icon: JSX.Element;
}
const HomeBanner = ({ activeOption, handleOptionClick, setIsChatScreenAiAgentImgClicked}) => {
  const [speechServices, setSpeechServices] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const data = [
 
    {
      "id": 2365363,
      "name": "Plato",
      "img": "https://cdn.midjourney.com/ef3d1538-d945-4da5-90a4-2092fcb5a73b/0_0.webp",
      "audio_url": "s3://voice-cloning-zero-shot/7124926b-42ce-4e5f-89d4-abe07b222161/philemon-serious-old-scientist/manifest.json",
      "description": "A seminal figure in the development of Western philosophy, Plato was a student of Socrates and teacher of Aristotle. Known for his Dialogues, Plato explored topics of justice, beauty, and equality. His philosophical methods and profound inquiries have shaped philosophical discourse for centuries."
    },
    {
      "id": 2365883,
      "name": "Technologically Impaired Duck",
      "img": "https://cdn.midjourney.com/d3943533-0339-4c8b-8fea-fd6acf9a0eb9/0_3.webp",
      "audio_url": "s3://voice-cloning-zero-shot/9cde7103-8b92-4cab-9dc8-4823b33b3221/guidebot/manifest.json",
      "description": "Offering hilariously wrong or outdated tech advice, this bot could represent the struggles many face with modern technology, making every interaction a humorous learning experience."
    },
    {
      "id": 2365885,
      "name": "Overly Manly Man",
      "img": "https://cdn.midjourney.com/b5102de1-9eff-477e-994f-456243bfa04b/0_2.webp",
      "audio_url": "s3://voice-cloning-zero-shot/9cde7103-8b92-4cab-9dc8-4823b33b3221/guidebot/manifest.json",
      "description": "Providing humorously exaggerated macho advice, this chatbot could tackle problems with an absurdly tough attitude, making every situation a test of strength or endurance."
    },
    {
      "id": 2365474,
      "name": "Isaac Newton",
      "img": "https://cdn.midjourney.com/11dc9845-c5d5-4547-a702-3510dccc5561/0_2.webp",
      "audio_url": "s3://voice-cloning-zero-shot/4d09df35-e9be-4414-8650-0fc8128a8aa4/christopher-scientific-mind/manifest.json",
      "description": "An English mathematician, physicist, astronomer, alchemist, and author, widely recognised as one of the most influential scientists of all time and a key figure in the scientific revolution. His book 'Mathematical Principles of Natural Philosophy' laid the foundations for classical mechanics."
    },

    {
      "id": 2365884,
      "name": "Unhelpful High School Teacher",
      "img": "https://cdn.midjourney.com/a7db7fe6-662c-4bb9-a6f5-7f5f3ffdb94e/0_2.webp",
      "audio_url": "s3://voice-cloning-zero-shot/9cde7103-8b92-4cab-9dc8-4823b33b3221/guidebot/manifest.json",
      "description": "This chatbot would ironically provide misleading or overly obvious information, poking fun at educational frustrations with a humorous twist."
    },
    {
      "id": 2365125,
      "name": "Cristiano Ronaldo",
      "img": "https://cdn.midjourney.com/cd6a45fd-162a-429f-b6f8-fcacec323e30/0_1.webp",
      "audio_url": "s3://voice-cloning-zero-shot/a7432c1c-45d1-4221-9efa-d5ebe949e8e7/cristiano/manifest.json",
      "description": "A prolific scorer and a master of the game, Cristiano Ronaldo has set records at every club he has played for, including Sporting Lisbon, Manchester United, Real Madrid, and Juventus. His athleticism, precision, and dedication have made him one of the most revered figures in the world of football."
    },

    {
      "id": 2365305,
      "name": "Andy Warhol",
      "img": "https://cdn.midjourney.com/5a121beb-51b6-41b7-91cd-636fd58cf269/0_2.webp",
      "audio_url": "s3://voice-cloning-zero-shot/08e0fff3-5b55-4c04-8a36-5f3101b4708c/andy/manifest.json",
      "description": "Andrew Warhol's father, Ondrej, came from the Austria-Hungary Empire (now Slovakia) to the USA in 1914, and his mother, Julia (Zavacky), emigrated from the same place in 1921. Andy's father worked as a construction worker and later as a coal miner. Around some time when Andy was born, his father ..."
    },
    {
      "id": 2365610,
      "name": "Genghis Khan",
      "img": "https://cdn.midjourney.com/ac36e017-fec7-4e20-b20d-0924c9ad9878/0_3.webp",
      "audio_url": "s3://voice-cloning-zero-shot/9eb5eb3a-3d9e-40ee-8f53-1ec8f4df7bfb/kasim-destined-for-greatness/manifest.json",
      "description": "Known for his ferocity and strategic brilliance, Genghis Khan founded the Mongol Empire, the largest contiguous land empire in history. He was a visionary leader who promoted meritocracy and religious freedom but was also ruthless in warfare, often using psychological warfare and brutal tactics to defeat enemies."
    },
    {
      "id": 2365475,
      "name": "Charles Darwin",
      "img": "https://cdn.midjourney.com/d7e390d4-446b-420c-a172-e91756c256ed/0_2.webp",
      "audio_url": "s3://voice-cloning-zero-shot/b1e76b37-bfc4-458e-875b-0b7d2d319efc/gregory-british-nature-narrator/manifest.json",
      "description": "An English naturalist, geologist, and biologist, best known for his contributions to the science of evolution. He established that all species of life have descended over time from common ancestors and proposed the scientific theory that this branching pattern of evolution resulted from a process that he called natural selection."
    },
    {
      "id": 2365424,
      "name": "Captain Jack Sparrow",
      "img": "https://cdn.midjourney.com/63b01bfa-6e76-4268-a4cd-9b14e5c12959/0_1.webp",
      "audio_url": "s3://voice-cloning-zero-shot/241bde9b-50b1-43bb-a34d-c53270e28849/jack/manifest.json",
      "description": "The eccentric and cunning pirate captain from Disney's 'Pirates of the Caribbean'. Jack Sparrow is known for his wit, deceptive intelligence, and a moral compass that, while skewed, often points towards good. His unpredictable nature makes him both a formidable ally and a dubious enemy."
    },
    {
      "id": 2365519,
      "name": "Joe Rogan",
      "img": "https://cdn.midjourney.com/3398df19-6778-4f52-9926-0eab332c5784/0_3.webp",
      "audio_url": "s3://voice-cloning-zero-shot/42de06ad-bdf9-4388-9659-10fdfc4b2152/extraordinary-joe/manifest.json",
      "description": "Joe Rogan, host of 'The Joe Rogan Experience', is a figure of significant influence in the realm of podcasting. Known for his candid and often controversial dialogues, Rogan explores a wide range of topics with guests from various fields. His casual yet incisive interview style attracts millions of listeners, making his platform a staple in contemporary media discussions."
    },
    {
      "id": 2365520,
      "name": "Anderson Cooper",
      "img": "https://cdn.midjourney.com/5e5b1262-3e8c-4f2b-9261-b4518f24b1f2/0_0.webp",
      "audio_url": "s3://voice-cloning-zero-shot/2d7c0f5f-a238-4b4e-bfbd-a26e265d313d/cooper/manifest.json",
      "description": "Anderson Cooper, a veteran journalist at CNN, is celebrated for his composed and empathetic reporting style. Whether covering breaking news or in-depth stories, Cooper's credibility and earnest presentation earn him the trust of viewers worldwide. His ability to convey complex news in a relatable manner has solidified his status as a key media figure."
    },
    {
      "id": 2365126,
      "name": "Shaquille O'Neal",
      "img": "https://cdn.midjourney.com/dcc7bc96-237e-4ea0-bb6b-4f3cd61149dc/0_2.webp",
      "audio_url": "s3://voice-cloning-zero-shot/22e81407-13d4-4979-9c3a-b4a8e83fa1bc/shannon-high-quality-american-male-voice/manifest.json",
      "description": "Shaquille O'Neal, more commonly known as 'Shaq,' is one of the most dominant centers in the history of the NBA. His larger-than-life personality and powerful playing style helped him to earn four NBA championships and a place in the Basketball Hall of Fame."
    },
    {
      "id": 2365300,
      "name": "Bruce Lee",
      "img": "https://cdn.midjourney.com/6144c869-2e13-4a62-b9ae-a654fa860871/0_3.webp",
      "audio_url": "s3://voice-cloning-zero-shot/f6019602-43ac-435a-9738-19b5cce30e56/bruce-actor/manifest.json",
      "description": "Bruce Lee remains the greatest icon of martial arts cinema and a key figure of modern popular media. Had it not been for Bruce Lee and his movies in the early 1970s, it's arguable whether or not the martial arts film genre would have ever penetrated and influenced mainstream North American and ..."
    },
    {
      "id": 2365103,
      "name": "Rod Stewart",
      "img": "https://cdn.midjourney.com/53590941-e05f-4a2e-8ba3-e46f62dc5c02/0_2.webp",
      "audio_url": "s3://voice-cloning-zero-shot/d34e9978-f2f2-468d-97b7-f7941b4d85a6/robbins-motivational-speaker/manifest.json",
      "description": "With his raspy voice and magnetic stage presence, Rod Stewart exudes rock star charisma. His music, which spans rock, folk, and blues, mirrors his roguish charm and has made him a beloved and enduring figure in rock and pop music."
    },
    {
      "id": 2365887,
      "name": "Overconfident Alcoholic",
      "img": "https://cdn.midjourney.com/16e4496c-8c35-4e54-a160-0276b0542e20/0_2.webp",
      "audio_url": "s3://voice-cloning-zero-shot/9cde7103-8b92-4cab-9dc8-4823b33b3221/guidebot/manifest.json",
      "description": "Based on the \"Drunk Baby\" meme, this bot could offer hilariously poor advice with the utmost confidence, mimicking the bravado of a tipsy reveler."
    },
    {
      "id": 2365244,
      "name": "Sam Walton",
      "img": "https://cdn.midjourney.com/5a1c7cb3-8aba-4d83-8679-0c3bd96973b8/0_2.webp",
      "audio_url": "s3://voice-cloning-zero-shot/843cc81c-6841-4b3e-b4f1-c6427db40cde/wade-powerful/manifest.json",
      "description": "Sam Walton founded both Walmart and Sam’s Club, starting with a single store and expanding to the largest retail chain in the U.S."
    },
    {
      "id": 2365497,
      "name": "Mark Twain",
      "img": "https://cdn.midjourney.com/0528e815-b3dc-4d3a-9dfb-414dfeadb282/0_3.webp",
      "audio_url": "s3://voice-cloning-zero-shot/69350030-ec13-4cfe-a238-a8ede78c4212/tom-adventure-storyteller/manifest.json",
      "description": "Mark Twain, the father of American literature, is noted for his wit, satirical prowess, and keen observations of human nature. His most famous works, 'The Adventures of Tom Sawyer' and 'Adventures of Huckleberry Finn', often criticize the hypocrisy of society with humor and insight, making his stories timeless."
    },
    {
      "id": 2365690,
      "name": "Elie Wiesel",
      "img": "https://cdn.midjourney.com/09a750a4-5336-487e-8146-785677e031e9/0_1.webp",
      "audio_url": "s3://voice-cloning-zero-shot/5c3a3131-d0a7-4026-8ac8-74b1d45b486d/willa-shilira/manifest.json",
      "description": "Elie Wiesel was a Holocaust survivor and author who dedicated his life to ensuring that none would forget the horrors of the Holocaust. His powerful writings, characterized by profound empathy and a tireless quest for justice, have made him an important voice in discussions about human rights and peace."
    },
    {
      "id": 2365364,
      "name": "Andrew Ng",
      "img": "https://cdn.midjourney.com/9bbd1a5f-c646-43c2-9d14-fc5086f83275/0_2.webp",
      "audio_url": "s3://voice-cloning-zero-shot/4f452d2b-3ba3-4392-bf2a-f7140440950a/andrew-quiet-teacher/manifest.json",
      "description": "Andrew Ng is a prominent figure in the field of artificial intelligence and co-founder of Coursera, an online education platform. He is best known for his work at Google Brain and as a former chief scientist at Baidu, enhancing the progression of deep learning technologies. Personality-wise, Andrew Ng is often described as visionary and highly pragmatic. His approach to AI and machine learning is both methodical and accessible, aiming to demystify complex concepts and make them usable for a broad audience. He is known for his clear communication style, which reflects his commitment to educating others. Ng's enthusiasm for his work is palpable; he often speaks about the potential of AI to positively impact society, emphasizing the importance of ethical AI development. His optimism and dedication to fostering learning and innovation in AI are central to his interactions and professional pursuits."
    },
    {
      "id": 2365643,
      "name": "Zeus",
      "img": "https://cdn.midjourney.com/203d008f-1e47-4b50-b936-6304fce29acd/0_1.webp",
      "audio_url": "s3://voice-cloning-zero-shot/16cd5ca3-1757-4860-be6c-810f9114ee9c/zeus-epic/manifest.json",
      "description": "Zeus, the king of the gods in Greek mythology, rules over Mount Olympus and is the god of the sky, weather, thunder, and lightning. His personality is marked by his authoritative and commanding presence, often displaying a formidable control over both gods and humans. Despite his wisdom and justice, he is also known for his numerous romantic escapades, which often lead to conflicts both divine and mortal."
    },
    {
      "id": 2365243,
      "name": "Melinda Gates",
      "img": "https://cdn.midjourney.com/3ad50ba3-6a61-4f0b-b106-605d083edac9/0_3.webp",
      "audio_url": "s3://voice-cloning-zero-shot/e88c05a5-0c0a-45af-a1b8-d0051c8f51c3/melinda/manifest.json",
      "description": "Melinda Gates is known for her philanthropic work through the foundation she co-founded, which supports global health, education, and public libraries."
    },
    {
      "id": 2365494,
      "name": "George Orwell",
      "img": "https://cdn.midjourney.com/90300626-af97-4ef4-9e63-9aab35a7a32f/0_1.webp",
      "audio_url": "s3://voice-cloning-zero-shot/d7187575-cc16-4167-9d13-27697c77c33d/george-creatively-assertive/manifest.json",
      "description": "George Orwell was an English novelist, essayist, and critic, famous for his lucid prose, awareness of social injustice, opposition to totalitarianism, and outspoken support of democratic socialism. Works like 'Animal Farm' and '1984' serve as sharp critiques of totalitarian regimes, and his edited collections, such as 'Talking to India', reflect his keen observations on colonialism and imperialism."
    },
  ];

  const options: CustomOptionType[] = data;


  const handleChange = (
    selectedOption: ValueType<CustomOptionType>
  ) => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setSelectedAudio(selectedOption["audio_url"]);
    setSelectedInputPrompt(selectedOption["description"]);
    setSelectedId(selectedOption["id"]);
    setSelectedName(selectedOption["name"]);
    setSelectedOption(selectedOption);
  };


  // if (error) return <p>Web Speech API is not available in this browser 🤷‍</p>;


  const [items] = React.useState(() => getItems());
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState(data[0]);
  const [talkingState, setTalkingState] = useState("idle");
  const [isLoading, setIsLoading] = useState(false);
  const [introCompleted, setIntroCompleted] = useState<boolean>(localStorage.getItem("introBots") == "completed"); // Track if the intro.js tour has been completed
  const [audioSrc, setAudioSrc] = useState<string>("");
  const audioRef = useRef<HTMLAudioElement>(null);
  const [inputValue, setInputValue] = useState("");
  const [selectedAudio, setSelectedAudio] = useState(data[0]["audio_url"]);
  const [selectedInputPrompt, setSelectedInputPrompt] = useState(data[0]["description"]);
  const [selectedId, setSelectedId] = useState(data[0]["id"]);
  const [selectedName, setSelectedName] = useState(data[0]["name"]);
  const onEndExecuted = useRef(false);
  const [toastShown, setToastShown] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const redirectUser = () => {
    audioRef.current?.pause();
    audioRef.current.currentTime = 0;
    window.location.href = `/chat/${selectedName.replaceAll(" ", "")}`;
  };

  const handleKeyDown = (event) => {
    if (!toastShown) {
      toast.success("Press enter when finished writing text!", {
        position: "bottom-center",
        duration: 5000, // Toast stays for 5 seconds
      });
      setToastShown(true);
    }
    if (event.key === "Enter") {
      streamTextGeneration(false);
      audioRef.current?.pause();
    }
  };
  

  const handleDictate = ({ result }) => {
    if(isListening){
      try {
        setInputValue(result.transcript);
        setIsListening(false);
        streamTextGeneration(true);
      } catch (error) {
        console.error("Error in handleDictate:", error);
      }
    } 
  
  };

  const handleProgress = ({ results }) => {
    try {
      setIsListening(true);
      const interimTranscript = results.map(result => result.transcript).join(" ");
      setInputValue(interimTranscript);
    } catch (error) {
      console.error("Error in handleProgress:", error);
    }
  };

  // NOTE: for drag by mouse
  const dragState = React.useRef(new DragManager());

  const handleDrag = ({ scrollContainer }: typeof VisibilityContext) => (ev: React.MouseEvent) => {
    dragState.current.dragMove(ev, (posDiff) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });
  };

  const onMouseDown = React.useCallback(() => dragState.current.dragStart, [dragState]);
  const onMouseUp = React.useCallback(() => dragState.current.dragStop, [dragState]);

  const isItemSelected = (id: string): boolean => !!selected.find((el) => el === id);

  const handleItemClick = (itemId: string) => {
    if (dragState.current.dragging) {
      return false;
    }
    const itemSelected = isItemSelected(itemId);

    setSelected((currentSelected: string[]) =>
      itemSelected
        ? currentSelected.filter((el) => el !== itemId)
        : currentSelected.concat(itemId),
    );
  };

  const isIphone = () => {
    return /iPhone/.test(navigator.userAgent);
  };

  const streamTextGeneration = async (isWantingToRecord:boolean) => {

    const isUserOnIphone = isIphone();

    if(isUserOnIphone) {

      setIsLoading(true);
      setTalkingState("thinking");
      const audioElement = audioRef.current;

      audioElement?.pause();
      audioElement.currentTime = 0;
  
      const conversationHistory = [];
  
      const openAIChatHistory2 = [
        ...conversationHistory,
        {
          text: inputValue,
          sender: "user",
          profile: "/images/profile.png",
          audioUrl: "",
        },
      ];
  
      const convertedMessages = convertMessages(openAIChatHistory2, selectedInputPrompt, "");
  
  
      const searchParams = new URLSearchParams();
  
      searchParams.set("prompt", "Hello there");
      searchParams.set("responseId", "3412321");
      searchParams.set("history", JSON.stringify(convertedMessages));
      searchParams.set("selectedVoice", selectedAudio);
      searchParams.set("introMsg", "false");
      searchParams.set("chatId", "31232");
      searchParams.set("isRAG", "0");
      setAudioSrc(`${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`);
      setAudioSrc(`${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`);
  
      setInputValue("");
      audioElement.load();
  
  
      audioRef.current.onplay = () => {
        setIsLoading(false);
        setTalkingState("talking");
      };
  
      const playAudio = () => {
        audioElement.play();
  
      };
  
      const onEnd = () => {
        if (onEndExecuted.current) return; // Check if the onEnd has been executed
        onEndExecuted.current = true; // Set the flag to true
        setTalkingState("idle");
  
        if (isWantingToRecord) {
          try {
            const dictateButton = document.querySelector(".dictate-button-home");
            if (dictateButton) {
              dictateButton.click();
            } 
            setTalkingState("listening");
          }
          catch (error) {
            console.error("Error in starting voice:", error);
          }
        }
  
      };
  
      audioElement?.addEventListener("loadeddata", playAudio);
      audioElement?.addEventListener("ended", onEnd);
      onEndExecuted.current = false; 
      
      return () => {
        audioElement?.removeEventListener("loadeddata", playAudio);
        audioElement?.addEventListener("ended", onEnd);
        
  
      };
    } else {
      if (isWantingToRecord) {
        if(!isUserOnIphone) {
          try {
            const dictateButton = document.querySelector(".dictate-button-home");
            if (dictateButton) {
              dictateButton.click();
            } 
            setTalkingState("listening");
          } catch (error) {
            console.error("Error in starting voice:", error);
          }
        } else {
          setIsListening(false);
        }
        
      } else {
        setIsListening(false);
      }
  
      setIsLoading(true);
      setTalkingState("thinking");
      const conversationHistory = [];
  
      const openAIChatHistory2 = [
        ...conversationHistory,
        {
          text: inputValue,
          sender: "user",
          profile: "/images/profile.png",
          audioUrl: "",
        },
      ];
  
      const convertedMessages = convertMessages(openAIChatHistory2, selectedInputPrompt, "");
  
      setInputValue("");

      const searchParams = new URLSearchParams();
      searchParams.set("prompt", "Hello there");
      searchParams.set("responseId", "3412321");
      searchParams.set("history", JSON.stringify(convertedMessages));
      searchParams.set("selectedVoice", selectedAudio);
      searchParams.set("introMsg", "false");
      searchParams.set("chatId", "31232");
      searchParams.set("isRAG", "0");
      searchParams.set("imageVision", "");

      const url2 = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
      const audioElement = audioRef.current;
      audioElement?.pause();
      audioElement.currentTime = 0;
      setAudioSrc(url2);
      audioRef.current?.load();
      const playAudio = () => {
        audioRef.current?.play().catch((error) => {
          toast.error("Error attempting to play:", error);
        });
        setTalkingState("talking");
        setIsLoading(false);
      };
      audioRef.current?.addEventListener("loadedmetadata", playAudio);
    }
  };

  

  const sayHello = async () => {


    const isUserOnIphone = isIphone();

    if(isUserOnIphone) {
      setIsLoading(true);
      setTalkingState("thinking");

      const audioElement = audioRef.current;
      setInputValue("");

      audioElement?.pause();
      audioElement.currentTime = 0;

      const conversationHistory = [];
      const normal_intro = "Welcome the user to the Orbofi Universe and briefly introduce yourself to the user.";

      const openAIChatHistory2 = [
        ...conversationHistory,
        {
          text: normal_intro,
          sender: "user",
          profile: "/images/profile.png",
          audioUrl: "",
        },
      ];

      const convertedMessages = convertMessages(openAIChatHistory2, selectedInputPrompt, "wandering user");


      const searchParams = new URLSearchParams();

      searchParams.set("prompt", "Hello there");
      searchParams.set("responseId", "3412321");
      searchParams.set("history", JSON.stringify(convertedMessages));
      searchParams.set("selectedVoice", selectedAudio);
      searchParams.set("introMsg", "false");
      searchParams.set("chatId", "31232");
      searchParams.set("isRAG", "0");
      
      setAudioSrc(`${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`);
      setAudioSrc(`${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`);


      audioElement?.load();

      audioElement.onloadeddata = () => {
        playAudio();
      };

      audioElement.onended = () => {
        setTalkingState("idle");
      };

      audioElement.onerror = () => {
        setIsLoading(false);
        toast.error("Error loading audio");
        setTalkingState("idle");
      };
    }
    else {
      setIsLoading(true);
      setTalkingState("thinking");
      setInputValue("");
  
      const conversationHistory = [];
      const normal_intro = "Welcome the user to the Orbofi Universe and briefly introduce yourself to the user.";

      const openAIChatHistory2 = [
        ...conversationHistory,
        {
          text: normal_intro,
          sender: "user",
          profile: "/images/profile.png",
          audioUrl: "",
        },
      ];
  
      const convertedMessages = convertMessages(openAIChatHistory2, selectedInputPrompt, "wandering user");
  

      // toast.error("user not on iphone");
      const searchParams = new URLSearchParams();
      searchParams.set("prompt", "Hello there");
      searchParams.set("responseId", "3412321");
      searchParams.set("history", JSON.stringify(convertedMessages));
      searchParams.set("selectedVoice", selectedAudio);
      searchParams.set("introMsg", "false");
      searchParams.set("chatId", "31232");
      searchParams.set("isRAG", "0");
      searchParams.set("imageVision", "");

      const url = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
      // Create a MediaSource
      const mediaSource = new MediaSource();
      const audioElement = audioRef.current;

      // Set the audio source
      audioElement.src = URL.createObjectURL(mediaSource);

      mediaSource.addEventListener("sourceopen", async () => {
        // console.log("MediaSource opened");
        const mimeCodec = "audio/mpeg";
        if (MediaSource.isTypeSupported(mimeCodec)) {
          const sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);

          sourceBuffer.addEventListener("updatestart", () => {
            console.log("SourceBuffer: updatestart");
          });

          sourceBuffer.addEventListener("updateend", () => {
            console.log("SourceBuffer: updateend");
          });

          sourceBuffer.addEventListener("error", (e) => {
            console.error("SourceBuffer error:", e);
            toast.error(`SourceBuffer error:, ${e}`);
          });

          // Fetch and stream the audio data
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const reader = response.body.getReader();

          const processChunk = async ({ done, value }) => {
            if (done) {
              console.log("Stream complete");
              mediaSource.endOfStream();
              return;
            }

            // Wait for SourceBuffer to be ready
            await new Promise(resolve => {
              if (!sourceBuffer.updating) {
                resolve();
                return;
              }
              const onUpdateEnd = () => {
                sourceBuffer.removeEventListener("updateend", onUpdateEnd);
                resolve();
              };
              sourceBuffer.addEventListener("updateend", onUpdateEnd);
            });

            console.log("Appending chunk to SourceBuffer");
            sourceBuffer.appendBuffer(value);
            setTalkingState("talking");
            setIsLoading(false);

            // Read the next chunk
            reader.read().then(processChunk);
          };

          // Start reading the first chunk
          reader.read().then(processChunk);
        } else {
          console.error(`MIME type ${mimeCodec} is not supported`);
        }
      });

      // Play the audio within the user interaction context
      audioElement?.play().then(() => {
      }).catch(error => {
        console.error("Playback error:", error);
      });
    }
    

  };

  const playAudio = () => {
    setTalkingState("talking");
    const audioElement = audioRef.current;
    if (audioElement) {
      const playPromise = audioElement.play();
      if (playPromise !== undefined) {
        playPromise.then(() => {
          setIsLoading(false);
        }).catch(error => {
          setIsLoading(false);
          console.error("Error playing audio:", error);
          toast.error("Error playing audio");
        });
      }
    }
  };

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    sayHello();
    const audio = new Audio("images/water_drop.mp3");
    // Play the audio
    audio.play();
    setIsClicked(true);

  };
  const onExit = () => { localStorage.setItem("introBots", "completed"); setIntroCompleted(true); };
  const [activeOptionmain, setActiveOption] = useState(0);

  const videoRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    const handleCanPlay = () => {
      setIsVideoLoaded(true);
      video.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
    };

    if (video) {
      video.addEventListener("canplay", handleCanPlay);
      return () => {
        video.removeEventListener("canplay", handleCanPlay);
      };
    }
  }, []);

  useEffect(() => {
    const initializeSpeechServices = async () => {
      const services = await createPonyfill({
        credentials: {
          region: "eastus",
          subscriptionKey: "4ad414dbae5345908b65ea9e16cc11ca",
        },
      });
      setSpeechServices(services);
    };

    initializeSpeechServices();
 
  }, []);

  if (!speechServices) {
    return <div>Loading...</div>;
  }

  const audiomain = () => {
    if (isListening) {
      setIsListening(false);
    } else {
      audioRef.current.pause();
      const audio = new Audio(tone);
      audio.volume = 0.03;
      audio.play();
      console.log(audio, "audio");
      console.log("audio effect playing");
    }
  };

  const { SpeechGrammarList, SpeechRecognition } = speechServices;
  return (
    <div className="HomeBanner-Main text-center">
   
      <video
        ref={videoRef}
        className={`video ${isVideoLoaded ? "loaded" : "loading"}`}
        src="/hero-video.mp4"
        type="video/mp4"
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
        poster="/images/herobgimg.png"
        style={{pointerEvents: "none"}}
      /> <div className="HomeBanner-Info">
        <h1> {t("create_your_own_ai_agents_clone_yourself_explore_t")} </h1>
        <div className={`chatbotmainwrap ${isClicked ? "show" : ""}`}>

          {!isClicked && <Link to="#" className="chatbotimgmainhome" onClick={handleClick}>
            <Image src="images/logo-icon.png" className="orbofiicon" />
            <span>Click Me</span>
          </Link>}
          <div className="GenerateAnything-Search-box">
            <div className="selectorbogi">
              <Select
                className="reactSelect"
                options={options}
                onChange={handleChange}
                value={selectedOption}
                isSearchable={false}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.name}
                formatOptionLabel={(option) => (
                  <div className="dropdown-img-home" style={{ "fontSize": "12px" }}>
                    <i>
                      <Image src={option.img} />
                      {talkingState === "talking" ? <iframe src="https://lottie.host/embed/fdd106b2-2576-4038-b826-ed75ec58898d/iFyVZq89dK.json" className="soundwave"></iframe> : ""}
                    </i>
                    {option.name}
                  </div>
                )}
              /></div>
            <div className="searchmain homesearch">

              <input type="text" placeholder={t("create_your_ai_companion")} value={inputValue} onChange={handleInputChange} onKeyDown={handleKeyDown} />
            </div>
            <Link to="javascript:void(0)" className='Chat-msg-recorder' onClick={audiomain}>
              <div>
                <DictateButton
                  onDictate={handleDictate}
                  speechGrammarList={SpeechGrammarList}
                  speechRecognition={SpeechRecognition}
                  onProgress={handleProgress}
                  className="dictate-button-home"
                >
                  <FourthButton child={<><i><Icon icon={isListening ? "ph:microphone-fill" : "ph:microphone"} style={{ color: isListening ? "red" : "white" }} /></i></>} className={"HomeStartAI-btn HomeRecordBtn"} />
                </DictateButton>
              </div>
            </Link>
          </div>
          <audio className="w-full" ref={audioRef} src={audioSrc} controls autoPlay
            style={{ "display": "none" }}
          />
          <div className="ChatScreenOptions mt-2" onMouseLeave={dragState.current.dragStop}>
            <ScrollMenu
              LeftArrow={LeftArrow}
              RightArrow={RightArrow}
              onMouseDown={onMouseDown}
              onMouseUp={onMouseUp}
              onMouseMove={handleDrag}
              onWheel={onWheel}
            >
              <li><Button id='step1' variant="" className={activeOptionmain === "ask_nad_chat" ? "active" : ""} onClick={() => redirectUser()}><i>
                <LordIcon src="https://cdn.lordicon.com/obawqdgi.json" trigger="loop" colors="primary:#ffffff,secondary:#ebe6ef,tertiary:#54abb1"></LordIcon>
              </i> {t("ask_and_chat")}</Button></li>
              <li><Button id='step2' variant="" className={activeOptionmain === "image_generation" ? "active" : ""} onClick={() => redirectUser()}><i>
                <LordIcon src="https://cdn.lordicon.com/pbhjpofq.json" trigger="loop" colors="primary:#ffffff,secondary:#54abb1,tertiary:#4bb3fd,quaternary:#ffc738,quinary:#f24c00,senary:#ffffff,septenary:#f9c9c0"></LordIcon>
              </i>{t("image_generation")}</Button></li>
              <li><Button id='step3' variant="" className={activeOptionmain === "website-generation" ? "active" : ""} onClick={() => redirectUser()}><i>
                <LordIcon src="https://cdn.lordicon.com/ddexfvmy.json" trigger="loop" colors="primary:#ffffff,secondary:#ffffff,tertiary:#54abb1"></LordIcon>
              </i> {t("website-generation")} </Button></li>
              <li><Button id='step4' variant="" className={activeOptionmain === "code" ? "active" : ""} onClick={() => redirectUser()}><i>
                <LordIcon src="https://cdn.lordicon.com/ejcvkxer.json" trigger="loop" colors="primary:#ffffff,secondary:#ffc738,tertiary:#f24c00,quaternary:#54abb1,quinary:#4bb3fd,senary:#fafafa,septenary:#ebe6ef"></LordIcon>
              </i> {t("code")} </Button></li>
              <li><Button id='step5' variant="" className={activeOptionmain === "GPT-4" ? "active" : ""} onClick={() => redirectUser()}><i>
                <LordIcon src="https://cdn.lordicon.com/ucekksbn.json" trigger="loop" colors="primary:#ffffff,secondary:#ffffff,tertiary:#54abb1"></LordIcon>
              </i> GPT-4 </Button><span className="protag"><Icon icon="noto-v1:crown" /></span></li>
              <li><Button variant="" className={activeOptionmain === "longform_text" ? "active" : ""} onClick={() => redirectUser()}><i>
                <LordIcon src="https://cdn.lordicon.com/pwwdvajw.json" trigger="loop" colors="primary:#ffffff,secondary:#ffffff,tertiary:#54abb1"></LordIcon>
              </i> {t("longform_text")} </Button></li>
              <li><Button id='step6' variant="" className={activeOptionmain === "vision_power" ? "active" : ""} onClick={() => redirectUser()}><i>
                <LordIcon src="https://cdn.lordicon.com/xkkkkglu.json" trigger="loop" colors="primary:#ffffff,secondary:#54abb1,tertiary:#ffffff"></LordIcon>
              </i> {t("vision_power")} </Button><span className="protag"><Icon icon="noto-v1:crown" /></span></li>
              <div><Steps enabled={!(localStorage.getItem("introBots") == "completed")} steps={[]} initialStep={0} onExit={onExit} /></div>
            </ScrollMenu>
          </div>
        </div>
        <div>
          {isLoading ? (<img
            src={
              "/assets/loading.gif"
            }
          ></img>) : null}
        </div>
        <ul className="AICompanionsubInfo">
          <li><i><lord-icon
            src="https://cdn.lordicon.com/eewfjpmj.json"
            trigger="loop"
          >
          </lord-icon></i> {t("made_with_love")}</li>
          <li><i><lord-icon
            src="https://cdn.lordicon.com/pcllgpqm.json"
            trigger="loop"
          >
          </lord-icon></i> {t("community_build")}</li>
          <li><i><lord-icon
            src="https://cdn.lordicon.com/jcwlpwlr.json"
            trigger="loop"
            colors="primary:#ffffff,secondary:#ffc738,tertiary:#f28ba8,quaternary:#f24c00,quinary:#4bb3fd"
          >
          </lord-icon></i> {t("train_and_create_in_second")}</li>
        </ul>
      </div>
    </div>
  );
};

export default HomeBanner;

class DragManager {
  clicked: boolean;
  dragging: boolean;
  position: number;

  constructor() {
    this.clicked = false;
    this.dragging = false;
    this.position = 0;
  }

  public dragStart = (ev: React.MouseEvent) => {
    this.position = ev.clientX;
    this.clicked = true;
  };

  public dragStop = () => {
    window.requestAnimationFrame(() => {
      this.dragging = false;
      this.clicked = false;
    });
  };

  public dragMove = (ev: React.MouseEvent, cb: (posDiff: number) => void) => {
    const newDiff = this.position - ev.clientX;

    const movedEnough = Math.abs(newDiff) > 5;

    if (this.clicked && movedEnough) {
      this.dragging = true;
    }

    if (this.dragging && movedEnough) {
      this.position = ev.clientX;
      cb(newDiff);
    }
  };
}

function LeftArrow() {
  const { initComplete, isFirstItemVisible, scrollPrev } =
    React.useContext<publicApiType>(VisibilityContext);
  return (
    <Arrow
      disabled={!initComplete || (initComplete && isFirstItemVisible)}
      onClick={() => scrollPrev()}
      testId="left-arrow"
    >
      <Icon icon="iconamoon:arrow-left-2-bold" />
    </Arrow>
  );
}

function RightArrow() {
  const { initComplete, isLastItemVisible, scrollNext } =
    React.useContext<publicApiType>(VisibilityContext);

  return (
    <Arrow
      disabled={initComplete && isLastItemVisible}
      onClick={() => scrollNext()}
      testId="right-arrow"
    >
      <Icon icon="iconamoon:arrow-right-2-bold" />
    </Arrow>
  );
}

function Arrow({
  children,
  disabled,
  onClick,
  className,
  testId,
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
  className?: string;
  testId: string;
}) {
  return (
    <button type='button'
      disabled={disabled}
      onClick={onClick}
      className={"arrow" + `-${className}`}
      data-testid={testId}
    >
      {children}
    </button>
  );
}


const getId = (index: number) => `${"test"}${index}`;

const getItems = () =>
  Array(10)
    .fill(0)
    .map((_, ind) => ({ id: getId(ind) }));

function onWheel(apiObj: publicApiType, ev: React.WheelEvent): void {

  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else {
    apiObj.scrollPrev();
  }
}