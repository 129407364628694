import { Tab } from "react-bootstrap";
import ChatbotMainItem from "./ChatbotMainItem";
import { ChatbotData } from '../../Utils/ChatbotUtils';

function ChatbotsList({ chatbots, loadMore, records, hasMore }: { chatbots: ChatbotData[], loadMore: () => void, records: number, hasMore: boolean }) {
  return (
    <>
      <div className="ChatbotsListHeader mt-lg-4 mt-3 trandingmain">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Tab.Content className="mt-3">
            <Tab.Pane eventKey="first">
              <ChatbotMainItem chatbots={chatbots} loadMore={loadMore} records={records} hasMore={hasMore} />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <ChatbotMainItem chatbots={chatbots} loadMore={loadMore} records={records} hasMore={hasMore} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>

  );
}

export default ChatbotsList;
