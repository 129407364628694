
import { Icon } from "@iconify/react";
import "./UpgradeCreateMoreCTA.css";
import { Image } from "react-bootstrap";
import PrimaryBtn from "../CommonBtn/PrimaryBtn";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { showPopUpAction } from "../../Redux/Slices/loginPopUpSlice";

const UpgradeCreateMoreCTA = () => {
  const dispatch = useDispatch();
  function OnOpenLoginPopup() {
    dispatch(showPopUpAction(true));
  }
  return (
    <div className="UpgradeCreateMoreAIBg">
      <div className="UpgradeCreateMoreAIBg-Info">
        <i><Icon icon="heroicons:sparkles-solid" /></i>
        <h5>Create your AI agents</h5>
        <p>{t("discover_custom_personalized_ai_companions")}</p>
        {localStorage.getItem("oauthToken") ?
            <PrimaryBtn child={<>{t("Create_AI_agent")} </>} className={""} linkName={"/createaicompanion"} onClick={() => { }} /> : <PrimaryBtn child={<>{t("Create_AI_agent")} </>} className={""} linkName={"/"} onClick={OnOpenLoginPopup} />
        }
      </div>
    </div>
  );
};

export default UpgradeCreateMoreCTA;
