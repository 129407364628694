import { Image } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import ChatbotsList from "./ChatbotsList";
import { useEffect, useState } from "react";
import { ChatbotData, GetChatbotsBySubcategory } from "../../Utils/ChatbotUtils";
import "./ChatbotsPageList.css";

function ChatbotsPageList() {
  const itemsPerPage = 50;
  const location = useLocation();
  const stateData = location.state;
  const [chatbots, setChatbots] = useState<ChatbotData[]>([]);
  const [records, setRecords] = useState(itemsPerPage);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const queryParameters = new URLSearchParams(window.location.search);
  const urlSubcatId = queryParameters.get("subcat");


  useEffect(() => {
    fetchChatbots(0);
  }, []);

  function fetchChatbots(page: number) {
    GetChatbotsBySubcategory(urlSubcatId, page, itemsPerPage).then((newChatbots) => {
      const chatbotsList = chatbots.concat(newChatbots);
      setChatbots(chatbotsList);
      setRecords(prevRecords => prevRecords + newChatbots?.length);
      setHasMore(newChatbots?.length === itemsPerPage);
    });
  }

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchChatbots(nextPage);
  };

  return (
    <>
      <div className="MainContent d-margin padding-16">
        <span className="top-left-bg"> <Image src="/assets/images/top-left-bg.svg" /> </span>
        <span className="top-right-bg"> <Image src="/assets/images/top-right-bg.svg" /> </span>
        <span className="bottom-left-bg"> <Image src="/assets/images/bottom-left-bg.svg" /> </span>
        <span className="bottom-right-bg"> <Image src="/assets/images/bottom-right-bg.svg" /> </span>
        <div className="ChatMainTitleWrap">
          <div className="titleText mb-0">
            <h2>{stateData}</h2>
          </div>
        </div>
        <ChatbotsList chatbots={chatbots} loadMore={loadMore} records={records} hasMore={hasMore} />
      </div>
    </>
  );
}

export default ChatbotsPageList;
