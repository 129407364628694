
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, {useEffect, useState} from "react";
import User from "../../Models/User";
import {useSelector} from "react-redux";
import {userDataSelector} from "../../Redux/Slices/userDataSlice";
import {ChunkArray} from "../../Utils/Utils";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {GetGenerationsListByUser, GetUserGenerationsList, ImageData} from "../../Utils/ImageGenerationsUtils";
import Masonry from "react-masonry-css";

function UserCreatedGenerations({userData} : {userData : User}) {

  const [userImages, setUserImages] = useState<ImageData[]>([]);
  const localUser = useSelector(userDataSelector);

  useEffect(() =>
  {
    if(userData.Email === localUser.Email)
    {
      GetUserGenerationsList(userData.Email).then((images) => { setUserImages(images); });
      return;
    }
    GetGenerationsListByUser(userData.Email).then((images) => { setUserImages(images); });
  }, [userData]);

  const chunkedChatbots = ChunkArray(userImages, 4);
  const breakpointColumns = {
    default: 8,
    1100: 5,
    991: 4,
    700: 3,
    500: 2,
  };
  return (
    <>
      <Masonry
        breakpointCols={breakpointColumns}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {chunkedChatbots.map((chunk, chunkIndex) => 
          chunk.map((image, index) =>
          {
            const aiImage = image as ImageData;
            return (
        
              <Link to={"/"}>
                <div className="masonry-Image">
                  <LazyLoadImage
                    alt={`Image ${index}`}
                    effect="opacity"
                    wrapperProps={{
                      style: {transitionDelay: "1s"},
                    }}
                    src={aiImage.image_url} // use normal <img> attributes as props
                  />
                </div>
              </Link>
        
            );
          }
          ))}
      </Masonry>
    </>
  );
}

export default UserCreatedGenerations;
