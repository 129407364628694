import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import {
  ChatbotData,
  ConversationData, GetChatbotVoiceByUrl,
  GetChatbotVoices,
  GetParentCategories, GetUserCategories, GetUserName,
  StatsData
} from '../../Utils/ChatbotUtils';
import { ChatMessage } from "./interface";
import { Button, Dropdown, Modal } from "react-bootstrap";

import FCompanionsInfoMoreSetting from "./FCompanionsInfoMoreSetting";
import { t } from "i18next";
import {Link} from "react-router-dom";
import { isEmpty } from "lodash";
import {useSelector} from 'react-redux';
import {dirtyFlagsSelector} from '../../Redux/Slices/dirtyFlagsSlice';

interface ComponentProps {
    setOpenSetting: (val: boolean) => void;
    openSetting: boolean
}

function FCompanionsInfoLessSetting({ chatbotData, chatbotHistory, setStatsData, setChatHistory, setMessagesCounter, description }: {
    chatbotData: ChatbotData,
    chatbotHistory: ConversationData,
    setStatsData: React.Dispatch<React.SetStateAction<StatsData>>,
    setChatHistory: React.Dispatch<React.SetStateAction<ChatMessage[]>>,
    setMessagesCounter: React.Dispatch<React.SetStateAction<number>>,
    description: string
}) {
  const [loading, setLoading] = useState(true);
  const [category, setCategoryName] = useState("");
  const [botCreator, setBotCreator] = useState("");
  const [initialVoice, setInitialVoice] = useState<string>("");
  const [initialVoiceLoaded, setInitialVoiceLoaded] = useState<boolean>(false);
  const dirtyFlags = useSelector(dirtyFlagsSelector);

  useEffect(() =>
  {
    if(!chatbotData?.audio_url) return;
    GetChatbotVoiceByUrl(chatbotData?.audio_url).then((voice) => { setInitialVoice(voice.Name); setInitialVoiceLoaded(true);});
  }, [chatbotData, dirtyFlags.BotDataLoaded]);

  useEffect(() =>
  {
    if(!isEmpty(chatbotData)) {

      if (chatbotData?.user_id !== undefined && chatbotData?.user_id >= 0)
      {
        GetUserName(chatbotData.user_id).then((name) => {setBotCreator(name);});
        GetUserCategories().then((categories) =>
        {
          const category = categories.find((category) => category.CategoryId === chatbotData?.category_id);
          if (category)
          {
            setCategoryName(category.Name);
          }
          else
          {
            setCategoryName("General");
          }
        });
      }
      else
      {
        GetParentCategories(1, 25).then((categories) =>
        {
          const category = categories.find((category) => category.ParentCategoryId === chatbotData.category_id);
          if (category)
          {
            setCategoryName(category.Name);
          }
          else
          {
            setCategoryName("General");
          }
        });
      }
    }
  }, [chatbotData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const getDataFromURL = (url) => {
    const params = new URLSearchParams(url.search);
    return params.get('data');
  };

  const dataValue = parseInt(getDataFromURL(location), 10);

  // Determine the tag to display
  const isOfficial = dataValue == 100000 || dataValue == 99999;
  return (
    <div className='FCompanions-Info-less-setting'>
      {loading ? (
        <div className=" animated fadeIn"> {/* Add animation class */}
          <div className="skeleton FCompanions-Info-Title mb-3">
            <h6 className="opacity-0">{chatbotData?.name}</h6>
          </div>
          <div className="skeleton FCompanions-Info-Title ">
            <p className="opacity-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi, sed. </p>
          </div>
          <div className="skeleton FCompanions-Wrap">
            <h6 className='ChatScreenHeaderuser opacity-0'><Icon icon="game-icons:heavy-bullets" width="16px" height="16px" /> Category</h6>
          </div>
        </div>
      ) : (
        <>
          <div className="FCompanions-Info-Title mb-2">
            <h6>{chatbotData?.name}   {chatbotData?.source === 4 ? (<span><Icon style={{color:"#b39800"}} icon="fluent:checkmark-starburst-16-filled" /></span>) :  ''}</h6>
          </div>
          <p>{description?.substring(0, 120) + (description?.length > 120 ? "..." : "")}</p>
          <div className="FCompanions-Info-Title mb-2">
            {botCreator !== "" ? <h2>Created by: <Link to={`/user/${botCreator}`}>{botCreator}</Link></h2> : ""}
          </div>
          <div className="FCompanions-Wrap">
            <h6 className='ChatScreenHeaderuser'><Icon icon="game-icons:heavy-bullets" width="16px"
              height="16px"/> Category: {category}</h6>
              {/* seems to be broken, removed*/ }
            {/* <Dropdown className="profile-dropdown">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <div className="Header-profile-main" onClick={handleShow}>
                  <h6><i><Icon icon="ep:arrow-down-bold"/></i></h6>
                </div>
              </Dropdown.Toggle>
            </Dropdown> */}
          </div>
        </>
      )}
      <Modal show={show} onHide={handleClose} centered size="lg" className='default-modal parametermodal'>
        <div className='default-modal-header'>
          <h2>{t("choose_parameters")}</h2>
          <Button variant="" onClick={handleClose}><Icon icon="ion:close-circle-outline" /></Button>
        </div>
        <Modal.Body>
          {initialVoiceLoaded && <FCompanionsInfoMoreSetting chatbotData={chatbotData} initialVoiceParam={initialVoice} /> }
        </Modal.Body>
      </Modal>
    </div>
  );

}

export default FCompanionsInfoLessSetting;
