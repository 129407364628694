import React, { useState, useEffect, useCallback } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { motion, AnimatePresence } from 'framer-motion';

interface PDFModalProps {
  isOpen: boolean;
  onClose: () => void;
  pdfUrl: string;
  pageNumber: number;
  title: string;
}

const PDFModal: React.FC<PDFModalProps> = ({ isOpen, onClose, pdfUrl, pageNumber, title }) => {
  const [currentPage, setCurrentPage] = useState<number>(pageNumber);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [pageNumber]);

  const handleOverlayClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  }, [onClose]);

  if (!isOpen) return null;

  const styles = {
    overlay: {
      position: 'fixed' as const,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      backdropFilter: 'blur(5px)',
    },
    modal: {
      backgroundColor: '#ffffff',
      borderRadius: '20px',
      width: '95%',
      height: '95%',
      display: 'flex',
      flexDirection: 'column' as const,
      overflow: 'hidden',
      boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px 30px',
      borderBottom: '1px solid #e0e0e0',
    },
    title: {
      color: '#333',
      margin: 0,
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    closeButton: {
      background: 'none',
      border: 'none',
      color: '#999',
      fontSize: '2rem',
      cursor: 'pointer',
      transition: 'color 0.3s ease',
      ':hover': {
        color: '#333',
      },
    },
    content: {
      flex: 1,
      overflow: 'hidden',
      padding: '20px',
    },
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          style={styles.overlay}
          onClick={handleOverlayClick}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={styles.modal}
          >
            <div style={styles.header}>
              <h2 style={styles.title}>{title}</h2>
              <button onClick={onClose} style={styles.closeButton}>&times;</button>
            </div>
            <div style={styles.content}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={pdfUrl}
                  plugins={[defaultLayoutPluginInstance]}
                  initialPage={currentPage - 1}
                />
              </Worker>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PDFModal;
