import { Image } from "react-bootstrap";
import ExploreBanner from "../../Components/ExploreCompo/ExploreBanner/ExploreBanner";
import "./ExplorePageCategories.css";
import CompanionsSlider from "../../Components/CompanionsSlider/CompanionsSlider";
import CollectionsCTABoxs from "../../Components/CollectionsCTABoxs/CollectionsCTABoxs";
import CompanionShorts from "../../Components/CompanionShorts/CompanionShorts";
import AllTimeFavBanner from "../../Components/AllTimeFavBanner/AllTimeFavBanner";
import CategoriesBigBox from "../../Components/CategoriesBigBox/CategoriesBigBox";
import CategoriesBox from "../CategoriesBox/CategoriesBox";
import CategoriesFilter from "../../Components/CategoriesFilter/CategoriesFilter";
import Categories from "../../Components/Categories/Categories";
import UpgradeCreateMoreCTA from "../../Components/UpgradeCreateMoreCTA/UpgradeCreateMoreCTA";
import TinderSwiper from "../HomePage/HomeSlider";
import { t } from "i18next";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { showPopUpAction } from "../../Redux/Slices/loginPopUpSlice";
import { useDispatch } from "react-redux";


function ExplorePageCategories() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const source = searchParams.get("source");
  const content = searchParams.get("content");

  useEffect(() => {
  }, [location]);
  const isCategoriesBigBoxAvailable = true;
  const dispatch = useDispatch();

  function OnOpenLoginPopup() {
    dispatch(showPopUpAction(true));
  }
  return (
    <div className="MainContent CategoriesBigBoxpage padding-16 d-margin">

      <span className="top-left-bg"> <Image src="/assets/images/top-left-bg.svg" /> </span>
      <span className="top-right-bg"> <Image src="/assets/images/top-right-bg.svg" /> </span>
      <span className="bottom-left-bg"> <Image src="/assets/images/bottom-left-bg.svg" /> </span>
      <span className="bottom-right-bg"> <Image src="/assets/images/bottom-right-bg.svg" /> </span>
      <div className="Breadcrumb-Wrap">
      </div>

      {/* <h2 className="exploretitle">Explore all of our categories and find some interesting chatbots to talk.</h2> */}

      <div className="ChatMainTitleWrap">
        <div className="titleText mb-0">
          <h2>{content}</h2>
          <p>{t("create_your_personalize_ai_companion_that_can_boos")}</p>
        </div>

        {localStorage.getItem("oauthToken") ?
          <>
            <Link to="/createaicompanion" className="btn-primary">{t("create_ai")} <i><Icon icon="heroicons:sparkles-solid" /></i></Link>

          </>
          :
          <>
            <Link to="/" onClick={OnOpenLoginPopup} className="btn-primary">{t("create_ai")} <i><Icon icon="heroicons:sparkles-solid" /></i></Link>




          </>
        }
      </div>


      <div className=" categorymainlist CategoriesBigBoxRow Categoriesmain">
        <CategoriesBigBox isExplorePage={false} />
      </div>

    </div>
  );
}

export default ExplorePageCategories;
