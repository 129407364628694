import React, { useState, useEffect, useRef } from 'react';
import './Typewriter.css'; // Import the CSS file
import parse from 'html-react-parser';

const Typewriter = ({ text, speed = 100, onComplete }) => {
  const [displayedText, setDisplayedText] = useState([]);
  const [currentText, setCurrentText] = useState('');
  const textIndex = useRef(0);
  const intervalId = useRef(null);

  // Function to parse displayed text into JSX elements with animation
  const parseDisplayedText = (textArray) => {
    const joinedText = textArray.map((char, index) => {
      // console.log(char);
      // if (char === ' ') {
      //   return <span key={index} className="fade-in">&nbsp;</span>;
      // }
      if (char.includes('<ul>') || char.includes('</ul>')) {
        return <span key={index} className="fade-in">{parse(char)}</span>;
      }
      else if (char.includes('<li>') ) {
        return <span key={index} className="fade-in">{parse(char)}</span>;
      }
      else if (char.includes('</li>') ) {
        return <br/>;
      }
      else if (char.includes('</strong>')) {
        return <span key={index} className="fade-in">{parse("</strong>")}</span>;
      }
      else if (char.includes('<p>') || char.includes('</p>')) {
        return <span key={index} className="fade-in">{parse(char)}</span>;  
      }
      else if (char.includes('<br>')) {
        return <span key={index} className="fade-in">{parse(char)}</span>;
      }
      return <span key={index} className="fade-in">{char}</span>;
    });

    return joinedText;
  };

  "<p>Fighting for freedom requires commitment, collective action, and strategic planning. Here are three ways I believe are crucial in fighting for freedom: </p><br> <ul> <li><strong>Educate and Organize: </strong> The foundation of any movement is education. Understanding the issues at hand and educating others creates an informed and engaged community. Organizing these individuals into cohesive groups helps in strategizing and mobilizing actions. </li> <li><strong>Grassroots involvement: </strong> True change happens from the bottom up. Engaging local communities to participate actively in the movement ensures that the change is rooted deeply in the needs and wants of those affected by injustice. </li> <li><strong>Nonviolent Direct Action: </strong> As demonstrated during the Civil Rights Movement, nonviolent direct action effectively confronts injustice and brings about social change. It involves peaceful protests, sit-ins, boycotts, and other forms of peaceful resistance."

  useEffect(() => {
    const type = () => {
      if (textIndex.current < currentText.length) {
        setDisplayedText((prevDisplayedText) => {
          let nextSpaceIndex = currentText.indexOf(' ', textIndex.current);
          if (nextSpaceIndex === -1) nextSpaceIndex = currentText.length;
          const newText = currentText.slice(textIndex.current, nextSpaceIndex) + " ";
          textIndex.current = nextSpaceIndex + 1;

          if (newText.includes('[') && onComplete) {
            cancelAnimationFrame(intervalId.current);
            onComplete();
          }

          return [...prevDisplayedText, newText];
        });
      }
    };

    const step = () => {
      type();
      intervalId.current = setTimeout(step, speed);
    };

    intervalId.current = setTimeout(step, speed);

    return () => clearTimeout(intervalId.current);
  }, [currentText, speed, onComplete]);

  useEffect(() => {
    if (text.length > displayedText.length) {
      setCurrentText(text);
    }
  }, [text]);

  return (
    <div className="typewriter">
      {parseDisplayedText(displayedText)}
    </div>
  );
};

export default Typewriter;
