import { History, HistoryItemType } from "./history_types";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { ImageFileWithPreview } from "../../../Pages/ChatScreenPage/interface";
import { Tooltip } from 'react-bootstrap';
import { AppState } from "../hooks/types";
import classNames from "classnames";

interface Props {
  history: History;
  currentVersion: number | null;
  revertToVersion: (version: number) => void;
  setImageFilesWithPreviews: (arr: ImageFileWithPreview[]) => void;
  reset: () => void;
  shouldDisableReverts: boolean;
  activeAppState: AppState;
}

function HistoryDisplay({
  history,
  currentVersion,
  revertToVersion,
  shouldDisableReverts,
  activeAppState,
  reset,
  setImageFilesWithPreviews,
}: Props) {
  
  // This function now just returns a string and does not use any hooks.
  function displayHistoryItemType(itemType: HistoryItemType) {
    switch (itemType) {
      case "ai_create":
        return "Create";
      case "ai_edit":
        return "Edit";
      default: {
        const exhaustiveCheck: never = itemType;
        throw new Error(`Unhandled case: ${exhaustiveCheck}`);
      }
    }
  }

  return (
    <div>
      {history.length > 0 && (
        <div>
          <div className="versionTitleWrap">
            <h3 className="versionTitle">Versions</h3>
            {activeAppState === AppState.CODING ? (
              <></>
            ) : (
              <button onClick={() => {reset(); setImageFilesWithPreviews([]);}} className="btn-primary">Reset</button>
            )}
          </div>
          <ul className="versionHistory">
            {history.map((item, index) => (
              <li key={index} className={classNames({"active": currentVersion === index}, "historyItem")}>
                <Tooltip title={displayHistoryItemType(item.type)}>
                  <button
                    disabled={shouldDisableReverts}
                    onClick={() =>
                      shouldDisableReverts
                        ? toast.error("Viewing a previous version is unavailable until code generation finalizes.")
                        : revertToVersion(index)
                    }
                    className="historyButton"
                  >
                    {`${displayHistoryItemType(item.type)} v${index + 1}`}
                    {item.parentIndex !== null && item.parentIndex !== index - 1 && ` (parent: v${item.parentIndex + 1})`}
                  </button>
                </Tooltip>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default HistoryDisplay;
