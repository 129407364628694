import { Col } from "react-bootstrap";
import CompanionsBox from "../../Components/CompanionsBox/CompanionsBox";
import { ChatbotData } from "../../Utils/ChatbotUtils";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useState } from "react";

function ChatbotMainItem({ chatbots, loadMore, records, hasMore }: { chatbots: ChatbotData[], loadMore: () => void, records: number, hasMore: boolean }) {
  const [showData, setShowData] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowData(true);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [records]);

  return (

    <InfiniteScroll
      dataLength={records}
      className="row"
      next={() => loadMore()}
      hasMore={hasMore}
      loader={
        <div className="AiImageLoader">
          <div>
            <div className="nb-spinner"></div>
          </div>
        </div>
      }
    >
      {chatbots.length > 0 ? (
        chatbots.map((chatbot, index) => (
          <Col lg={2} key={index}>
            <CompanionsBox chatbotData={chatbot} />
          </Col>
        ))
      ) : (
        <div className="text-center"></div>
      )}
    </InfiniteScroll>

  );
}

export default ChatbotMainItem;



// import { Col } from "react-bootstrap";
// import CompanionsBox from "../../Components/CompanionsBox/CompanionsBox";
// import { ChatbotData } from "../../Utils/ChatbotUtils";
// import InfiniteScroll from "react-infinite-scroll-component";
// import { useEffect, useRef, useState } from "react";

// function ChatbotMainItem({ chatbots, loadMore, records, hasMore }) {
//   const [showData, setShowData] = useState(false);
//   const [itemsToShow, setItemsToShow] = useState([]);
//   const containerRef = useRef(null);

//   useEffect(() => {
//     const timeout = setTimeout(() => {
//       setShowData(true);
//     }, 2000);

//     return () => clearTimeout(timeout);
//   }, [records]);

//   useEffect(() => {
//     if (showData) {
//       const observer = new IntersectionObserver(entries => {
//         entries.forEach(entry => {
//           if (entry.isIntersecting) {
//             entry.target.classList.add('show');
//           }
//         });
//       }, { rootMargin: '0px 0px 100px 0px' });

//       const items = document.querySelectorAll('.chatbot-item');
//       items.forEach(item => {
//         observer.observe(item);
//       });

//       return () => observer.disconnect();
//     }
//   }, [showData]);

//   useEffect(() => {
//     if (showData) {
//       const interval = setInterval(() => {
//         if (itemsToShow.length < chatbots.length) {
//           setItemsToShow(prevItems => [
//             ...prevItems,
//             chatbots[prevItems.length]
//           ]);
//         } else {
//           clearInterval(interval);
//         }
//       }, 50);

//       return () => clearInterval(interval);
//     }
//   }, [showData, chatbots]);

//   return (
//     <InfiniteScroll
//       dataLength={records}
//       className="row"
//       next={() => loadMore()}
//       hasMore={hasMore}
//       loader={
//         <div className="AiImageLoader">
//           <div>
//             <div className="nb-spinner"></div>
//           </div>
//         </div>
//       }
//     >
//       {itemsToShow.map((chatbot, index) => (
//         <Col lg={2} key={index} ref={containerRef}>
//           <div className={`chatbot-item${showData ? ' show' : ''}`}>
//             <CompanionsBox chatbotData={chatbot} />
//           </div>
//         </Col>
//       ))}
//     </InfiniteScroll>
//   );
// }

// export default ChatbotMainItem;
