import { Col, Image, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Link, useLocation } from "react-router-dom";
import "./CategoriesBigBox.css";
import { useEffect, useState } from "react";
import Subcategory from "../../Models/Subcategory";
import { GetParentCategories } from "../../Utils/ChatbotUtils";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { showPopUpAction } from "../../Redux/Slices/loginPopUpSlice";
import PrimaryBtn from "../CommonBtn/PrimaryBtn";

function CategoriesBigBox({ isExplorePage }: { isExplorePage: boolean }) {
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const location = useLocation();
  const queryParameters = new URLSearchParams(window.location.search);
  const urlSubcatId = queryParameters.get("source");
  const itemsPerPage = isExplorePage ? 30 : 30;
  const [displayCatNumber, setDisplayCatNumber] = useState(itemsPerPage);
  const [records, setRecords] = useState(itemsPerPage);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  // Default items per page
  useEffect(() => {
    function updateItemsPerPage() {
      const screenWidth = window.innerWidth;
      const isMobileWidth = screenWidth <= 768; // Define mobile threshold
      if (isMobileWidth) {
        setDisplayCatNumber(10);
      } else {
        setDisplayCatNumber(50);
      }

    }

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);
  useEffect(() => {
    fetchCategories(0);
  }, []);


  function fetchCategories(page: number) {
    GetParentCategories(page, itemsPerPage).then((newCategories: Subcategory[]) => {
      const subCatList = subcategories.concat(newCategories);
      setSubcategories(subCatList);
      setRecords(prevRecords => prevRecords + newCategories?.length);
      setHasMore(newCategories?.length === itemsPerPage);
    });
  }

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchCategories(nextPage);
  };

  const [showData, setShowData] = useState(false);

  useEffect(() => {
    // This effect will run every time 'records' changes,
    // and will set a timeout to show the data after 2 seconds
    const timeout = setTimeout(() => {
      setShowData(true);
    }, 2000);

    return () => clearTimeout(timeout); // Cleanup on component unmount
  }, [records]); // Re-run this effect whenever 'records' changes
  const renderSubCat = (catNumber: number
  ) => {
    return subcategories.slice(0, catNumber).map((subcategory: Subcategory, index) => (
      <SubCategoryBox key={index} subcategoryData={subcategory} location={location} />
    ));
  };
  return (
    <>
      <div className="maincategory">
        {isExplorePage ? (
          <>
            <Row className="CategoriesBigBoxRow">
              {renderSubCat(displayCatNumber)}
            </Row>

          </>
        ) : (
          <InfiniteScroll
            dataLength={records}
            className="row CategoriesBigBoxRow"
            next={() => loadMore()}
            hasMore={hasMore}
            // height={window.innerHeight}
            loader={
              <div className="AiImageLoader">
                <div>
                  <div className="nb-spinner"></div>
                </div>
              </div>
            }
          >
            {showData.length > 0 ? (showData && subcategories.map((subcategory: Subcategory, index) => (
              <SubCategoryBox key={index} subcategoryData={subcategory} location={location} />
            )))
              : (
                <div className="text-center">No chatbots found.</div>
              )}
          </InfiniteScroll>
        )}

      </div>
    </>
  );
}

function SubCategoryBox({ subcategoryData }: { subcategoryData: Subcategory, location: any }): JSX.Element {
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);
  const dispatch = useDispatch();

  function OnOpenLoginPopup() {
    dispatch(showPopUpAction(true));
  }
  return (
    <>
      <Col lg={3}>

        <div className="CategoriesBigBox-Main">
          <div className="CategoriesBigBox-Image">
            {loading ? (
              <Row >
                {subcategoryData?.CoverImageUrl[0] && <Col lg={6} >
                  <div className="CategoriesBigBox-img skeleton">
                    <Link to={`/search-category?subcat=${subcategoryData?.ParentCategoryId}`} state={subcategoryData?.Name} className="opacity-0">
                      <Image src={subcategoryData?.CoverImageUrl[0]} />
                    </Link>
                  </div>
                </Col>}
                {subcategoryData?.CoverImageUrl?.[1] && <Col lg={6} >
                  <div className="CategoriesBigBox-img skeleton">
                    <Link to={`/search-category?subcat=${subcategoryData?.ParentCategoryId}`} state={subcategoryData?.Name} className="opacity-0">
                      <Image src={subcategoryData?.CoverImageUrl[1]} />
                    </Link>
                  </div>
                </Col>}
                {subcategoryData?.CoverImageUrl?.[2] && <Col lg={6} className="CategoriesBigBox-p-0 ">
                  <div className="CategoriesBigBox-img skeleton">
                    <Link to={`/search-category?subcat=${subcategoryData?.ParentCategoryId}`} state={subcategoryData?.Name} className="opacity-0">
                      <Image src={subcategoryData?.CoverImageUrl[2]} />
                    </Link>
                  </div>
                </Col>}
                {subcategoryData?.CoverImageUrl?.length > 3 && <Col lg={6} className="CategoriesBigBox-p-0 ">
                  <div className="CategoriesBigBox-sub-img-main">
                    <Row >
                      <Col lg={6} >
                        <div className="CategoriesBigBox-sub-Wrap">
                          <div className="CategoriesBigBox-sub-img skeleton">
                            <Link to={`/search-category?subcat=${subcategoryData?.ParentCategoryId}`} state={subcategoryData?.Name} className="opacity-0">
                              <Image src={subcategoryData?.CoverImageUrl[3]} />
                            </Link>
                          </div>
                          <div className="CategoriesBigBox-sub-img skeleton">
                            <Link to={`/search-category?subcat=${subcategoryData?.ParentCategoryId}`} state={subcategoryData?.Name} className="opacity-0">
                              <Image src={subcategoryData?.CoverImageUrl[4]} />
                            </Link>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="CategoriesBigBox-sub-Wrap">
                          {subcategoryData?.CoverImageUrl?.[5] && <div className="CategoriesBigBox-sub-img skeleton">
                            <Link to={`/search-category?subcat=${subcategoryData?.ParentCategoryId}`} state={subcategoryData?.Name} className="opacity-0"><Image src={subcategoryData?.CoverImageUrl[5]} /></Link>
                          </div>}
                          <div className="CategoriesBigBox-sub-img-add">
                            {localStorage.getItem("oauthToken") ?
                              <>
                                <Link to="/createaicompanion"><Icon icon="mingcute:add-fill" /></Link>
                              </>
                              :
                              <>
                                <Link to="/" onClick={OnOpenLoginPopup}><Icon icon="mingcute:add-fill" /></Link>
                              </>
                            }
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>}
                {subcategoryData?.CoverImageUrl?.length <= 3 && <Col lg={6} className="CategoriesBigBox-p-0 skeleton">
                  <div className="CategoriesBigBox-sub-img-add">
                    {localStorage.getItem("oauthToken") ?
                        <Link to="/createaicompanion"><Icon icon="mingcute:add-fill" /></Link> : <Link to="/" onClick={OnOpenLoginPopup}><Icon icon="mingcute:add-fill" /></Link>
                    }
                  </div>
                </Col>}
              </Row>
            ) : (
              <Row>

                {subcategoryData?.CoverImageUrl?.[0] && <Col lg={6}>
                  <div className="CategoriesBigBox-img">
                    <Link to={`/search-category?subcat=${subcategoryData?.ParentCategoryId}`} state={subcategoryData?.Name}>
                      <Image src={subcategoryData?.CoverImageUrl[0]} />
                    </Link>
                  </div>
                </Col>}
                {subcategoryData?.CoverImageUrl?.[1] && <Col lg={6}>
                  <div className="CategoriesBigBox-img">
                    <Link to={`/search-category?subcat=${subcategoryData?.ParentCategoryId}`} state={subcategoryData?.Name}>
                      <Image src={subcategoryData?.CoverImageUrl[1]} />
                    </Link>
                  </div>
                </Col>}
                {subcategoryData?.CoverImageUrl?.[2] && <Col lg={6} className="CategoriesBigBox-p-0">
                  <div className="CategoriesBigBox-img">
                    <Link to={`/search-category?subcat=${subcategoryData?.ParentCategoryId}`} state={subcategoryData?.Name}>
                      <Image src={subcategoryData?.CoverImageUrl[2]} />
                    </Link>
                  </div>
                </Col>}
                {subcategoryData?.CoverImageUrl?.length > 3 && <Col lg={6} className="CategoriesBigBox-p-0">
                  <div className="CategoriesBigBox-sub-img-main">
                    <Row >
                      <Col lg={6}>
                        <div className="CategoriesBigBox-sub-Wrap">
                          <div className="CategoriesBigBox-sub-img">
                            <Link to={`/search-category?subcat=${subcategoryData?.ParentCategoryId}`} state={subcategoryData?.Name}>
                              <Image src={subcategoryData?.CoverImageUrl[3]} />
                            </Link>
                          </div>
                          <div className="CategoriesBigBox-sub-img">
                            <Link to={`/search-category?subcat=${subcategoryData?.ParentCategoryId}`} state={subcategoryData?.Name}>
                              <Image src={subcategoryData?.CoverImageUrl[4]} />
                            </Link>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="CategoriesBigBox-sub-Wrap">
                          {subcategoryData?.CoverImageUrl?.[5] && <div className="CategoriesBigBox-sub-img">
                            <Link to={`/search-category?subcat=${subcategoryData?.ParentCategoryId}`} state={subcategoryData?.Name}><Image src={subcategoryData?.CoverImageUrl[5]} /></Link>
                          </div>}
                          <div className="CategoriesBigBox-sub-img-add">
                            {localStorage.getItem("oauthToken") ?
                              <Link to="/createaicompanion"><Icon icon="mingcute:add-fill" /></Link> : <Link to="/" onClick={OnOpenLoginPopup}><Icon icon="mingcute:add-fill" /></Link>
                            }
                            <small>({subcategoryData?.Qty})</small>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>}
                {subcategoryData?.CoverImageUrl?.length <= 3 && <Col lg={6} className="CategoriesBigBox-p-0">
                  <div className="CategoriesBigBox-sub-img-add">
                    {localStorage.getItem("oauthToken") ?
                      <Link to="/createaicompanion"><Icon icon="mingcute:add-fill" /></Link> : <Link to="/" onClick={OnOpenLoginPopup}><Icon icon="mingcute:add-fill" /></Link>
                    }
                  </div>
                </Col>}
              </Row>
            )}
          </div>
          <div className="CategoriesBigBox-Info">
            <Link to={`/search-category?subcat=${subcategoryData?.ParentCategoryId}`} state={subcategoryData?.Name}>
              <h6>{subcategoryData?.Name}
              </h6>
            </Link>
          </div>
        </div>
      </Col>

    </>
  );
}

export default CategoriesBigBox;