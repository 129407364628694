
import React, { useEffect, useRef, useState } from 'react';
import useThrottle from "./hooks/useThrottle";
import { AppState } from './hooks/types';

interface Props {
  code: string;
  device: "mobile" | "desktop";
  appState: AppState; // Add this line
}

function Preview({ code, device, appState }: Props) {
  const throttledCode = useThrottle(code, 200);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [docType, setDocType] = useState('');

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentDocument) {
      if (!docType) {
        const docTypeMatch = throttledCode.match(/<!DOCTYPE[^>]*>/i);
        if (docTypeMatch) {
          setDocType(docTypeMatch[0]);
        }
      }

      const content = docType + (throttledCode.replace(docType, '') || '<html><body></body></html>');
      
      iframe.contentDocument.open();
      iframe.contentDocument.write(content);
      iframe.contentDocument.close();
    }
  }, [throttledCode, docType]);

  

  return (
    <div className="relative">
  
    <iframe
      id={`preview-${device}`}
      ref={iframeRef}
      title="Preview"
      sandbox="allow-scripts allow-same-origin"
      style={{
        width: device === "desktop" ? '100%' : '375px',
        height: '90vh',
        border: '2px solid rgb(105 200 176)',
        borderRadius: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        transform: 'scale(0.9)',
        transformOrigin: 'top',
        marginTop: '25px',
      }}
    />

{appState === AppState.CODING && (
        <div 
          style={{
            position: 'absolute',
            bottom: '40px',
            right: '40px',
            width: '36px',
            height: '36px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '50%',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <div 
            style={{
              width: '24px',
              height: '24px',
              border: '2px solid rgba(0, 0, 0, 0.1)',
              borderTopColor: '#007AFF',
              borderRadius: '50%',
              animation: 'spin 1s linear infinite',
            }}
          />
          <style>{`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}</style>
        </div>
      )}




</div>
  );
}

export default Preview;

