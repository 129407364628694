

import { Col, Image } from "react-bootstrap";
import "./SearchChatbot.css";
import ChatbotsList from "../ChatbotsPage/ChatbotsList";
import { ChatbotData, GetRandomChatbots, GetSearchedData } from "../../Utils/ChatbotUtils";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CollectionsCTABoxs from "../../Components/CollectionsCTABoxs/CollectionsCTABoxs";
import AllTimeFavBannerHome from "../../Components/AllTimeFavBanner/AllTimeFavBanner";
import CompanionsSlider from "../../Components/CompanionsSlider/CompanionsSlider";
import CompanionsBox from "../../Components/CompanionsBox/CompanionsBox";
import { useParams } from 'react-router-dom';

function SearchChatbot() {

  const { query } = useParams();

  const itemsPerPage = 30;
  const itemsPerPageMobile = 10;
  const divRef = useRef(null);
  const animationCloningRef = useRef<HTMLDivElement | null>(null);
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);

  const [chatbots, setChatbots] = useState<ChatbotData[]>([]);
  const [records, setRecords] = useState(itemsPerPage);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    // window.onload = () => {
    setChatbots([]);
    fetchChatbots(query);
    // };
  }, [query]);



  const loadMore = () => {
    // const nextPage = page + 1;
    // setPage(nextPage);
    // fetchChatbots(nextPage);
  };


  useEffect(() => {

    const handleScroll = () => {
      if (animationCloningRef.current) {
        const rect = animationCloningRef.current.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        setIsAnimationPlaying(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  const scrollTop = () => {
    if (divRef.current) {
      divRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    scrollTop();
  }, []);

  // useEffect(() => {
  //   // window.onload = () => {
  //     fetchChatbots(query);
  //   // };
  // }, []);

  function fetchChatbots(query: string) {

    setChatbots([]);
    setRecords(0);
    setHasMore(false);

    GetSearchedData(query).then((newChatbots) => {
      // chatbotsList = [];
      // const uniqueNewChatbots = newChatbots.filter(chatbot => !chatbots.includes(chatbot));
      // const chatbotsList = chatbots.concat(uniqueNewChatbots);
      setChatbots(newChatbots);
      setRecords(60);
      // setHasMore(uniqueNewChatbots.length === itemsPerPage);
    });
  }

  return (
    <>
      <div className="MainContent d-margin padding-16">
        <span className="top-left-bg"> <Image src="/assets/images/top-left-bg.svg" /> </span>
        <span className="top-right-bg"> <Image src="/assets/images/top-right-bg.svg" /> </span>
        <span className="bottom-left-bg"> <Image src="/assets/images/bottom-left-bg.svg" /> </span>
        <span className="bottom-right-bg"> <Image src="/assets/images/bottom-right-bg.svg" /> </span>
        <div className="ChatMainTitleWrap">
          <div className="titleText mb-0">
            <h2>Results for: {query}</h2>
          </div>
        </div>
        <InfiniteScroll
          dataLength={records}
          className="row"
          next={() => loadMore()}
          hasMore={hasMore}
          loader={
            <div className="AiImageLoader">
              {/* <div>
              <div className="nb-spinner"></div>
            </div> */}
            </div>
          }
        >
          {chatbots.length > 0 ? (
            chatbots.map((chatbot, index) => (
              <>
                {index !== 0 && index % 30 === 0 && <CollectionsCTABoxs />}
                {index !== 0 && index % 30 === 0 && <CompanionsSlider />}
                {index !== 0 && index % 30 === 0 && <div className="py-30 mb-5">
                  <AllTimeFavBannerHome />
                </div>}
                <Col lg={2} key={index}>
                  <CompanionsBox chatbotData={chatbot} />
                </Col>
              </>
            ))
          ) : (
            <div className="AiImageLoader">
              <div>
                <div className="nb-spinner"></div>
              </div>
            </div>
          )}
        </InfiniteScroll>
      </div>
    </>

  );
}

export default SearchChatbot;
