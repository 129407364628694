import UserChatbot from "../Models/UserChatbot";
import axios from "axios";

export async function CreateChatbotErrorReport(chatbotData: UserChatbot, error: Error) {
  // Send error to logging service
  const errorData = {
    error: error.message,
    chatbotData: chatbotData
  };

  await sendGlobalMessage(errorData);
}

interface Message {
  channel: string;
  content: {
    className: string;
    text: string;
  };
}


async function sendSpaceMessage(messageToSend: string) {
  const message: Message = {
    channel: "channel:id:" + process.env.VITE_SPACE_CHANNEL_ID,
    content: {
      className: "ChatMessage.Text",
      text: messageToSend,
    },
  };

  try {
    const response = await axios.post(process.env.VITE_SPACE_CHANNEL_URL || "", message, {
      headers: {
        Authorization: `Bearer ${process.env.VITE_SPACE_API_TOKEN}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      console.log("Message sent successfully!");
    } else {
      console.log("Failed to send message. Status code:", response.status);
    }
  } catch (err) {
    console.error("Error sending request:", err);
  }
}

export async function sendGlobalMessage(errorData: { error: string, chatbotData: UserChatbot }) {
  const message = `Error: ${errorData.error}, ChatbotData: ${JSON.stringify(errorData.chatbotData)}`;
  await sendSpaceMessage(message);
}