import { Icon } from "@iconify/react";
import CompanionsBox from "../../Components/CompanionsBox/CompanionsBox";
// Import Swiper React components
import Slider from "react-slick";
import { useRef } from "react";

function CompanionsBoxHomeLIst() {
  const settings = {


    infinite: true,
    centerPadding: "60px",
    slidesToShow: 4,

    speed: 500,
    // slidesPerRow: 1
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.04,
          slidesToScroll: 1
        }
      }
    ]
  };
  const sliderRef = useRef<Slider | null>(null);
  const goToPreviousSlide = () => {
    sliderRef?.current?.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef?.current?.slickNext();
  };

  return (
    <>
      <div className="maincategory deskcompnaionboxdesktop">
        <Slider ref={sliderRef} {...settings} className="">
          <div>
            <CompanionsBox />
          </div>
          <div>
            <CompanionsBox />
          </div>
          <div>
            <CompanionsBox />
          </div>
          <div>
            <CompanionsBox />
          </div>
          <div>
            <CompanionsBox />
          </div>
          <div>
            <CompanionsBox />
          </div>
          <div>
            <CompanionsBox />
          </div>
          <div>
            <CompanionsBox />
          </div>
          <div>
            <CompanionsBox />
          </div>
          <div>
            <CompanionsBox />
          </div>
          <div>
            <CompanionsBox />
          </div>
          <div>
            <CompanionsBox />
          </div>
          <div>
            <CompanionsBox />
          </div>
          <div>
            <CompanionsBox />
          </div>
          <div>
            <CompanionsBox />
          </div>
          <div>
            <CompanionsBox />
          </div>
        </Slider>
        <div className="nextpreviousbtn">
          <div className="SliderPrevbtn" onClick={goToPreviousSlide}>
            <Icon icon="ep:arrow-left-bold" />
          </div>
          <div className="Slidernextbtn" onClick={goToNextSlide}>
            <Icon icon="ep:arrow-right-bold" />
          </div>
        </div>
      </div>

    </>
  );
}

export default CompanionsBoxHomeLIst;
