// import {GetFirestoreInstance} from "./Utils";
// import {collection, getDocs, query} from "@firebase/firestore";


export type ImageData = {
  image_url: string;
  numImage: number; // You might want to use Date type if you plan to manipulate this data
}

export async function GetGenerationsListByUser(email: string): Promise<ImageData[]>
{
  // if(!email)
  // {
  //   return [];
  // }
  // const db = GetFirestoreInstance();
  // if (!db)
  // {
  //   return [];
  // }
  // const q = query(collection(db, "Orbox/" + email + "/generations"));
  // const querySnapshot = await getDocs(q);
  // const publicUserGenerationsList : ImageData[] = [];
  // querySnapshot.forEach((doc) => {
  //   publicUserGenerationsList.push(doc.data() as ImageData);
  // });
  return [];
}

const userGenerationsList: ImageData[] = [];
export async function GetUserGenerationsList(email: string): Promise<ImageData[]>
{
  // if(userGenerationsList.length > 0)
  // {
  //   return userGenerationsList;
  // }
  // if(!email)
  // {
  //   return [];
  // }
  // const db = GetFirestoreInstance();
  // if (!db)
  // {
  //   return [];
  // }
  // const q = query(collection(db, "Orbox/" + email + "/generations"));
  // const querySnapshot = await getDocs(q);
  // querySnapshot.forEach((doc) => {
  //   userGenerationsList.push(doc.data() as ImageData);
  // });
  return [];
}