import {createSlice} from "@reduxjs/toolkit";


export const loginPopUpSlice = createSlice({
  name: "toggleLoginPopUp",
  initialState: {
    value: false
  },
  reducers: {
    showPopUpAction: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload;
    }
  }
});

export const { showPopUpAction } = loginPopUpSlice.actions;

export default loginPopUpSlice.reducer;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const loginPopUpSelector = (state: { loginPopUpState: { value: boolean; }; }) => state.loginPopUpState.value;