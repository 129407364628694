import { Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./InviteFriendPopup.css";
import { Icon } from "@iconify/react";
import axios from "axios";
import { t } from "i18next";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  PinterestShareButton,
  PinterestIcon,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  RedditShareButton,
  RedditIcon,
  TumblrShareButton,
  TumblrIcon,
} from "react-share";
import {useSelector} from "react-redux";
import {userDataSelector} from "../../../Redux/Slices/userDataSlice";
import toast from "react-hot-toast";


export function ValidateInvitation(token: string) {
  console.log("Validating invitation...");
  const user = parseInt(localStorage.getItem("invitation"), 10);
  const config = { headers: { Authorization: `Bearer ${token}` } };
  axios.post(import.meta.env.VITE_SERVER_ADDRESS + "validateInvitation", {user: user}, config)
    .then(r => console.log("Registration validated") );
}


function InviteFriendPopup() {

  const userData = useSelector(userDataSelector);
  const link = window.location.href + "&?user=" + userData.UserId;
  const title = t("invite_your_friends_and_network_to_use_this_ai_com");
  const hashtag = "#Orbofi";
  const iconSize = 40;

  return (
    <>
      <div className="login-info">
        <span><Image src="/assets/icons/orbofi-icon-white.svg" /></span>
        <h5>{t("invite_friends_and_get_10_orbox")}</h5>
        <p>{title}</p>
      </div>
      <div className="shareLinkmain">
        <Form.Group className='c-form-control'>
          <div className='input-icon'>
            <Form.Control type="text" value={link} />
            <Link to="javascript:void(0)" onClick={() => {
              navigator.clipboard.writeText(link);
              toast.success('Link copied to clipboard!');
              }}><Icon icon="iconamoon:copy-fill" /></Link>
          </div>
        </Form.Group>
      </div>
      <hr />
      <ul className="socialMedia-Btns">
        <TwitterShareButton title={title} url={link}>
          <TwitterIcon size={iconSize} round />
        </TwitterShareButton>
        <FacebookShareButton quote={title} hashtag={hashtag} url={link}>
          <FacebookIcon size={iconSize} round />
        </FacebookShareButton>
        <EmailShareButton subject={title} body={title} url={link}>
          <EmailIcon size={iconSize} round />
        </EmailShareButton>
        <LineShareButton title={title} url={link}>
          <LineIcon size={iconSize} round />
        </LineShareButton>
        <TelegramShareButton title={title} url={link}>
          <TelegramIcon size={iconSize} round />
        </TelegramShareButton>
        <PinterestShareButton title={title} media={link} url={link}>
          <PinterestIcon size={iconSize} round />
        </PinterestShareButton>
        <RedditShareButton title={title} url={link}>
          <RedditIcon size={iconSize} round />
        </RedditShareButton>
        <TumblrShareButton title={title} url={link}>
          <TumblrIcon size={iconSize} round />
        </TumblrShareButton>
      </ul>
    </>
  );
}

export default InviteFriendPopup;
