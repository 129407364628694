
import { Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import UpgradeCreateMoreCTA from "../../Components/UpgradeCreateMoreCTA/UpgradeCreateMoreCTA";
import {useEffect, useState} from "react";
import User from "../../Models/User";
import {useSelector} from "react-redux";
import {userDataSelector} from "../../Redux/Slices/userDataSlice";
import {FetchUserById} from "../../Utils/UserUtils";
import UserCreatedChatbots from "./UserCreatedChatbots";
import UserCreatedGenerations from "./UserCreatedGenerations";
import {t} from "i18next";

function ProfileData({isPublicProfile, userId} : {isPublicProfile : boolean, userId:number}) : JSX.Element
{
  const currentUser = useSelector(userDataSelector);
  const [userData, setUserData] = useState<User>(new User());


  useEffect(() =>
  {
    if (!isPublicProfile)
    {
      setUserData(currentUser);
      return;
    }
    if(isPublicProfile && userId !== 0)
    {
      FetchUserById(userId).then((data) => { setUserData(data); });
    }
  }, [isPublicProfile, userId]);


  return (
    <>
      <div className="ChatbotsListHeader ProfileListheader mt-lg-4 mt-3">
        <div className="sidebarSearch ChatbotsListSearch">
          <Link to="/"><Icon icon="iconamoon:search" /></Link>
          <input type="text" placeholder={t("search")} />
        </div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav variant="pills" className="c-tabs chatbotList-tabs">
            <Nav.Item>
              <Nav.Link eventKey="first">{t("user_chatbots")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third">{t("user_images")}</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="mt-3">
            <Tab.Pane eventKey="first">
              {userData.UserId != 0 ? <UserCreatedChatbots userData={userData} />: ""}
              <UpgradeCreateMoreCTA />
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              {userData.UserId != 0 ? <UserCreatedGenerations userData={userData}/> : ""}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>

  );
}

export default ProfileData;
