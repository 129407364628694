import {Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Icon} from "@iconify/react";
import {useEffect, useState} from "react";
import axios from "axios";
import {t} from "i18next";

export default function NotificationDropdown(): JSX.Element {
  const [notifications, setNotifications] = useState({general_notifications: [], invitations: []});

  useEffect(() => {
    const config = {headers: {Authorization: `Bearer ${localStorage.getItem("oauthToken")}`}};
    axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getNotifications`, config)
      .then((response) => {
        setNotifications(response.data);
      });
  }, []);

  const markAsRead = (notificationId, notificationType) => {
    const config = {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("oauthToken"),
      }
    };

    const notificationData = {
      notification_id: notificationId,
      notification_type: notificationType
    };

    axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}markNotification`, notificationData, config)
        .then(() => {
          setNotifications(prevNotifications => ({
            ...prevNotifications,
            general_notifications: prevNotifications.general_notifications.filter(notification => notification.id !== notificationId)
          }));
        })
        .catch();
  };


    return (
        <Dropdown className="notification-dropdown">
            <Dropdown.Toggle variant="" id="dropdown-basic">
                <Link to="javascript:void(0)" className="notification-header-link"><Icon icon="octicon:bell-24"/></Link>
            </Dropdown.Toggle>

      <Dropdown.Menu className="notification-dropdown-main">
        <div className="dropdown-bg">
          <ul className="notification-dropdown-list">
            {notifications.general_notifications && notifications.general_notifications.length > 0 ? (
              notifications.general_notifications.map((stat, index) => (
                  <li key={index}>
                    <Link to={stat.link === "" ? "javascript:void(0)" : `${stat.link}`}
                          onClick={() => markAsRead(stat.id, "general_notification")}>
                      <span><Icon icon="ic:round-rocket-launch"/></span>
                      <small>{stat.message}</small>
                    </Link>
                  </li>
              ))
            ) : null}
            {notifications.invitations && notifications.invitations.length > 0 ? (
                notifications.invitations.map((invitation, index) => (
                    <li key={index}>
                      <Link to={`/user/${invitation.user_name}`}
                    onClick={() => markAsRead(invitation.id, "invitation")}>
                    <span><Icon icon="ic:round-rocket-launch"/></span>
                    <small>{invitation.user_name} {t("was_registered_on_the_website_thanks_to_you")}</small>
                  </Link>
                </li>
              ))
            ) : null}
            {(!notifications.chat_bot_stats || notifications.chat_bot_stats.length === 0) && (!notifications.invitations || notifications.invitations.length === 0) && (
              <li><Link to="">
                <small>{t("no_notification_to_check")}</small>
              </Link></li>
            )}
          </ul>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );

}