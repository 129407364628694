import WizardForm from "react-wizard-form";
import UserNamePopup from "./UserNamePopup";
import PopupModal from "../PopUpBase";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import InterestsPopup from "./InterestsPopup";
import LanguagePopup from "./LanguagePopup";
import Personalizing from "./Personalizing";
import "./OnboardingPopup.css";
import { useSelector, useDispatch } from "react-redux";
import { closeOnboarding } from "../../../Redux/Slices/onboardingSlice";

function OnboardingPopup() {
    // Connect to the Redux store
    const open = useSelector((state) => state.onboarding.open); // Assuming your onboarding slice is named 'onboarding' in your store
    const dispatch = useDispatch();

    return (
        <PopupModal show={open} handleClose={{}} className="default-popup login-popup" backdrop={undefined}>
            <Link to="javascript:void(0)" className="CloseBtn" onClick={() => dispatch(closeOnboarding())}>
                <Icon icon="solar:close-circle-linear" />
            </Link>
            <WizardForm>
                <UserNamePopup />
                <InterestsPopup />
                <LanguagePopup />
                {/*<CompanionPopup />*/}
                <Personalizing />
            </WizardForm>
        </PopupModal>
    );
}

export default OnboardingPopup;
