// apiFunctions.js
import axios from "axios";

// store data to api
export const addDataToAPI = async (data) => {
    try {
      const response = await axios.post('https://hub.orbofi.com/ipfs-api/create', data);
      if (response.status === 201) {
        console.log('Data added successfully:', response.data);
        return response.data;
      } else {
        console.error('Error adding data:', response.statusText);
        return null;
      }
    } catch (error) {
      console.error('Error adding data:', error);
      return null;
    }
};

// https://layerzero.gitbook.io/docs/technical-reference/mainnet/supported-chain-ids
export const lz_endpoints = {
  "Binance Smart Chain Testnet": {"chainID": 10102, "endpoint": "0x6Fcb97553D41516Cb228ac03FdC8B9a0a9df04A1"},
  "Polygon Mumbai": {"chainID": 10109, "endpoint": "0xf69186dfBa60DdB133E91E9A4B5673624293d8F8"},
  "Polygon": {"chainID": 109, "endpoint": "0x3c2269811836af69497E5F486A85D7316753cf62"},
  "BNB Smart Chain": {"chainID": 102, "endpoint": "0x3c2269811836af69497E5F486A85D7316753cf62"},
  "goerli": {"chainID": 10121, "endpoint": "0xbfD2135BFfbb0B5378b56643c2Df8a87552Bfa23"},
  "sepolia":{"chainID": 10161, "endpoint": "0xae92d5aD7583AD66E49A0c67BAd18F6ba52dDDc1"},
}

export const MIN_GAS_TO_STORE = 150000;

export const FUNCTION_TYPE_SEND = 1

export const BATCH_SIZE_LIMIT = 1;

export const  zeroAddressBytes = new Uint8Array(20); // 20 bytes, all initialized to 0

export function splitIntoRanges(num) {
  // Convert to a number if it's a string, then ensure it's an integer
  num = Math.floor(Number(num));
  
  // Check for NaN and return an error message
  if (isNaN(num)) {
      return 'Invalid input: cannot be converted to a number.';
  }

  if (num < 2) return [[1, num]];
  const mid = Math.ceil(num / 2);
  return [[1, mid], [mid + 1, num]];
}


export   const removeDomain = async (str) => {
    return await str.replace("orbofi.mypinata.cloud/", "");
  }



  export  const addOmniChainData = async (data) => {
    const url = 'https://hub.orbofi.com/ipfs-api/store_address';
    const headers = { 'Content-Type': 'application/json' };
  
    return axios.post(url, data, { headers })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error.response.data);
      });
  }

  export  const addOmniMintData = async (data) => {
    // console.log("storenft", data)
    const url = 'https://hub.orbofi.com/ipfs-api/store_nfts';
    const headers = { 'Content-Type': 'application/json' };
  
    return axios.post(url, data, { headers })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error.response.data);
      });
  }
  
  export async function getOmniData(srcAddress) {
    const url = `https://hub.orbofi.com/ipfs-api/get_address/${srcAddress}`;
  
    return axios.get(url)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error.response.data);
      });
  }

export   function addressToBytes(address) {
    // Remove the '0x' prefix if it exists
    const cleanAddress = address.startsWith('0x') ? address.slice(2) : address;
  
    // Convert the cleaned address to a bytes array
    const addressBytes = new Uint8Array(cleanAddress.match(/[\da-f]{2}/gi).map(function (h) {
      return parseInt(h, 16);
    }));
  
    return addressBytes;
  }

  export function hexStringToBytes(hexString) {
    // Convert the hex string to a bytes array using Uint8Array
    const bytesArray = new Uint8Array(hexString.match(/[\da-f]{2}/gi).map(function (h) {
      return parseInt(h, 16);
    }));
  
    return bytesArray;
  }