import { Col, Image, Row, Modal, Button } from "react-bootstrap";
import { t } from "i18next";
import { Icon } from "@iconify/react";
import { Link, useParams } from "react-router-dom";
import AIOrbABI from "../../Contracts/AITokenization/AIOrb.json";
import OmniAIOrb from "../../Contracts/AITokenization/OmniAIOrb.json";
import MantaAIOrb from "../../Contracts/AITokenization/MantaAIOrb.json";
import useDebouncedReadFromAIContract from "../../Contracts/wagmi/readContract.js";
import { ORBOFI_BASE_IPFS_URL } from "../../Contracts/ipfs.js";
import axios from "axios";
import { ethers } from "ethers";
import {
  useWaitForTransaction,
  useAccount,
  useContractWrite,
  useContractEvent,
  useNetwork,
  useContractRead,
} from "wagmi";
import {
  getOmniData,
  addOmniMintData,
} from "../../Components/TokenizeForm/apiFunctions";
import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import AIVRF from "../../Contracts/AITokenization/AIVRF.json";
import EntropyAbi from "../../Contracts/AITokenization/EntropyAbi.json";
import toast from "react-hot-toast";
import "./AiGenratePage.css";

const sampleQuantity = 1;
const optimism_sepolia_chain_ID = 10;
const entropyAddressSepolia = ethers.utils.getAddress(
  "0xdF21D137Aadc95588205586636710ca2890538d5"
);
const ENTROPY_PROVIDER_ADDRESS = ethers.utils.getAddress(
  "0x52DeaA1c84233F7bb8C8A45baeDE41091c616506"
);

function AiGenratePage(props: any) {
  // eslint-disable-next-line no-unused-vars
  const { collectionName, chainId, contractAddress, lZ } = useParams();
  const [IPFSData, setIPFSData] = useState([]);
  const [omniData, setOmniData] = useState([]);
  const [activeButton, setActiveButton] = useState();
  const [dynamicContractAddress, setDynamicContractAddress] = useState();
  const [dynamicChainId, setDynamicChainId] = useState();
  const [showModal, setShowModal] = useState();
  const [confetti, setConfetti] = useState(false);
  const [tokenID, setTokenID] = useState(0);
  const [isTokenIDUpdated, setIsTokenIDUpdated] = useState(false);
  const [sequenceNumber, setSequenceNumber] = useState();
  const [providerUriString, setProviderUriString] = useState("");
  const [fortunaRevelation, setFortunaRevelation] = useState(null);
  const [userRandomNumber, setUserRandomNumber] = useState(
    ethers.utils.hexlify(ethers.utils.randomBytes(32))
  );
  const [userCommitment, setUserCommitment] = useState(
    ethers.utils.keccak256(userRandomNumber)
  );

  // eslint-disable-next-line no-unused-vars
  const { address, isConnecting, isDisconnected } = useAccount();
  const { chain } = useNetwork();

  const baseHookArgs = [
    {
      address: dynamicContractAddress
        ? dynamicContractAddress
        : contractAddress,
      functionName: "name",
      chainId: dynamicChainId
        ? dynamicChainId
        : chainId
          ? Number(chainId)
          : null,
      isLZ: lZ ? true : false,
    },
    {
      address: dynamicContractAddress
        ? dynamicContractAddress
        : contractAddress,
      functionName: "symbol",
      chainId: dynamicChainId
        ? dynamicChainId
        : chainId
          ? Number(chainId)
          : null,
      isLZ: lZ ? true : false,
    },
    {
      address: dynamicContractAddress
        ? dynamicContractAddress
        : contractAddress,
      functionName: "pricePerUnit",
      chainId: dynamicChainId
        ? dynamicChainId
        : chainId
          ? Number(chainId)
          : null,
      isLZ: lZ ? true : false,
    },
    {
      address: dynamicContractAddress
        ? dynamicContractAddress
        : contractAddress,
      functionName: lZ ? "maxMintId" : "maxSupply",
      chainId: dynamicChainId
        ? dynamicChainId
        : chainId
          ? Number(chainId)
          : null,
      isLZ: lZ ? true : false,
    },
    {
      address: dynamicContractAddress
        ? dynamicContractAddress
        : contractAddress,
      functionName: lZ ? "nextMintId" : "totalSupply",
      chainId: dynamicChainId
        ? dynamicChainId
        : chainId
          ? Number(chainId)
          : null,
      isLZ: lZ ? true : false,
    },
  ];

  // New state variables for hook arguments
  const [hookArgs, setHookArgs] = useState(
    !lZ
      ? [
        {
          address: dynamicContractAddress
            ? dynamicContractAddress
            : contractAddress,
          functionName: "tokenURI",
          args: Number(chainId) == 10 ? [1] : [0],
          chainId: dynamicChainId ? dynamicChainId : Number(chainId),
        },
        ...baseHookArgs,
      ]
      : [...baseHookArgs]
  );

  // Use tokenURI data only if lZ is false
  let tokenURIWagmi, contractURIError, contractURIIsLoading;
  if (!lZ) {
    ({
      data: tokenURIWagmi,
      error: contractURIError,
      isLoading: contractURIIsLoading,
    } = useDebouncedReadFromAIContract(hookArgs[0]));
  }

  const {
    data: contractNameWagmi,
    error1,
    isLoading1,
  } = useDebouncedReadFromAIContract(hookArgs[lZ ? 0 : 1]);
  const {
    data: contractSymbolWagmi,
    error2,
    isLoading2,
  } = useDebouncedReadFromAIContract(hookArgs[lZ ? 1 : 2]);
  const { data: contractPriceWagmi } = useDebouncedReadFromAIContract(
    hookArgs[lZ ? 2 : 3]
  );
  const { data: maxSupplyWagmi } = useDebouncedReadFromAIContract(
    hookArgs[lZ ? 3 : 4]
  );
  const { data: totalSupplyWagmi } = useDebouncedReadFromAIContract(
    hookArgs[lZ ? 4 : 5]
  );

  const debouncedFetchData = debounce(() => {
    setHookArgs(
      !lZ
        ? [
          {
            address: dynamicContractAddress
              ? dynamicContractAddress
              : contractAddress,
            functionName: "tokenURI",
            args: Number(chainId) == 10 ? [1] : [0],
            chainId: Number(chainId),
          },
          ...baseHookArgs,
        ]
        : [...baseHookArgs]
    );
  }, 500); // 500ms delay between

  useEffect(() => {
    debouncedFetchData();
  }, [
    dynamicContractAddress ? dynamicContractAddress : contractAddress,
    dynamicChainId ? dynamicChainId : chainId,
    debouncedFetchData,
  ]);

  const eventSignature = "Transfer(address, address, uint256)";
  const eventSignatureHash = ethers.utils.id(eventSignature);
  const {
    data: normalMint,
    error,
    isError,
    write,
  } = useContractWrite({
    mode: "recklesslyUnprepared",
    address: dynamicContractAddress ? dynamicContractAddress : contractAddress,
    abi: lZ
      ? OmniAIOrb.abi
      : chainId == 169
        ? MantaAIOrb.abi
        : chainId == optimism_sepolia_chain_ID
          ? AIVRF
          : AIOrbABI.abi,
    functionName: "mint",
    args: [address, sampleQuantity], // the UI widget that allows user to input the quantity
    overrides: {
      value: contractPriceWagmi?.mul(sampleQuantity),
    },
  });

  const {
    data: omniDATATx,
    error: omniError,
    write: omniMint,
  } = useContractWrite({
    mode: "recklesslyUnprepared",
    address: dynamicContractAddress ? dynamicContractAddress : contractAddress,
    abi: lZ ? OmniAIOrb.abi : chainId == 169 ? MantaAIOrb.abi : AIOrbABI.abi,
    functionName: "mint",
    args: [address], // the UI widget that allows user to input the quantity
    overrides: {
      value: contractPriceWagmi,
    },
  });

  const { isLoadingSrc, isSuccessSrc } = useWaitForTransaction({
    hash: omniDATATx?.hash,

    async onSuccess(omniDATATx) {
      const user_data = {
        address: address,
        srcContractAddress: omniData.srcContractAddress,
        destContractAddress: omniData.destContractAddress,
        contract_address: dynamicContractAddress
          ? dynamicContractAddress
          : contractAddress,
        tokenID: tokenID,
        tokenURI: `${ORBOFI_BASE_IPFS_URL}${omniData.tokenURI}${tokenID}`,
        chainID: chainId,
        islZ: true,
      };

      addOmniMintData(user_data).then((r) => { });
    },
  });

  let value;
  useContractEvent({
    address: dynamicContractAddress ? dynamicContractAddress : contractAddress,
    abi: lZ ? OmniAIOrb.abi : chainId == 169 ? MantaAIOrb.abi : AIOrbABI.abi,
    eventName: "Transfer",
    listener(node, label, owner) {
      value = parseInt(owner._hex, 16);
      setTokenID(value);
      setIsTokenIDUpdated(true);
    },
  });


  const MintNFT = async () => {
    if (chainId == optimism_sepolia_chain_ID) {
      console.log("VRF about to start");
      // mintWithEntropy();
      requestMintWithVRF?.();
    } else {
      write?.();
    }

    if (isError) {
      alert(error.data.message);
    }
  };

  // ********************************* VRF mint  // *********************************

  const entropyContract = {
    address: entropyAddressSepolia,
    abi: EntropyAbi,
  };

  let entropyArgs = {
    address: entropyAddressSepolia,
    functionName: "getFee",
    chainId: dynamicChainId ? dynamicChainId : chainId ? Number(chainId) : null,
    isLZ: lZ ? true : false,
    isVRF: true,
    args: [ENTROPY_PROVIDER_ADDRESS],
  }; // check if the isVRF might lead to errors

  let entropyArgsProviderInfo = {
    address: entropyAddressSepolia,
    functionName: "getProviderInfo",
    chainId: dynamicChainId ? dynamicChainId : chainId ? Number(chainId) : null,
    isLZ: lZ ? true : false,
    isVRF: true,
    args: [ENTROPY_PROVIDER_ADDRESS],
  }; // check if the isVRF might lead to errors

  const { data: entropyFee, error: entropyError } =
    useDebouncedReadFromAIContract(entropyArgs);

  const { data: providerURI, error: providerURIError } =
    useDebouncedReadFromAIContract(entropyArgsProviderInfo);

  useEffect(() => {
    if (providerURI?.uri) {
      console.log("fetching provider commitment", providerURI.uri);
      const uriString = ethers.utils.toUtf8String(providerURI.uri);
      setProviderUriString(uriString);
      const fetchProviderCommitment = async () => {
        try {
          console.log(
            "providerURIError sequenceNumber ....",
            providerUriString,
            sequenceNumber
          );
          const res = await axios.get(
            `${providerUriString}/revelations/${sequenceNumber}`
          );
          console.log("the URL for relevation ✅", res.data.value.data);
          console.log(`${providerUriString}/revelations/${sequenceNumber}`);
          setFortunaRevelation(res.data.value.data);
        } catch (error) {
          console.error("Error fetching provider commitment:", error);
        }
      };
      if (providerUriString && sequenceNumber) {
        fetchProviderCommitment();
      }
    }
  }, [providerURI?.uri, sequenceNumber]);

  let entropyFeeNumber = parseInt(entropyFee?.toString());
  let mint_price = parseInt(contractPriceWagmi?.toString());

  let entropy_and_mint_fee = entropyFeeNumber + mint_price;
  // console.log("entropy_and_mint_fee", entropy_and_mint_fee);
  let entropy_and_mint_fee_BigNumber;

  if (entropy_and_mint_fee != null && !isNaN(entropy_and_mint_fee)) {
    entropy_and_mint_fee_BigNumber = ethers.BigNumber.from(
      entropy_and_mint_fee.toString()
    );
  } else {
    // Set to a default value (e.g., 0) if entropy_and_mint_fee is not a valid number
    // console.log("entropy_and_mint_fee_BigNumber not found yet");
    null

  }

  const {
    data: entropyRequestMint,
    error: requestMintVRFError,
    isError: requestMintVRFIsError,
    write: requestMintWithVRF,
  } = useContractWrite({
    mode: "recklesslyUnprepared",
    address: contractAddress,
    abi: AIVRF.abi,
    functionName: "requestMint",
    args: [userCommitment], // the UI widget that allows user to input the quantity
    overrides: {
      value: entropy_and_mint_fee_BigNumber,
    },
  });

  // waiting for tx
  const { isLoading: isLoadingRequestMint, isSuccess: isSuccessRequestMint } =
    useWaitForTransaction({
      hash: entropyRequestMint?.hash,
      confirmations: 5,
      onSuccess(entropyRequestMint) {
        const response = entropyRequestMint ? entropyRequestMint.logs[0] : [];
        console.log("Response from entropyRequest 🥱", response);
      },
    });

  // let value;
  useContractEvent({
    address: dynamicContractAddress ? dynamicContractAddress : contractAddress,
    abi: AIVRF.abi,
    eventName: "MintRequested",
    async listener(requester, sequenceNumber) {
      setSequenceNumber(sequenceNumber);

      console.log("fetching fortunaRevelation ....");
      const res = await axios.get(
        `${providerUriString}/revelations/${sequenceNumber}`
      );

      let _fortunaRevelation = res.data;

      const args = [
        sequenceNumber,
        userRandomNumber,
        "0x" + res.data.value.data,
      ];

      try {
        console.log("calling the fulfillMintWithVRF 😈 ");
        const fulfillMintResult = await fulfillMintWithVRF({
          recklesslySetUnpreparedArgs: args,
        });
        console.log("Fulfill mint result:", fulfillMintResult);
      } catch (error) {
        // console.error("Error calling fulfillMintWithVRF:", error);
        toast.error(`Error calling fulfillMintWithVRF:", ${error}`);
      }
    },
  });

  const {
    data: entropyFullfilltMint,
    error: fulfillMintVRFError,
    isError: fulfillMintVRFIsError,
    write: fulfillMintWithVRF,
  } = useContractWrite({
    address: dynamicContractAddress ? dynamicContractAddress : contractAddress,
    abi: AIVRF.abi,
    functionName: "fulfillMint",
  });

  // waiting for tx
  const { isLoading: isLoadingFullfillMint, isSuccess: isSuccessFullfillMint } =
    useWaitForTransaction({
      hash: entropyFullfilltMint?.hash,
      onSuccess(entropyFullfilltMint) {
        setShowModal(true);
        setConfetti(true);

        console.log("success from entropy Fullfill Mint");
        const response = entropyFullfilltMint
          ? entropyFullfilltMint.logs[0]
          : [];
        console.log("Response from entropyFullfill 🥱", response);
        // console.log("sequence number from success stroy 🦀", sequenceNumber)
        // console.log("fulldata", entropyRequestMint)
      },

      onError() {
        console.log("INSIDE fulfillMintVRFError", fulfillMintVRFError);
      },
    });

  async function handleSuccessRequestMint() {
    console.log("isSuccessRequestMint after tx", sequenceNumber);
    console.log("randomNumber ndani", userRandomNumber);
    console.log("userCommitment ndani", userCommitment);
    const res = await axios.get(
      `${providerUriString}/revelations/${sequenceNumber}`
    );
    const args = [sequenceNumber, userRandomNumber, "0x" + res.data.value.data];
    console.log("Arguments for fulfillMint:", args);

    try {
      const fulfillMintResult = await fulfillMintWithVRF({
        recklesslySetUnpreparedArgs: [
          91n,
          "0x96a37594ec397e9091c056ae48c8b04a272e8fef14201145d19422bf3958af4b",
          "0x331c6ab3c88aadaefa1ff44bc23f6c8ce7255f707105570435cc0edc85181bfb",
        ],
      });
      console.log("Fulfill mint result:", fulfillMintResult);
    } catch (error) {
      console.error("Error during fulfillMint:", error);
    }

    if (isError) {
      console.error("Fulfill mint error:", fulfillMintVRFError);
    }
  }

  function removeLastZero(str) {
    if (str.endsWith("0") || str.endsWith("1")) {
      return str.slice(0, -1); // removes the last character from the string
    } else {
      return str;
    }
  }

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: normalMint?.hash,
    onSuccess(normalMint) {
      setShowModal(true);
      setConfetti(true);
    },
  });

  useEffect(() => {
    if (isSuccess && isTokenIDUpdated && tokenID !== 0 && !lZ) {
      const user_data = {
        address: address,
        contract_address: dynamicContractAddress
          ? dynamicContractAddress
          : contractAddress,
        tokenID: tokenID,
        tokenURI: `${ORBOFI_BASE_IPFS_URL}/${removeLastZero(
          tokenURIWagmi
        )}${tokenID}`,
        collection_name: contractNameWagmi,
        chainID: chainId,
        islZ: false,
      };

      addOmniMintData(user_data).then((r) => { });
    }
  }, [
    address,
    chainId,
    dynamicContractAddress ? dynamicContractAddress : contractAddress,
    contractNameWagmi,
    isSuccess,
    isTokenIDUpdated,
    tokenID,
  ]);

  const handleCloseModal = () => {
    setShowModal(false);
    setConfetti(false);
  };

  useEffect(() => {
    async function fetchDataFromIPFS() {
      if (tokenURIWagmi || lZ) {
        // if lZ exists this means that the contract were made from the layer zero
        // architecture and the tokenURI is the baseURI
        let baseURI;
        // this will keep track of the total supply of the omni contracts
        // as they are main token supply is split into multiple contracts (src and dest)
        let totalSupplyForOmniContracts;
        if (lZ) {
          const data = await getOmniData(
            dynamicContractAddress ? dynamicContractAddress : contractAddress
          );

          // The total supply is split between src and dest.
          // To get the total supply, pick the second element from the dest list.
          totalSupplyForOmniContracts = data.destTotalSupply[1];
          setOmniData(data);
          baseURI = `ipfs${data.tokenURI}`;
        } else {
          baseURI = removeLastZero(tokenURIWagmi);
        }

        const max_supply = totalSupplyForOmniContracts
          ? totalSupplyForOmniContracts
          : maxSupplyWagmi?.toNumber();

        const imagePromises = [];

        for (let i = 0; i <= max_supply - 1; i++) {
          const token_uri = `${ORBOFI_BASE_IPFS_URL}/${baseURI}${i}`;

          imagePromises.push(axios.get(token_uri));
        }

        try {
          const responseArray = await Promise.all(imagePromises);
          const images = responseArray.map((response) => response.data);

          setIPFSData(images);
        } catch (error) {
          console.error("Error fetching IPFS data:", error);
        }
      }
    }
    fetchDataFromIPFS().then((r) => { });
  }, [
    tokenURIWagmi || lZ,
    contractNameWagmi,
    IPFSData,
    maxSupplyWagmi,
    omniDATATx,
  ]);

  // console.log("IPFSData", IPFSData)
  const handlePolygonClick = () => {
    setDynamicContractAddress(contractAddress);
    setDynamicChainId(137);
    setActiveButton("polygon");
  };

  const handleBNBClick = () => {
    setDynamicContractAddress(omniData.destContractAddress);
    setDynamicChainId(56);
    setActiveButton("bnb");
  };

  return (
    <div className="MainContent padding-16 d-margin">
      <div className="aigenratemainpage">
        <div className="aigenratepage">
          <Row className="align-items-start">
            <Col lg={6}>
              <div className="aiimg">
                <span>
                  {" "}
                  <Image
                    src={
                      totalSupplyWagmi?.toNumber() ===
                        maxSupplyWagmi?.toNumber()
                        ? IPFSData[totalSupplyWagmi?.toNumber() - 1]?.image
                        : IPFSData[totalSupplyWagmi?.toNumber()]?.image
                    }
                  />
                </span>
              </div>
            </Col>
            <Col lg={6}>
              <div className="aidetail">
                <div className="aititle">
                  <h2>
                    {contractNameWagmi} ({contractSymbolWagmi})
                  </h2>
                </div>
                <div className="setting-sidebar-main">
                  <h6>
                    <small>
                      Total Supply:
                      <span>
                        {totalSupplyWagmi?.toNumber()} /
                        {maxSupplyWagmi?.toNumber()}
                      </span>
                    </small>{" "}
                  </h6>
                  <h6>
                    <small>
                      Royalty
                      <span>{IPFSData[0]?.royalty} %</span>
                    </small>{" "}
                  </h6>
                  <h6>
                    <small>
                      Tokenized on the blockchain
                      <span>
                        <div className="TokenizeRadioWrap">
                          <label className="radio-button-container">
                            <input type="radio" name="yesno" checked={true} />
                            <span className="checkmark"></span>
                            Yes
                          </label>
                          <label className="radio-button-container">
                            <input type="radio" name="yesno" />
                            <span className="checkmark"></span>
                            No
                          </label>
                          {/* <p><input type="radio" name="yesno" id="yes" /><label htmlFor="yes">Yes</label></p>
                      <p ><input type="radio" name="yesno" id="no" /><label htmlFor="no">No</label></p> */}
                        </div>
                      </span>
                    </small>
                  </h6>
                  <h6>
                    <small>
                      Chain{" "}
                      <span>
                        {dynamicChainId ? (
                          dynamicChainId === 56 ? (
                            <i>
                              <Image src="/images/icons/BNBChain.svg" />
                            </i>
                          ) : dynamicChainId === 137 ? (
                            <i>
                              <Image src="/images/icons/Polygon.svg" />
                            </i>
                          ) : null
                        ) : IPFSData[0]?.chainId === 56 ? (
                          <i>
                            <Image src="/images/icons/BNBChain.svg" />
                          </i>
                        ) : IPFSData[0]?.chainId === 137 ? (
                          <i>
                            <Image src="/images/icons/Polygon.svg" />
                          </i>
                        ) : IPFSData[0]?.chainId === 169 ? (
                          <i>
                            <Image src="/images/icons/manta.svg" />
                          </i>
                        ) : IPFSData[0]?.chainId === 1351057110 ? (
                          <h6> Skale </h6>
                        ) : IPFSData[0]?.chainId === 8453 ? (
                          <i>
                            <Image src="/images/icons/base-logo-in-blue.svg" />
                          </i>
                        ) : IPFSData[0]?.chainId === 10 ? (
                          <i>
                            <Image src="/images/icons/optimism.svg" />
                          </i>
                        ) : IPFSData[0]?.chainId === 421614 ? (
                          <i>
                            <Image src="/images/icons/arbitrum.svg" />
                          </i>
                        ) : IPFSData[0]?.chainId === 2525 ? (
                          // <i><Image src="/images/icons/injective.svg" /></i>
                          <h6>Injective</h6>
                        ) : IPFSData[0]?.chainId === 1116 ? (
                          <h6>CoreDao</h6>
                        ) : IPFSData[0]?.chainId === 1328 ? (
                          <h6>SEI</h6>
                        ):
                        
                        null}
                      </span>
                    </small>
                  </h6>
                  <h6>
                    <small>
                      Contract Address{" "}
                      <span>{`${dynamicContractAddress
                          ? dynamicContractAddress?.substring(0, 6)
                          : contractAddress?.substring(0, 6)
                        }...${dynamicContractAddress
                          ? dynamicContractAddress.substring(
                            dynamicContractAddress.length - 4
                          )
                          : contractAddress?.substring(
                            contractAddress?.length - 4
                          )
                        }`}</span>
                      {dynamicChainId ? (
                        dynamicChainId === 56 ? (
                          <a
                            href={`https://bscscan.com/address/${dynamicContractAddress
                                ? dynamicContractAddress
                                : contractAddress
                              }`}
                            target="_blank"
                            style={{ color: "#f0b90b" }}
                            rel="noreferrer"
                          >
                            <Icon icon="ri:share-box-fill" />
                          </a>
                        ) : dynamicChainId === 137 ? (
                          <a
                            href={`https://polygonscan.com/address/${dynamicContractAddress
                                ? dynamicContractAddress
                                : contractAddress
                              }`}
                            target="_blank"
                            style={{ color: "#8247e5" }}
                            rel="noreferrer"
                          >
                            <Icon icon="ri:share-box-fill" />
                          </a>
                        ) : null
                      ) : IPFSData[0]?.chainId === 56 ? (
                        <a
                          href={`https://bscscan.com/address/${dynamicContractAddress
                              ? dynamicContractAddress
                              : contractAddress
                            }`}
                          target="_blank"
                          style={{ color: "#f0b90b" }}
                          rel="noreferrer"
                        >
                          <Icon icon="ri:share-box-fill" />
                        </a>
                      ) : IPFSData[0]?.chainId === 137 ? (
                        <a
                          href={`https://polygonscan.com/address/${dynamicContractAddress
                              ? dynamicContractAddress
                              : contractAddress
                            }`}
                          target="_blank"
                          style={{ color: "#8247e5" }}
                          rel="noreferrer"
                        >
                          <Icon icon="ri:share-box-fill" />
                        </a>
                      ) : IPFSData[0]?.chainId === 169 ? (
                        <a
                          href={`https://pacific-explorer.manta.network/address/${dynamicContractAddress
                              ? dynamicContractAddress
                              : contractAddress
                            }`}
                          target="_blank"
                          style={{ color: "#8247e5" }}
                          rel="noreferrer"
                        >
                          <Icon icon="ri:share-box-fill" />
                        </a>
                      ) : IPFSData[0]?.chainId === 1351057110 ? (
                        <a
                          href={`https://staging-fast-active-bellatrix.explorer.staging-v3.skalenodes.com/address/${dynamicContractAddress
                              ? dynamicContractAddress
                              : contractAddress
                            }`}
                          target="_blank"
                          style={{ color: "#8247e5" }}
                          rel="noreferrer"
                        >
                          <Icon icon="ri:share-box-fill" />
                        </a>
                      ) : IPFSData[0]?.chainId === 8453 ? (
                        <a
                          href={`https://basescan.org/address/${dynamicContractAddress
                              ? dynamicContractAddress
                              : contractAddress
                            }`}
                          target="_blank"
                          style={{ color: "#8247e5" }}
                          rel="noreferrer"
                        >
                          <Icon icon="ri:share-box-fill" />
                        </a>
                      ) : IPFSData[0]?.chainId === 10 ? (
                        <a
                          href={`https://optimistic.etherscan.io/address/${dynamicContractAddress
                              ? dynamicContractAddress
                              : contractAddress
                            }`}
                          target="_blank"
                          style={{ color: "#8247e5" }}
                          rel="noreferrer"
                        >
                          <Icon icon="ri:share-box-fill" />
                        </a>
                      ) : IPFSData[0]?.chainId === 421614 ? (
                        <a
                          href={`https://sepolia.arbiscan.io/address/${dynamicContractAddress
                              ? dynamicContractAddress
                              : contractAddress
                            }`}
                          target="_blank"
                          style={{ color: "#8247e5" }}
                          rel="noreferrer"
                        >
                          <Icon icon="ri:share-box-fill" />
                        </a>
                      ) : IPFSData[0]?.chainId === 2525 ? (
                        <a
                          href={` https://inevm.calderaexplorer.xyz/address/${dynamicContractAddress
                              ? dynamicContractAddress
                              : contractAddress
                            }`}
                          target="_blank"
                          style={{ color: "#8247e5" }}
                          rel="noreferrer"
                        >
                          <Icon icon="ri:share-box-fill" />
                        </a>
                      ) : IPFSData[0]?.chainId === 1116 ? (
                        <a
                          href={` https://scan.coredao.org/address/${dynamicContractAddress
                              ? dynamicContractAddress
                              : contractAddress
                            }`}
                          target="_blank"
                          style={{ color: "#8247e5" }}
                          rel="noreferrer"
                        >
                          <Icon icon="ri:share-box-fill" />
                        </a>
                      ) :
                      IPFSData[0]?.chainId === 1328 ? (
                        <a
                          href={`https://seitrace.com/address/${dynamicContractAddress
                              ? dynamicContractAddress
                              : contractAddress
                            }`}
                          target="_blank"
                          style={{ color: "#8247e5" }}
                          rel="noreferrer"
                        >
                          <Icon icon="ri:share-box-fill" />
                        </a>
                      ) :
                      
                      
                      
                      null}
                    </small>
                  </h6>
                  <div className="btnImgwrap mt-4">
                    <span>
                      <Image src="/assets/images/btn-left-after.svg" />
                    </span>
                    <Button

                      onClick={() => {
                        lZ ? omniMint() : MintNFT();
                      }}
                      disabled={
                        isDisconnected ||
                        totalSupplyWagmi?.toNumber() === maxSupplyWagmi?.toNumber()
                      }
                    >
                      {isLoading ? "Minting..." : isLoadingFullfillMint ? "fulfillMint..." : isLoadingRequestMint ? "requestMint..." : "Mint"}
                    </Button>
                    <span>
                      <Image src="/assets/images/btn-right-after.svg" />
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {/* contragutalions popup */}

          {confetti && (
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              numberOfPieces={500}
            />
          )}

          <Modal
            show={showModal}
            onHide={handleCloseModal}
            centered
            className="FactoryListPopup default-modal"
          >
            <Modal.Body>
              <h5 className="factoryListTitle">Congratulations!</h5>
              <Link
                to="#"
                className="CloseBtn"
                onClick={() => handleCloseModal(false)}
              >
                <Icon icon="ion:close-circle" color="white" />
              </Link>
              <hr />
              <div className="NFTModal">
                <p>Congrats, you have successfully minted an NFT!</p>
                <Image
                  src={IPFSData[tokenID]?.image}
                  className="w-100 mb-3"
                  style={{ borderRadius: "15px" }}
                />
                {chainId === "169" ? (
                  <a
                    className="btn btn-primary justify-content-center"
                    href={`https://element.market/assets/manta-pacific/${contractAddress}/${tokenID}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Check on ELEMENT
                  </a>
                ) : (
                  <a
                    className="btn btn-primary justify-content-center"
                    href={`https://opensea.io/assets/${dynamicChainId
                        ? dynamicChainId === 137
                          ? "matic"
                          : dynamicChainId === 56
                            ? "bsc"
                            : "unknown"
                        : chainId
                          ? chainId === "137"
                            ? "matic"
                            : chainId === "56"
                              ? "bsc"
                              : "unknown"
                          : "unknown"
                      }/${dynamicContractAddress || contractAddress}/${tokenID}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Check on OPENSEA
                  </a>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <div className="MintImgWrap">
        {IPFSData?.map((item, index) => {
          const adjustedIndex = omniData ? index + 1 : index; // Adjusting the index to start from 1
          return (
            <div className="MintImg" key={index}>
              <Image src={item.image} />
              {/* {lZ === undefined && adjustedIndex <= totalSupplyWagmi?.toNumber() ? <h6>Minted</h6> : null} */}
              {omniData && lZ && (
                <>
                  {omniData?.srcTotalSupply.includes(adjustedIndex) && (
                    <span>
                      <Image src="/images/icons/Polygon.svg" />
                    </span>
                  )}
                  {omniData?.destTotalSupply.includes(adjustedIndex) && (
                    <span>
                      <Image src="/images/icons/BNBChain.svg" />
                    </span>
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AiGenratePage;
