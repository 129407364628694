import {createSlice} from "@reduxjs/toolkit";
import User from "../../Models/User";

const initialStruct: {
    PremiumOrbox: number;
    ProfilePicture: string;
    Role: string;
    Name: string;
    Orbox: number;
    UserId: number;
    Email: string;
    Platform: string
} = {
  UserId: -1,
  Name: "",
  Email: "",
  Role: "",
  Orbox: 0,
  PremiumOrbox : 0,
  ProfilePicture: "",
  Platform: "",
};

export const userDataSlice = createSlice({
  name: "userData",
  initialState: {
    value: initialStruct,
    chatbotLanguage: { value: "english", label: "English", msLangCode: "en-US", icon: "twemoji:flag-us-outlying-islands" },
  },
  reducers: {
    updateUserData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload;
    },
    setChatbotLanguage: (state, action) => {
      state.chatbotLanguage = action.payload;
    }
  }
});

export const { updateUserData, setChatbotLanguage } = userDataSlice.actions;

export default userDataSlice.reducer;

export const userDataSelector = (state: { userData: { value: User; }; })  => state.userData.value;
export const chatbotLangSelector = (state: { userData: { chatbotLanguage: any; }; })  => state.userData.chatbotLanguage;