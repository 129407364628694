import toast from "react-hot-toast";
import { WS_BACKEND_URL, USER_CLOSE_WEB_SOCKET_CODE } from "./config";


const ERROR_MESSAGE = "Error generating code.";

const STOP_MESSAGE = "Code generation stopped";

export interface CodeGenerationParams {
  generationType: "create" | "update";
  image?: string;
  resultImage?: string;
  history?: string[];
  inputType?: string
  textPrompt?: string
  // isImageGenerationEnabled: boolean; // TODO: Merge with Settings type in types.ts
}


export function generateCode(
  wsRef: React.MutableRefObject<WebSocket | null>,
  params: CodeGenerationParams,
  onChange: (chunk: string) => void,
  onSetCode: (code: string) => void,
  onStatusUpdate: (status: string) => void,
  onComplete: () => void
) {

  const wsUrl = `${WS_BACKEND_URL}/generate-code`;

  const ws = new WebSocket(wsUrl);
  wsRef.current = ws;

  ws.addEventListener("open", () => {
    ws.send(JSON.stringify(params));
  });

  ws.addEventListener("message", async (event: MessageEvent) => {
    const response = JSON.parse(event.data);
    if (response.type === "chunk") {
      onChange(response.value);
    } else if (response.type === "status") {
      onStatusUpdate(response.value);
    } else if (response.type === "setCode") {
      onSetCode(response.value);
    } else if (response.type === "error") {
      console.error("Error generating code", response.value);
      toast.error(response.value);
    }
  });
  ws.addEventListener("close", (event) => {
    if (event.code === USER_CLOSE_WEB_SOCKET_CODE) {
      toast.success(STOP_MESSAGE);
    } else if (event.code !== 1000) {
      console.error("WebSocket error code", event);
      toast.error(ERROR_MESSAGE);
    }
    onComplete();
  });

  ws.addEventListener("error", (error) => {
    console.error("WebSocket error", error);
    toast.error(ERROR_MESSAGE);
  });
}

 