import {Col, Image} from 'react-bootstrap';
import ExploreBanner from "../../Components/ExploreCompo/ExploreBanner/ExploreBanner";
import "./ExplorePage.css";
import CompanionsSlider from "../../Components/CompanionsSlider/CompanionsSlider";
import CollectionsCTABoxs from "../../Components/CollectionsCTABoxs/CollectionsCTABoxs";
import CompanionShorts from "../../Components/CompanionShorts/CompanionShorts";
import AllTimeFavBanner from "../../Components/AllTimeFavBanner/AllTimeFavBanner";
import CategoriesBigBox from "../../Components/CategoriesBigBox/CategoriesBigBox";
import CategoriesBox from "../CategoriesBox/CategoriesBox";
import CategoriesFilter from "../../Components/CategoriesFilter/CategoriesFilter";
import Categories from "../../Components/Categories/Categories";
import TinderSwiper from "../HomePage/HomeSlider";
import { t } from "i18next";
import UpgradeCreateMoreExploreCTA from "../../Components/ExploreCompo/ExploreBanner/UpgradeCreateMoreExploreCTA";
import AllTimeFavBannerHome from '../../Components/AllTimeFavBanner/AllTimeFavBanner';
import CompanionsBox from '../../Components/CompanionsBox/CompanionsBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useEffect, useRef, useState} from 'react';
import {ChatbotData, GetRandomChatbots} from '../../Utils/ChatbotUtils';


function ExplorePage() {

  const itemsPerPage = 30;
  const itemsPerPageMobile = 10;
  const [chatbots, setChatbots] = useState<ChatbotData[]>([]);
  const [records, setRecords] = useState(itemsPerPage);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    window.onload = () => {
      fetchChatbots(0);
    };
  }, []);

  function fetchChatbots(page: number) {
    const itemsToFetch = window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPage;
    GetRandomChatbots(page, itemsToFetch).then((newChatbots) => {
      const uniqueNewChatbots = newChatbots.filter(chatbot => !chatbots.includes(chatbot));
      const chatbotsList = chatbots.concat(uniqueNewChatbots);
      setChatbots(chatbotsList);
      setRecords(prevRecords => prevRecords + newChatbots.length);
      setHasMore(newChatbots.length === itemsToFetch);
    });
  }

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchChatbots(nextPage);
  };



  return (
    <div className="MainContent padding-16 d-margin">
      <span className="top-left-bg"> <Image src="/assets/images/top-left-bg.svg" /> </span>
      <span className="top-right-bg"> <Image src="/assets/images/top-right-bg.svg" /> </span>
      <span className="bottom-left-bg"> <Image src="/assets/images/bottom-left-bg.svg" /> </span>
      <span className="bottom-right-bg"> <Image src="/assets/images/bottom-right-bg.svg" /> </span>
      <div className="Breadcrumb-Wrap">
      </div>
      <ExploreBanner />
      {/* <CategoriesFilter /> */}
      <div className="py-30">
        <CompanionsSlider isAtTop={true} />
        <div className="tindermobile mt-3">
          <TinderSwiper />
        </div>
      </div>
      <div className="py-30">
        <CompanionShorts isAtTop={true} HeadTitle={"Created just now"} />
        <div className="tindermobile mt-3">
          <TinderSwiper />
        </div>
      </div>
      <div className="py-30">
        <CollectionsCTABoxs />
      </div>
      <div className="HomeCategories-List py-30">
        <div className="titleText ">
          <h2>{t("more_categories")}</h2>
          <p>Smart AI companions and clones, with voice and personality</p>
        </div>
        <CategoriesBox />
      </div>
      <div className="py-30 CategoriesBigBoxRow">
        <CategoriesBigBox isExplorePage={true} />
      </div>
      <div className="HomeCategories-List py-30">
        <div className="titleText ">
          <h2>{t("more_categories")}</h2>
          <p>Smart AI companions and clones, with voice and personality</p>
          {/* <p>{t("create_your_personalize_ai_companion_that_can_boos")}</p> */}
        </div>
        <Categories />
      </div>
      <div className="py-30">
        {/* <ExploreCompanionSlider /> */}
        <UpgradeCreateMoreExploreCTA />
      </div>
      <div className="py-30">
        <CompanionsSlider isAtTop={true} />
      </div>
      <div className="py-30">
        <CompanionShorts isAtTop={true} HeadTitle={"Created just now"} />
        <div className="tindermobile mt-3">
          <TinderSwiper />
        </div>
      </div>
      <div className="py-30">
        <CompanionsSlider isAtTop={true} />
      </div>

      <div className="py-30">
        <AllTimeFavBanner />
      </div>
      <div className="py-30">
        <CompanionsSlider isAtTop={true} />
      </div>
      <div className="py-30">
        <CompanionShorts isAtTop={true} />
        <div className="tindermobile mt-3">
          <TinderSwiper />
        </div>
      </div>
      <InfiniteScroll
        dataLength={records}
        className="row"
        next={() => loadMore()}
        hasMore={hasMore}
        loader={
          <div className="AiImageLoader">
            <div>
              <div className="nb-spinner"></div>
            </div>
          </div>
        }
      >
        {chatbots.length > 0 ? (
          chatbots.map((chatbot, index) => (
            <>
              {/* {index !== 0 && index % 30 === 0 && <div className="mb-5 py-30"><CompanionShorts HeadSubTitle={""} HeadTitle={"Created just now"} isAtTop={false} /></div>} */}
              {index !== 0 && index % 30 === 0 && <CompanionsSlider isAtTop={true} />}
              {index !== 0 && index % 30 === 0 && <div className="py-30 mb-5">
                <AllTimeFavBannerHome />
              </div>}
              <Col lg={2} key={index}>
                <CompanionsBox chatbotData={chatbot} />
              </Col>
            </>
          ))
        ) : (
          <div className="AiImageLoader">
            {/* <div>
             <div className="nb-spinner"></div>
             </div> */}
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
}

export default ExplorePage;
