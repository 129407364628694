import React, { useEffect, useRef, useState } from 'react';
import { Icon } from "@iconify/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider, { Settings } from "react-slick";
import CompanionsBox from "../CompanionsBox/CompanionsBox";
import "./CompanionsSlider.css";
import { t } from "i18next";
import { ChatbotData, GetChatbotList, GetRandomParentChatbots } from '../../Utils/ChatbotUtils';
import CompanionCard from "../Cards/CompanionCard";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showPopUpAction } from '../../Redux/Slices/loginPopUpSlice';
import LordIcon from '../LordIcon/LordIcon';



interface ComponentProps {
  isAtTop: boolean
}

function CompanionsSlider({ isAtTop }: ComponentProps) {

  const sliderRef = useRef<Slider | null>(null);
  const settings: Settings = {
    autoplaySpeed: 2500,
    arrows: false,
    cssEase: "linear",
    slidesToShow: 6,
    slidesToScroll: 6,
    useTransform: true,
    draggable: false,
    responsive: [
      {
        breakpoint: 2800,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1430,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4.03,
          slidesToScroll: 4.03,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.02,
          slidesToScroll: 2.02
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1.05,
          slidesToScroll: 1.05,
        },
      },
    ],
  };
  const goToPreviousSlide = () => {
    sliderRef?.current?.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef?.current?.slickNext();
  };

  const [chatbotList, setChatbotList] = useState<ChatbotData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getRandomNumber = () => {
    // Define the ranges
    const ranges = [
      [2, 7],
      [12, 16],
      [19, 20],
      [24, 24]
    ];

    // Randomly select a range
    const randomRange = ranges[Math.floor(Math.random() * ranges.length)];

    // Generate a random number within the selected range
    return Math.floor(Math.random() * (randomRange[1] - randomRange[0] + 1)) + randomRange[0];
  };

  useEffect(() => {
    if (!chatbotList || chatbotList.length <= 0) {
      const randomPriority = getRandomNumber();
      GetRandomParentChatbots(randomPriority).then((resp) => {
        setChatbotList(resp.sort(() => Math.random() - 0.5));
        setIsLoading(false);
      }).catch(console.error);
    }
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  function CreateAiCompanion() {
    if (localStorage.getItem("oauthToken")) {
      navigate("/createaicompanion");
    }
    else {
      dispatch(showPopUpAction(true));
    }
  }

  return (
    <div className="FavoriteCompanions relative">
      <div className="CompanionShortsTitleWrap">
        <div className="titleText">
          <h2>{t("try_one_of_our_favorite_companions")}</h2>
        </div>
        <div className="d-flex gap-2 desktopArrows">
          <div className="SliderPrevbtn" onClick={goToPreviousSlide}>
            <Icon icon="ep:arrow-left-bold" />
          </div>
          <div className="Slidernextbtn" onClick={goToNextSlide}>
            <Icon icon="ep:arrow-right-bold" />
          </div>
        </div>
      </div>
      {!isLoading &&
        <Slider ref={sliderRef} {...settings} className="CompanionShortsSlider tinderdesktop">
          {isAtTop ? <div>
            <div onClick={CreateAiCompanion} className="SliderAddCard">
              <LordIcon
                src="https://cdn.lordicon.com/zrkkrrpl.json"
                colors="primary:#53aab0,secondary:#53aab0"
                trigger="loop"
              />
              <h6>Create a voice AI <br /> assistant</h6>


            </div>
          </div> : ""}
          {chatbotList.map((chatbot) => {
            return <CompanionCard key={chatbot.chatbot_id} chatbotData={chatbot} />;
          })
          }
        </Slider>
      }
    </div>
  );
}

export default CompanionsSlider;
