import axios from "axios";
import User from "../Models/User";
import Subscription from "../Models/Subscription";
import Category from "../Models/Category";

export const RUNPOD_BASE_URL = "https://wgpxrl5iy1vka3-6000.proxy.runpod.net"; // test server
// export const RUNPOD_BASE_URL = "https://wgpxrl5iy1vka3-6000.proxy.runpod.net"
// export const RUNPOD_BASE_URL = "https://pkv2tw3w24vd2x-6000.proxy.runpod.net"; // test server

export const RUNPOD_BASE_URL_UPLOAD = "https://98sylryzyqxvtf-4000.proxy.runpod.net";
export const RUNPOD_ROMANIAN_SERVER = "https://avr2tpclxwbwc4-6000.proxy.runpod.net";

export function Logout() {
  localStorage.setItem("oauthToken", "");
  localStorage.removeItem("userData");
}


// Get the user data from the DB
export async function GetUserData(): Promise<void | User> {
  if (!localStorage.getItem("userData")) {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("oauthToken"),
      },
    };
    return await axios
      .get(import.meta.env.VITE_SERVER_ADDRESS + "getUserDetails", config)
      .then((resp) => {
        const userData: User = resp?.data as User;
        localStorage.setItem("userData", JSON.stringify(userData));
        localStorage.setItem("picture", userData.ProfilePicture);
        localStorage.setItem("name", userData.Name);
        return userData;
      })
      .catch((error) => {
        Logout();
        throw error;
      });
  } else {
    const userData = localStorage.getItem("userData");
    if (userData) {
      const retrievedData: User = JSON.parse(userData);
      localStorage.setItem("picture", retrievedData.ProfilePicture);
      localStorage.setItem("name", retrievedData.Name);
      return retrievedData as User;
    }
  }
}

// Modify the data on the backend server
export function UpdateUserData(data: User): Promise<User | string> {
  localStorage.removeItem("userData");
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("oauthToken"),
    },
  };

  return axios
    .post(
      import.meta.env.VITE_SERVER_ADDRESS + "updateUserDetails",
      data,
      config,
    )
    .then((response) => {
      return response.data as User;
    })
    .catch((error) => {
      if (axios.isAxiosError(error)) {
        // Check for the specific 'used' response
        if (
          error.response &&
          error.response.status === 409 &&
          error.response.data === "used"
        ) {
          return "used";
        }
      }
      console.error(error);
      throw error;
    });
}

export async function RegisterOrboxUse(
  productId: number,
  skipValidation: boolean = true,
  quantity: number = 1,
  level: number = 1,
): Promise<any> {
  const config = {
    headers: { Authorization: "Bearer " + localStorage.getItem("oauthToken") },
  };

  const payload = {
    skipValidation: skipValidation, 
    product_id: productId,
    level: level,
    quantity: quantity,
  };

  try {
    const resp = await axios.post(
      import.meta.env.VITE_SERVER_ADDRESS + "useOrbox",
      payload,
      config,
    );
    return resp?.data as User; // return the data or "InvalidTransaction"
  } catch (error) {
    console.error(error);
    return "InvalidTransaction";
  }
}

export async function ValidateOrboxUse(
  productId: number,
  quantity: number = 1,
  level: number = 1,
) {
  const config = {
    headers: { Authorization: "Bearer " + localStorage.getItem("oauthToken") },
  };

  const payload = {
    product_id: productId,
    level: level,
    quantity: quantity,
  };

  try {
    await axios.post(
      import.meta.env.VITE_SERVER_ADDRESS + "validateOrbox",
      payload,
      config,
    );
  } catch (error) {
    console.error(error);
  }
}

export async function GetSubInfo(): Promise<void | Subscription> {
  if (!localStorage.getItem("subscription")) {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("oauthToken"),
      },
    };
    return await axios
      .get(import.meta.env.VITE_SERVER_ADDRESS + "getSubInfo", config)
      .then((resp) => {
        const subData: Subscription = resp?.data as Subscription;
        localStorage.setItem("subscription", JSON.stringify(subData));
        return subData;
      })
      .catch((error) => {
        console.error(error);
      });
  } else {
    const subData = localStorage.getItem("subscription");
    if (subData) {
      const retrievedData: Subscription = JSON.parse(subData) as Subscription;

      const expirationDate = new Date(retrievedData.SubscriptionExpDate);
      const currentDate = new Date();

      if (expirationDate <= currentDate) {
        localStorage.removeItem("subscription");
        return GetSubInfo();
      } else {
        return retrievedData as Subscription;
      }
    }
  }
}

export async function CancelSub(): Promise<void | Subscription> {
  const config = {
    headers: { Authorization: "Bearer " + localStorage.getItem("oauthToken") },
  };
  return await axios.get(
    import.meta.env.VITE_SERVER_ADDRESS + "cancelSub",
    config,
  );
}

export async function CheckPendingTransaction() {
  const config = {
    headers: { Authorization: "Bearer " + localStorage.getItem("oauthToken") },
  };
  return await axios.get(
    import.meta.env.VITE_SERVER_ADDRESS + "checkTransaction",
    config,
  );
}

// Split an array into chunks
export function ChunkArray(array: unknown[], size: number) {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
}

let categories: Category[];
export async function GetCategories() {
  if (categories && categories.length > 0) {
    return categories;
  }
  const resp = await axios.get(
    import.meta.env.VITE_SERVER_ADDRESS + "categories",
  );
  categories = resp?.data as Category[];
  return categories;
}

export function InitializeWagmi() {
  //
  // const { chains, provider } = configureChains(
  //   [mainnet, polygon, bsc, polygonMumbai, bscTestnet],
  //   [
  //     alchemyProvider({ apiKey: import.meta.env.VITE_REACT_APP_ALCHEMY_API_KEY, priority: 0 }),
  //     publicProvider({ priority: 1 })]
  // );
  //
  //
  // const projectId = import.meta.env.VITE_REACT_WALLET_CONNECT_PROJECT_ID;
  // const connectors = connectorsForWallets([
  //   {
  //     groupName: "Popular",
  //     wallets: [
  //       injectedWallet({ projectId, chains }),
  //       metaMaskWallet({ projectId, chains }),
  //       walletConnectWallet({ projectId, chains }),
  //       okxWallet({ projectId, chains }),
  //       rainbowWallet({ projectId, chains }),
  //       coinbaseWallet({ projectId, chains }),
  //       trustWallet({ projectId, chains }),
  //       ledgerWallet({ projectId, chains }),
  //       argentWallet({ projectId, chains })
  //
  //     ],
  //   },
  // ]);
  //
  // const wagmiClient = createClient({
  //   autoConnect: true,
  //   connectors,
  //   provider
  // });
}

export function FormatTime(date: Date) {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const tmp_minute = minutes < 10 ? "0" + minutes : minutes;

  return `${hours}:${tmp_minute} ${ampm}`;
}

export function GetBrowserLanguage() {
  return navigator.language;
}