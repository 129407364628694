import { Icon } from "@iconify/react";
import { Button, Col, Dropdown, Form, Image, Row } from "react-bootstrap";
import PrimaryBtn from "../../Components/CommonBtn/PrimaryBtn";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionMeta,
  default as OptionTypeBase,
  default as Select,
  default as ValueType,
} from "react-select";
import LordIcon from "../../Components/LordIcon/LordIcon";
import Subcategory from "../../Models/Subcategory";
import { showPopUpAction } from "../../Redux/Slices/loginPopUpSlice";
import UploadAndGenerateImage from "./UploadAndGenerateImage";
import { Link } from "react-router-dom";
import {
  GetUserCategories,
  classifyLinkType,
  readFileAsDataURL,
} from "../../Utils/ChatbotUtils";
import axios from "axios";
import { userDataSelector } from "../../Redux/Slices/userDataSlice";
import { subDataSelector } from "../../Redux/Slices/subscriptionDataSlice";
import PopupModal from "../../Components/PopUps/PopUpBase";
import PricingTabs from "../PricingPage/Components/PricingTabs/PricingTabs";
import PricingPopup from "../PricingPage/PricingPopup";
import Products from "../../Models/Products";

interface CustomOptionType extends OptionTypeBase {
  label: string;
  value: string;
  icon: JSX.Element;
}

interface DocumentDetails {
  documentType?: string;
  fileURL?: string;
  isRAG?: boolean;
}

function CreateAICompanionStepOne(
  props: any,
  { activeOption, handleOptionClick, setIsChatScreenAiAgentImgClicked },
) {
  const dispatch = useDispatch();
  const [botCategory, setBotCategory] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showPaymentScreen, setShowPaymentScreen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(new Products());
  const subData = useSelector(subDataSelector);

  const isUserLogged = !!localStorage.getItem("oauthToken");
  const handleChange = (
    selectedOption: ValueType<CustomOptionType>,
    actionMeta: ActionMeta<CustomOptionType>,
  ) => {
    setBotCategory(selectedOption.value);
  };
  // State to hold the textarea value
  const [chatbotName, setChatbotName] = useState("");
  const [aiCharacterBuilder, setAiCharacterBuilder] = useState("");
  const [documentDetails, setDocumentDetails] = useState([]);
  const [selectedGeneratedImage, setSelectedGeneratedImage] = useState("");

  const [image, setImage] = useState(() => {
    return localStorage.getItem("image") || props?.data?.image;
  });
  useEffect(() => {
    if (props?.data?.image) {
      const newImage = props.data.image;
      setImage(newImage);
      localStorage.setItem("image", newImage);
    }
  }, [props?.data]);
  // Handle change in textarea
  const handleTextareaChange = (e) => {
    if (!isUserLogged) {
      dispatch(showPopUpAction(true));
      return;
    }
    setAiCharacterBuilder(e.target.value);
  };
  useEffect(() => {
    console.log({props})
    if (props?.data) {
      setChatbotName(props?.data?.data?.chatbotName);
      setAiCharacterBuilder(props?.data?.data?.aiCharacterBuilder);
      setSelectedGeneratedImage(props?.data?.data?.image)
      // setUploadedFiles(props?.data?.data?.documentDetails);
    }
  }, [props?.data]);

  // Handle change in textarea
  const handleChatbotName = (e) => {
    if (!isUserLogged) {
      dispatch(showPopUpAction(true));
      return;
    }
    setChatbotName(e.target.value);
  };
  const onNextStep = async () => {
    if (!isUserLogged) {
      dispatch(showPopUpAction(true));
      return;
    }
    if (chatbotName.length < 2) {
      toast.error("Make sure you have set a name!");
      return;
    }
    if (aiCharacterBuilder.length < 2) {
      toast.error("Make sure you have the character builder set!");
      return;
    }

    if (!selectedGeneratedImage) {
      toast.error("Make sure you have uploaded or generated the image!");
      return;
    }
    // return;
    props.nextStep({ aiCharacterBuilder: aiCharacterBuilder, chatbotName: chatbotName, image: selectedGeneratedImage });
    props.setActiveStep(50)
  };
  const [subcategories, setCategories] = useState<Subcategory[]>([]);

  useEffect(() => {
    GetUserCategories().then((newCategories: Subcategory[]) => {
      setCategories(newCategories);
    });
  }, []);

  const [links, setLinks] = useState([""]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileUploadloading, setFileUploadLoading] = useState(false);
  const [selectedModalOption, setSelectedModalOption] = useState('Llama3');
  const selectModalOption = (event: any) => {
    if([2,3].includes(subData?.SubscriptionLevel)) {
      setSelectedModalOption(event.target.value)
    } else {
      setShowModal(true)
    }
  }

  const handleDelete = (id) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  // logic to flag URLS when the site is scrapeable
  const MIN_CONTENT_LENGTH = 5000; // Minimum length of content in characters, adjust as needed

  const [linkCheckLoading, setLinkCheckLoading] = useState(Boolean);
  const checkLinkContent = async (url: any) => {
    try {
      setLinkCheckLoading(true);
      const response = await axios.get(url, {
        timeout: 5000,
      });

      // Check for a successful response and non-empty content
      if (
        response.status === 200 &&
        response.data &&
        response.data.length > MIN_CONTENT_LENGTH
      ) {
        setLinkCheckLoading(false);
        return true;
      }

      setLinkCheckLoading(false);
      console.log(`Content from ${url} is too short or empty.`);
      return false;
    } catch (error) {
      console.error(`Failed to fetch from ${url}:`, error);
      setLinkCheckLoading(false);
      return false;
    }
  };

  
  return (
    <>
      <div className="bg-AiCompanion">
        <Row className="justify-content-center align-items-center">
          <Col lg={6} className="AiCompanion-None">
            <div className="AiCompanion-List-Info">
              <span>Step 1</span>
              <h5>Give a brain and heart to your AI companion and agent </h5>
              <p>Describe the purpose, skills, personality, and how you want your AI companion to be and help you with.</p>
            </div>
          </Col>
          <Col lg={6} className="AiCompanion-First-Height">
            <div className="AiCompanion-WIdth">
              <UploadAndGenerateImage setSelectedGeneratedImage={setSelectedGeneratedImage} selectedGeneratedImage={selectedGeneratedImage} />
              <Form className="CreateAICompanion-Main-Form">
                <Form.Group className="c-form-control c-form-control2">
                  <Form.Label><lord-icon src="https://cdn.lordicon.com/ziadhhlv.json" trigger="loop" stroke="bold" colors="primary:#ffffff,secondary:#7c98ff"></lord-icon> {t("Name your AI companion")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("Name your AI companion")}
                    name="chatbotName"
                    value={chatbotName}
                    onChange={handleChatbotName}
                  />
                </Form.Group>
                <Form.Group className="c-form-control c-form-control2">
                  <Form.Label><lord-icon src="https://cdn.lordicon.com/momsnocb.json" trigger="loop" stroke="bold" colors="primary:#ffffff,secondary:#f24c00,tertiary:#f9c9c0"></lord-icon>{t("ai_character_builder")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder={t(
                      "Describe your AI agent, its purpose, skill, and personality, and how it talks",
                    )}
                    name="aiCharacterBuilder"
                    value={aiCharacterBuilder}
                    onChange={handleTextareaChange}
                  />
                </Form.Group>

                <Form.Group className="c-form-control c-form-control2">
                  <Form.Label><lord-icon src="https://cdn.lordicon.com/nrvzczli.json" trigger="loop" stroke="bold" state="loop-cycle" colors="primary:#ffffff,secondary:#4bb3fd"></lord-icon>{t("model")}</Form.Label>
                  <ul className="createAIBotsOptions">
                    <li>
                      <label htmlFor="c-form-input-01">
                        <input type="radio" name="createAIBots" id="c-form-input-01" value={'Llama3'} checked={selectedModalOption === 'Llama3'} onChange={selectModalOption} />
                        <p>
                          <i><Image src="/images/icons/Llama.svg" /></i>
                          {t("Llama3.1")}
                        </p>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="c-form-input-02">
                        <input type="radio" name="createAIBots" id="c-form-input-02" value={'GPT'} checked={selectedModalOption === 'GPT'} onChange={selectModalOption} />
                        <p>
                          <i><Image src="/images/icons/Gpt4.svg" /></i>
                          {t("GPT 4-O")}
                          <small><Icon icon="fluent-emoji:crown" /></small>
                        </p>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="c-form-input-03">
                        <input type="radio" name="createAIBots" id="c-form-input-03" value={'Claude'} checked={selectedModalOption === 'Claude'} onChange={selectModalOption} />
                        <p>
                          <i><Image src="/images/icons/Claude.svg" /></i>
                          {t("Claude 3.5")}
                          <small><Icon icon="fluent-emoji:crown" /></small>
                        </p>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="c-form-input-04">
                        <input type="checkbox" name="createAIBots" id="c-form-input-04" checked />
                        <p>
                          <i><Image src="/images/icons/Imagegeneration.svg" /></i>
                          {t("Image generation")}
                        </p>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="c-form-input-05">
                        <input type="checkbox" name="createAIBots" id="c-form-input-05" checked />
                        <p>
                          <i><Image src="/images/icons/websiteCodegeneration.svg" /></i>
                          {t("website/code generation")}
                        </p>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="c-form-input-06">
                        <input type="checkbox" name="createAIBots" id="c-form-input-06" />
                        <p>
                          <i><Image src="/images/icons/VisionPower.svg" /></i>
                          {t("Vision power")}
                          <small><Icon icon="fluent-emoji:crown" /></small>
                        </p>
                      </label>
                    </li>
                  </ul>
                </Form.Group>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
      {linkCheckLoading ? (
        <div style={{ margin: "0 auto" }}>
          {" "}
          <div className="AiImageLoader">
            <div>
              <div className="nb-spinner"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-end">
          <Button variant="" className="AiCompanion-btn btn-primary px-4 py-2 mx-auto" onClick={onNextStep}>{t("next_step")}<i><Icon icon="heroicons:sparkles-solid" /></i></Button>
        </div>
      )}
      {localStorage.getItem("oauthToken") ? (
        <>
          <PopupModal
            show={showModal}
            handleClose={() => {setShowModal(false)}}
            className={`default-popup login-popup  ${
              !showPaymentScreen ? "PricingPopupMain" : "PricingPaymentPopup"
            }`}
            backdrop={undefined}
          >
            {!showPaymentScreen ? (
              <Link
                to="javascript:void(0)"
                className="CloseBtn"
                onClick={() => {setShowModal(false)}}
              >
                <Icon icon="solar:close-circle-linear" />
              </Link>
            ) : (
              ""
            )}
            {!showPaymentScreen ? (
              <PricingTabs
                isFaq={false}
                onPaymentToggle={() => setShowPaymentScreen(true)}
                setCurrentProduct={setCurrentProduct}
                isPopup={true}
              />
            ) : (
              <PricingPopup
                onPaymentToggle={() => setShowPaymentScreen(false)}
                product={currentProduct}
              />
            )}
          </PopupModal>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default CreateAICompanionStepOne;