export const ProductList = {
  ChatBot: 11,
};

export const SubProduct = {
  ChatBot: {
    AskAndChat: 0,
    ImageGeneration: 1,
    Apps: 2,
    Code: 3,
    GPT4: 4,
    VisionPower: 5
  }
};