import React, { useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import PDFModal from './PDFModal';
import { motion, AnimatePresence } from 'framer-motion';

interface Source {
    title: string;
    source: string;
    ragSource?: string;
    ragSourceType?: string;
    pdfMetadata?: {
      pageNumber: number;
      totalPages: number;
      creationDate: string;
      author: string;
    };
  }

interface SourceCardsProps {
  sources?: Source[];
}

const SourceCards: React.FC<SourceCardsProps> = ({ sources = [] }) => {
  const [selectedPDF, setSelectedPDF] = useState<Source | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const styles = {
    container: {
      marginTop: '20px',
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '12px',
      padding: '15px',
    },
    title: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '15px',
      display: 'flex',
      alignItems: 'center',
      color: '#ffffff',
      cursor: 'pointer',
    },
    icon: {
      marginRight: '10px',
      fontSize: '20px',
    },
    cardContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
      gap: '15px',
    },
    card: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '8px',
      padding: '15px',
      cursor: 'pointer',
      textDecoration: 'none',
      color: '#ffffff',
      transition: 'background-color 0.3s ease',
      display: 'flex',
      flexDirection: 'column' as const,
    },
    cardTitle: {
      fontSize: '14px',
      fontWeight: 'normal',
      margin: '10px 0 0 0',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical' as const,
    },
    youtubePreview: {
      width: '100%',
      aspectRatio: '16 / 9',
      border: 'none',
      borderRadius: '8px',
    },
    viewMore: {
      marginTop: '15px',
      fontSize: '14px',
      color: '#ffffff',
      cursor: 'pointer',
      textAlign: 'center' as const,
    },
    pdfPreview: {
        width: '100%',
        height: '150px',
      },
  };

  if (!sources || sources.length === 0) {
    return null;
  }

  const getYouTubeEmbedUrl = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
      return `https://www.youtube.com/embed/${match[2]}`;
    }
    return null;
  };

  const youtubeSource = sources.find(source => source.ragSourceType === 'youtube');
  const otherSources = sources.filter(source => source.ragSourceType !== 'youtube');

  // const tempPDFLink = "https://orbofi-pinecone.s3.ap-southeast-1.amazonaws.com/file/20240905/ashcon_mohseninia_open_source_vehicle_ecu_diagnostics_and_testing_platform.pdf"

  const uniqueSources = [
    ...(youtubeSource ? [youtubeSource] : []),
    ...otherSources.filter((source, index, self) =>
      index === self.findIndex((t) => {
        if (source.ragSourceType === 'pdf' && t.ragSourceType === 'pdf') {
          return t.ragSource === source.ragSource && 
                 t.pdfMetadata?.pageNumber === source.pdfMetadata?.pageNumber;
        } else {
          return t.ragSource === source.ragSource && t.title === source.title;
        }
      })
    )
  ];

  

  

  const displayedSources = uniqueSources.slice(0, 4);
  const remainingSources = Math.max(0, uniqueSources.length - 4);

  return (
    <div style={styles.container}>
      <h3 style={styles.title} onClick={() => setIsExpanded(!isExpanded)}>
        <span style={styles.icon}>📚</span> Sources
        <span style={{ marginLeft: 'auto' }}>{isExpanded ? '▲' : '▼'}</span>
      </h3>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div style={styles.cardContainer}>
              {displayedSources.map((source, index) => (
                <div key={index} style={styles.card} onClick={() => source.ragSourceType === 'pdf' && setSelectedPDF(source)}>
                  {
                  source.ragSourceType === 'pdf' ? (
                      <>
                        <div style={styles.pdfPreview}>
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
                          <Viewer
                            fileUrl={source.ragSource}
                            initialPage={source.pdfMetadata?.pageNumber ? source.pdfMetadata.pageNumber - 1 : 0}
                          />
                        </Worker>
                        </div>
                        <h4 style={styles.cardTitle}>
                          {source.title} (Page {source.pdfMetadata?.pageNumber} of {source.pdfMetadata?.totalPages})
                        </h4>
                      </>
                    ) :
                  source.ragSourceType === 'youtube' ? (
                    <>
                      {getYouTubeEmbedUrl(source.ragSource) && (
                        <iframe
                          style={styles.youtubePreview}
                          src={getYouTubeEmbedUrl(source.ragSource)}
                          title={source.title}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      )}
                      <h4 style={styles.cardTitle}>{source.title}</h4>
                    </>
                  ) : 
                  source.ragSourceType === 'link' ?
                  (
                    <a
                      href={source.source}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#ffffff', textDecoration: 'none' }}
                    >
                      <h4 style={styles.cardTitle}>{source.title}</h4>
                    </a>
                  ):
                  null
                  }
                </div>
              ))}
            </div>
            {remainingSources > 0 && (
              <div style={styles.viewMore}>
                View {remainingSources} more
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {selectedPDF && (
        <PDFModal
          isOpen={!!selectedPDF}
          onClose={() => setSelectedPDF(null)}
          pdfUrl={selectedPDF.ragSource}
          pageNumber={selectedPDF.pdfMetadata?.pageNumber || 1}
          title={selectedPDF.title}
        />
      )}
    </div>
  );
};

export default SourceCards;