
import "./Loader.css";

const LoaderLastStep = (props) => {

  return (

    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1691.3 434.28"
      className="mb-5"
      width={400}
      height={200}
      {...props}
    >
      <defs>
        <style>
          {
            ".cls-1{fill:url(#linear-gradient);}.cls-1,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8{stroke-width:0px;}.cls-2{fill:url(#radial-gradient);}.cls-3{fill:url(#linear-gradient-4);}.cls-4{fill:url(#linear-gradient-2);}.cls-5{fill:url(#linear-gradient-3);}.cls-6{fill:url(#linear-gradient-5);}.cls-7{fill:url(#linear-gradient-6);}.cls-8{fill:#fff;}"
          }
        </style>
        <linearGradient
          id="linear-gradient"
          x1={496.77}
          y1={0.68}
          x2={271.27}
          y2={217.83}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.43} stopColor="#fff" />
          <stop offset={0.99} stopColor="#c2eebb" />
        </linearGradient>
        <radialGradient
          id="radial-gradient"
          cx={265.56}
          cy={312.43}
          fx={265.56}
          fy={312.43}
          r={61.9}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.43} stopColor="#3fc4ff" />
          <stop offset={0.99} stopColor="#4e5afe" />
        </radialGradient>
        <linearGradient
          id="linear-gradient-2"
          x1={402.7}
          y1={-97.01}
          x2={177.2}
          y2={120.14}
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1={426.63}
          y1={-72.16}
          x2={201.12}
          y2={144.99}
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1={364.31}
          y1={-136.88}
          x2={138.8}
          y2={80.27}
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1={482.26}
          y1={-14.39}
          x2={256.76}
          y2={202.76}
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1={494.21}
          y1={-1.98}
          x2={268.71}
          y2={215.17}
          xlinkHref="#linear-gradient"
        />
      </defs>
      <path
        className="cls-1 svg-elem-1"
        d="m520.12,190.18H11.11c-6.19,0-11.11,6.35-11.11,13.96,0,3.8,1.27,7.34,3.23,9.87,1.99,2.39,4.79,3.95,7.88,3.95h8.74c-6.05,0-11,6.19-11,13.8,0,3.95,1.13,7.47,3.25,9.87,1.97,2.53,4.65,4.09,7.75,4.09h8.88c-6.05,0-10.98,6.19-10.98,13.8,0,3.82,1.13,7.34,3.09,9.87,2.12,2.53,4.79,4.09,7.88,4.09h118.39c-1.13,5.49-1.99,11.13-2.26,17.05l-.86,15.92c-3.52,69.47,51.99,127.83,121.49,127.83s125.13-58.36,121.6-127.83l-.84-15.92c-.29-5.92-.99-11.56-2.26-17.05h118.37c3.11,0,5.94-1.56,7.77-4.09,2.1-2.53,3.23-6.05,3.23-9.87,0-7.61-4.92-13.8-11-13.8h8.9c3.09,0,5.76-1.56,7.75-4.09,1.97-2.39,3.23-5.92,3.23-9.87,0-7.61-4.92-13.8-10.98-13.8h8.86c2.96,0,5.65-1.56,7.77-3.95,1.97-2.53,3.23-6.07,3.23-9.87,0-7.61-4.92-13.96-11-13.96Zm-179.1,125.71c-1.83,38.76-33.11,70.17-71.87,72.02-44.54,1.97-81.17-34.67-79.05-79.07,1.81-38.74,33.11-70.17,71.87-71.87,44.52-2.12,81.17,34.53,79.05,78.91Z"
      />
      <path
        className="cls-2 svg-elem-2"
        d="m341.02,315.88c-1.83,38.76-33.11,70.17-71.87,72.02-44.54,1.97-81.17-34.67-79.05-79.07,1.81-38.74,33.11-70.17,71.87-71.87,44.52-2.12,81.17,34.53,79.05,78.91Z"
      />
      <path
        className="cls-4 svg-elem-3"
        d="m174.59,124.71c-4.59-11.89-6.92-25.67-6.92-40.97s2.33-28.98,6.92-40.86c.43-1.1.88-2.18,1.34-3.25-11.7,3.79-21.72,10.6-28.92,20.74,8.22,5.78,13.49,15.72,13.49,30.41,0,19.89-9.58,31-23.04,34.97,7.78,31.69,33.01,45.74,62.97,45.74,6.73,0,13.22-.71,19.33-2.17-9.91-3.68-18.67-8.99-26.01-15.83-8.34-7.76-14.78-17.44-19.16-28.77Z"
      />
      <path
        className="cls-5 svg-elem-4"
        d="m335.16,37.19c-3.53-6.53-7.95-12.3-13.27-17.25-13.97-13.04-33.76-19.93-57.22-19.93s-43.25,6.89-57.22,19.93c-5.32,4.96-9.74,10.72-13.26,17.25-6.83,12.67-10.28,28.24-10.28,46.56,0,27.85,7.92,49.35,23.54,63.9,8.92,8.3,20.23,14.1,33.38,17.2,7.41,1.75,15.38,2.64,23.85,2.64s16.45-.9,23.85-2.64c13.15-3.1,24.47-8.9,33.38-17.2,15.62-14.55,23.54-36.05,23.54-63.9,0-18.32-3.45-33.89-10.28-46.56Zm-61.89,53.15c-2.31.35-4.7.53-7.16.53-3.46,0-6.8-.35-9.97-1.06-14.71-3.31-25.43-14.54-25.43-35.58,0-5.57.76-10.47,2.14-14.7,4.32-13.17,14.74-20.04,27.44-21.6-6.9,1.89-11.84,7.48-11.84,17.52,0,12.75,7.9,18.28,17.65,18.28,3.05,0,5.91-.54,8.41-1.67,2.32-1.05,4.32-2.61,5.87-4.71,2.12-2.89,3.37-6.82,3.37-11.9,0-10.05-4.95-15.63-11.84-17.52,12.46,1.53,22.71,8.16,27.18,20.82,1.56,4.41,2.42,9.56,2.42,15.48,0,22.36-12.12,33.64-28.25,36.12Z"
      />
      <path
        className="cls-3 svg-elem-5"
        d="m136.77,70.73c-1.93-.57-4.02-.85-6.2-.85-9.78,0-17.7,5.59-17.7,18.32s7.92,18.32,17.7,18.32c.01,0,.03,0,.04,0,9.76-.01,17.66-5.56,17.66-18.32,0-9.89-4.78-15.47-11.5-17.47Z"
      />
      <path
        className="cls-6 svg-elem-6"
        d="m370.63,90.77c0-14.69,5.27-24.63,13.49-30.41-7.2-10.13-17.22-16.95-28.92-20.74.47,1.07.92,2.15,1.34,3.25,4.6,11.88,6.92,25.63,6.92,40.86s-2.33,29.08-6.92,40.97c-4.38,11.32-10.82,21-19.16,28.77-7.34,6.84-16.1,12.14-26.01,15.83,6.11,1.46,12.6,2.17,19.33,2.17,29.96,0,55.19-14.05,62.97-45.74-13.46-3.96-23.04-15.08-23.04-34.97Z"
      />
      <path
        className="cls-7 svg-elem-7"
        d="m400.55,69.87c-2.18,0-4.27.28-6.2.85-6.72,2-11.5,7.58-11.5,17.47,0,12.77,7.9,18.31,17.66,18.32.01,0,.03,0,.04,0,9.78,0,17.7-5.54,17.7-18.32s-7.92-18.32-17.7-18.32Z"
      />
      <path
        className="cls-8 svg-elem-8"
        d="m785.09,201.33c20.46,18.06,22.42,49.78,22.42,73.25s-2.24,55.2-22.42,73.26c-21.58,19.09-55.22,19.09-78.2,19.09s-56.62.26-78.2-19.09c-22.42-20.12-22.7-52.88-22.7-73.26,0-24.51,1.12-54.94,22.7-73.25,21.58-19.35,54.38-19.09,78.2-19.09s56.62-.26,78.2,19.09Zm-128.94,123.56c11.21,13.67,30.27,14.96,50.46,14.96s38.68-1.29,50.73-14.96c6.45-7.48,12.06-21.41,12.06-50.3,0-31.21-5.89-43.59-12.06-50.56-11.49-13.16-30.55-14.7-50.73-14.7s-38.96,1.54-50.46,14.7c-7.57,9.03-12.05,22.7-12.05,50.56,0,29.4,5.6,43.08,12.05,50.3Z"
      />
      <path
        className="cls-8 svg-elem-9"
        d="m838.07,215c0-7.48.28-16.51,8.13-23.47,7.01-6.19,15.98-7.22,23.55-7.22h93.06c13.45,0,30.84.78,42.05,16.51,9.25,12.38,9.81,26.57,9.81,39.73s-.56,27.34-8.41,40.76c-5.04,8.77-14.29,18.57-37,18.57h-25.23v1.29l74.56,63.71h-46.53l-64.47-58.55c-4.2-3.87-9.25-10.06-9.25-15.22v-4.13c0-7.22,5.61-12.38,16.54-12.38h37c6.44,0,14.86-.77,20.18-8,3.92-5.93,4.48-18.57,4.48-24.76,0-14.44-2.8-21.15-7.01-25.02-5.88-5.41-14.85-5.41-20.74-5.41h-59.99c-4.2,0-6.73,0-9.81,2.84-4.2,3.09-3.92,6.19-3.92,10.06v140.58h-37v-149.87Z"
      />
      <path
        className="cls-8 svg-elem-10"
        d="m1211.42,283.1c8.97,9.8,9.25,21.93,9.25,32.24,0,12.12,0,26.31-11.21,37.92-11.21,11.61-23.83,11.61-35.88,11.61h-93.9c-9.81,0-18.78,1.03-26.63-7.22-1.96-2.06-6.73-7.99-6.73-18.31v-128.97c0-6.96,1.96-13.67,6.17-18.57,5.89-6.45,14.29-7.48,20.74-7.48h96.43c10.93,0,24.95,1.03,35.6,10.84,10.93,10.06,12.33,27.08,12.33,38.69,0,10.06-.56,19.86-9.25,29.41-5.6,6.19-10.93,8.51-18.5,9.28v1.03c9.25.77,16.82,4.64,21.58,9.54Zm-128.09-22.96h71.76c7.57,0,14.86,0,20.18-6.45,1.4-1.81,4.21-5.93,4.21-18.31,0-4.9,0-13.93-6.45-19.34-5.33-4.9-11.77-4.64-18.22-4.64h-60.54c-6.45-2.06-12.06,4.9-10.93,10.06v38.69Zm0,64.23c0,5.93.84,8.77,2.24,10.58,3.08,3.09,8.97,2.84,12.05,2.84h58.3c5.89,0,15.98-.26,21.58-6.45,4.21-4.9,5.05-12.38,5.05-18.31,0-5.16-.56-13.41-4.21-18.31-2.24-2.84-7.85-7.48-19.9-7.48h-75.12v37.14Z"
      />
      <path
        className="cls-8 svg-elem-11"
        d="m1424.74,201.33c20.46,18.06,22.42,49.78,22.42,73.25s-2.24,55.2-22.42,73.26c-21.58,19.09-55.22,19.09-78.2,19.09s-56.62.26-78.2-19.09c-22.42-20.12-22.7-52.88-22.7-73.26,0-24.51,1.12-54.94,22.7-73.25,21.58-19.35,54.38-19.09,78.2-19.09s56.62-.26,78.2,19.09Zm-128.94,123.56c11.21,13.67,30.27,14.96,50.46,14.96s38.68-1.29,50.73-14.96c6.45-7.48,12.06-21.41,12.06-50.3,0-31.21-5.89-43.59-12.06-50.56-11.49-13.16-30.55-14.7-50.73-14.7s-38.96,1.54-50.46,14.7c-7.57,9.03-12.05,22.7-12.05,50.56,0,29.4,5.6,43.08,12.05,50.3Z"
      />
      <path
        className="cls-8 svg-elem-12"
        d="m1477.72,364.87v-156.83c0-5.93,1.4-12.12,7.29-17.28,6.73-5.93,14.29-6.45,21.02-6.45h127.82v27.09h-109.88c-2.52,0-4.76-.26-7.01,2.06-2.24,2.06-2.24,4.9-2.24,7.22v39.21h86.89v27.08h-86.89v77.9h-37Z"
      />
      <path
        className="cls-8 svg-elem-13"
        d="m1654.3,364.87v-180.56h37v180.56h-37Z"
      />
    </svg>

  );
};

export default LoaderLastStep;
