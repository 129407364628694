
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import User from "../../Models/User";
import { useSelector } from "react-redux";
import { userDataSelector } from "../../Redux/Slices/userDataSlice";
import CompanionCard from "../../Components/Cards/CompanionCard";
import { ChatbotData, GetLocalUserChatbots, GetUserChatbots } from "../../Utils/ChatbotUtils";

import Masonry from "react-masonry-css";
import { Col, Row } from "react-bootstrap";

function UserCreatedChatbots({ userData }: { userData: User }) {

  const [userChatbots, setUserChatbots] = useState<ChatbotData[]>([]);
  const localUser = useSelector(userDataSelector);

  useEffect(() => {
    if (userData.Email === localUser.Email) {
      GetLocalUserChatbots().then((chatbots) => { setUserChatbots(chatbots); });
      return;
    }
    GetUserChatbots(userData.UserId).then((chatbots) => { setUserChatbots(chatbots); });
  }, [userData]);

  const breakpointColumns = {
    default: 8,
    1980: 7,
    1800: 6,
    1469: 5,
    1300: 4,
    1199: 3,
    876: 2,
    479: 1,
  };


  return (
    <>
      <ul className="userChatbotWrapProfile">
        {userChatbots.map((chatbot, index) => (
          <li>
            <Link key={index} to={`/chat/${chatbot.name}?id=${chatbot.id}`}>
              <CompanionCard chatbotData={chatbot as ChatbotData} />
            </Link>
          </li>
        ))}
      </ul>
      <Masonry
        breakpointCols={breakpointColumns}
        className="my-masonry-grid UserChatBotCreated"
        columnClassName="my-masonry-grid_column"
      >
      </Masonry>
    </>
  );
}

export default UserCreatedChatbots;
