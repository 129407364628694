import CodeMirror from "./CodeMirror";
import { Icon } from "@iconify/react";
// import { Settings } from "./hooks/types";
import copy from "copy-to-clipboard";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

interface Props {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;

}

function CodeTab({ code, setCode }: Props) {
  const copyCode = useCallback(() => {
    copy(code);
    toast.success("Copied to clipboard");
  }, [code]);

  return (
    <div className="relative">
      <Link to="javascript:void(0)"
        title="Copy Code"
        className="CopyCodeLink"
        onClick={copyCode}
      >
        Copy Code <Icon className="ml-2" icon="solar:copy-line-duotone" />
      </Link>
      <div className="CodeMirrorScroll">
        <CodeMirror
          code={code}
          onCodeChange={setCode}
        />
      </div>
    </div>
  );
}

export default CodeTab;