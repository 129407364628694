import { Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import {ChatbotData, ToggleFavouriteChatbot} from "../../Utils/ChatbotUtils";
import { useEffect, useState } from "react";
import SmoothImage from "react-smooth-image";
import UserChatbot from '../../Models/UserChatbot';
import { useDispatch } from "react-redux";
import { showPopUpAction } from "../../Redux/Slices/loginPopUpSlice";
import {updateDirtyFlags} from "../../Redux/Slices/dirtyFlagsSlice";

export default function CompanionCard({ chatbotData }: { chatbotData: ChatbotData | UserChatbot }): JSX.Element {
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLinkClick = () => {
    if (chatbotData?.user_id && chatbotData?.user_id >= 0) {
      // User Created Chatbot
      navigate(`/chat/${chatbotData?.name}?id=${chatbotData?.id}`);
    }
    else {
      navigate(`/chat/${chatbotData?.string_id}`);
    }
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1050);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>

      <div className="CompanionShorts-Main">
        {loading ? (
          <Link to="#">
            <div className="FCompanions-Img skeleton">
              <Image src={chatbotData?.img} className="opacity-0" />
            </div>
            <div className="FCompanions-Info">
              <div className="FCompanions-Info-Title">
                <div className="skeleton ">
                  <h6 className="opacity-0">{chatbotData?.name}</h6>
                </div>
                <div className="skeleton ml-auto">
                  <Link to="javascript:void(0)" onClick={()=>{ToggleFavouriteChatbot(chatbotData).then((isFav)=>{setIsActive(isFav); dispatch(updateDirtyFlags({ FavouriteChatbotsDirty: true }));} );}} className={`FavoriteLink opacity-0 favicon ${isActive ? 'active' : ''}`}> <Icon icon={isActive ? "mingcute:star-fill" : "mingcute:star-line"} /></Link>
                  <Link to="javascript:void(0)" onClick={handleLinkClick} className="opacity-0"><Icon icon="ep:top-right" /></Link>
                </div>
              </div>
              <div className="skeleton ">
                <p className="opacity-0">"build in seconds any kind of voice-powered AI agents and AI clones"</p>
              </div>
            </div>
          </Link>
        ) : (

          <Link to='javascript:void(0)' onClick={handleLinkClick}>
            <div className="CompanionShorts-Img">
              <SmoothImage transitionTime={1.0}
                alt={chatbotData?.name}
                src={chatbotData?.img}
                width={100} // Example width, adjust as needed
                height={100} // Example height, adjust as needed
              />
            </div>
            <div className="CompanionShorts-Info">
              <div className="CompanionShorts-Info-Title">
                <h5> {chatbotData?.name} {chatbotData?.source === 4 ? (<span><Icon style={{color:"#b39800", fontSize: '16px'}} icon="fluent:checkmark-starburst-16-filled" /></span>) :  ''}</h5>
                <Link to="javascript:void(0)" onClick={()=>{ToggleFavouriteChatbot(chatbotData).then((isFav)=>{setIsActive(isFav); dispatch(updateDirtyFlags({ FavouriteChatbotsDirty: true }));} );}} className={`FavoriteLink favicon ${isActive ? 'active' : ''}`}> <Icon icon={isActive ? "mingcute:star-fill" : "mingcute:star-line"} /></Link>
                <Link to='javascript:void(0)'><Icon icon="ep:top-right" /></Link>
              </div>
              <p>{chatbotData?.description.substring(0, 60) + (chatbotData.description.length > 60 ? "..." : "") || "build in seconds any kind of voice-powered AI agents and AI clones"}</p>
            </div>
          </Link>
        )}
      </div>

    </>
  );
}