import React, { useEffect, useRef, useState } from 'react';
import { Icon } from "@iconify/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider, { Settings } from "react-slick";
import CompanionsBox from "../CompanionsBox/CompanionsBox";
import "./CompanionsSlider.css";
import { t } from "i18next";
import { ChatbotData, GetChatbotList, GetRandomParentChatbots } from '../../Utils/ChatbotUtils';
import CompanionCard from "../Cards/CompanionCard";
import LordIcon from '../LordIcon/LordIcon';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showPopUpAction } from '../../Redux/Slices/loginPopUpSlice';


interface ComponentProps {
  isAtTop: boolean
}

function CompanionsSliderFirst({ isAtTop }: ComponentProps) {
  const sliderRef = useRef<Slider | null>(null);
  const settings: Settings = {
    autoplaySpeed: 2500,
    arrows: false,
    cssEase: "linear",
    slidesToShow: 6,
    slidesToScroll: 6,
    useTransform: true,
    draggable: false,
    responsive: [
      {
        breakpoint: 2800,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1430,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3.03,
          slidesToScroll: 3.03,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.02,
          slidesToScroll: 2.02
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1.05,
          slidesToScroll: 1.05,
        },
      },
    ],
  };
  const goToPreviousSlide = () => {
    sliderRef?.current?.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef?.current?.slickNext();
  };

  // Setting Sample of data to load first carousel and hide other carousels with no data yet.
  const [chatbotList, setChatbotList] = useState<ChatbotData[]>([{
    "id": 4605767,
    "name": "",
    "category_id": 1,
    "img": "",
    "description": " Jerome Edgar Horton is an American accountant and politician who served on the California Board of Equalization from 2009 to 2019, representing the 3rd district. He was appointed to the board by Governor Arnold Schwarzenegger to replace Judy Chu. Prior to that, Horton was a member of the California State Assembly from 2000 to 2006. In 2020, he announced his candidacy for the 2",
    "system_prompt": "}} '''Jerome Edgar Horton''' (born September 14, 1956) is an American accountant and politician who was the member of the California Board of Equalization from the 3rd district from October 5, 2009 to January 7, 2019. He previously served as a member of the California State Assembly from 2000 until 2006. ==Career== On July 15, 2009, Governor California Board of Equalization to replace Judy Chu, who resigned from the board to become a member of the United States House of Representatives. On November 9, 2020, Horton announced his candidacy for the 2022 U.S. Senate election in California. ===Allegations of Improper Activity=== An audit by the Governor Jerry Brown stripping the board of most of its powers. ==Electoral history== === 2010 === === 2006 === === 2004 === }} === 2002 === }} ==References== ==External links== *[https://web.archive.org/web/20050301203504/http://www.assembly.ca.gov/acs/makebio.asp?district=51 Legislative website] (archived) **[http://www.joincalifornia.com/candidate/6105 Join California Jerome E. Horton] |- Category:1956 births Category:African-American state legislators in California Category:Living people Category:Democratic Party members of the California State Assembly Category:People from Los Angeles County, California Category:Politicians from Pine Bluff, Arkansas Category:21st-century American politicians Category:21st-century African-American politicians Category:20th-century African-American people",
    "audio_url": "",
    "source": 2,
    "popularity": 0,
    "voice": 1,
    "character_length": 1804,
    "long_description": "",
    "categories": "",
    "rag": false,
    "chatbot_id": 1356258,
    "subcategory_id": 432149,
    "priority": 0
  },
  {
    "id": 4605730,
    "name": "",
    "category_id": 1,
    "img": "",
    "description": "",
    "system_prompt": "}} '''Jerome Bunty Chaffee''' (April 17, 1825 – March 9, 1886) was an American entrepreneur and United States Senator from Colorado. Chaffee County, Colorado ==References== * ''[http://bioguide.congress.gov/scripts/biodisplay.pl?index=C000271 Biographical Directory of the United States Congress: Chaffee, Jerome Bunty]'', accessed 9 December 2005 * ''The National Cyclopædia of American Biography''. (1929) Vol. VI New York: James T. White & Co. pp. 199–200. * ''Who was Who in America, Historical Volume''. (1963) Chicago: Marquis Who's Who Category:1825 births Category:1886 deaths Category:Members of the Colorado Territorial Legislature Category:Delegates to the United States House of Representatives from Colorado Territory Category:Politicians from Niagara County, New York Category:People from Adrian, Michigan Category:Colorado Republican Party chairs Category:Republican Party United States senators from Colorado Category:Republican Party members of the United States House of Representatives from Colorado Category:19th-century American legislators",
    "audio_url": "",
    "source": 2,
    "popularity": 0,
    "voice": 1,
    "character_length": 1520,
    "long_description": "",
    "categories": "",
    "rag": false,
    "chatbot_id": 1356070,
    "subcategory_id": 432155,
    "priority": 0
  },
  {
    "id": 4605687,
    "name": "",
    "category_id": 1,
    "img": "",
    "description": "",
    "system_prompt": "}} '''Jero Wacik''' (born 24 April 1949 ==References== Category:1949 births Category:Living people Category:Balinese people Category:University of Indonesia alumni Category:Bandung Institute of Technology alumni Category:Government ministers of Indonesia Category:Indonesian Hindus Category:Politicians from Bali Category:Indonesian politicians convicted of corruption Category:People from Buleleng Regency {{Indonesia-politician-stub}}",
    "audio_url": "",
    "source": 2,
    "popularity": 0,
    "voice": 1,
    "character_length": 757,
    "long_description": "",
    "categories": "",
    "rag": false,
    "chatbot_id": 1355925,
    "subcategory_id": 0,
    "priority": 0
  },
  {
    "id": 4605680,
    "name": "",
    "category_id": 1,
    "img": "",
    "description": "",
    "system_prompt": "'''Jermmas Chuenglertsiri''' ( In 2018 she was one of executive board-of-directors of the Democrat Party. In the election of a new Democrat Party leader in November 2018, she was appointed as one of the five election commission of the party. In the election on March 24, 2019, she was a candidate for election in Bangkok's one constituency, consisting of Phra Nakhon, Pom Prap Sattru Phai, Samphanthawong and Dusit (except Thanon Nakhon Chai Si). She was not elected, losing to Karnkanit Heawsantati, the female candidate from the Palang Pracharath Party. In the 2022 Bangkok gubernatorial election, where members of the BMC were elected at the same time, her daughter Nipapan \"Gub\" Chuenglertsiri was also elected as a representative in Pom Prap Sattru Phai district. She was one of nine Democrats elected this time.",
    "audio_url": "",
    "source": 2,
    "popularity": 0,
    "voice": 1,
    "character_length": 1278,
    "long_description": "",
    "categories": "",
    "rag": false,
    "chatbot_id": 1355889,
    "subcategory_id": 0,
    "priority": 0
  },
  {
    "id": 4605596,
    "name": "",
    "category_id": 1,
    "img": "",
    "description": "",
    "system_prompt": "}} '''Jeremy J. Taylor''' (born April 19, 1978) is an American educator and Republican politician. He served a single term on the Iowa House of Representatives from 2011 to 2013, and was a member of the Woodbury County Board of Supervisors between 2014 and 2020. ==Early life and career== Taylor was born in 1978, in Sioux City, Iowa, Taylor's residency and voter registration was challenged in December 2019 by Maria Rundquist, Taylor remained a candidate for the Republican primary for the United States House of Representatives in Iowa's 4th congressional district, a seat held by Steve King. On January 12, 2023, the United States Department of Justice announced the arrest and indictment of Taylor’s wife, Kim Phuong Taylor, on charges of voter fraud in relation to Taylor’s unsuccessful candidacy in 2020 for the Republican primary and his successful candidacy for Woodbury County Supervisor. ==References== ==Sources== *[http://www.iowahouserepublicans.com/members/jeremy-taylor/biography Iowa Republican caucus bio of Taylor]*[https://web.archive.org/web/20111218030032/http://www.legis.iowa.gov/Legislators/legislatorAllYears.aspx?PID=10782 Iowa legislature bio of Taylor] Category:1978 births Category:Living people Category:University of South Dakota alumni Category:Dowling College alumni Category:Schoolteachers from Iowa Category:Republican Party members of the Iowa House of Representatives Category:County supervisors in Iowa Category:21st-century American educators Category:Politicians from Sioux City, Iowa Category:21st-century American politicians Category:Liberty University alumni",
    "audio_url": "",
    "source": 2,
    "popularity": 0,
    "voice": 1,
    "character_length": 1959,
    "long_description": "",
    "categories": "",
    "rag": false,
    "chatbot_id": 1355525,
    "subcategory_id": 0,
    "priority": 0
  },]);
  const [isLoading, setIsLoading] = useState(false);

  const getRandomNumber = () => {
    // Define the ranges
    const ranges = [
      [2, 7],
      [12, 16],
      [19, 20],
      [24, 24]
    ];

    // Randomly select a range
    const randomRange = ranges[Math.floor(Math.random() * ranges.length)];

    // Generate a random number within the selected range
    return Math.floor(Math.random() * (randomRange[1] - randomRange[0] + 1)) + randomRange[0];
  };


  useEffect(() => {
    if (chatbotList.length <= 5) {
      const randomPriority = getRandomNumber();
      GetRandomParentChatbots(randomPriority).then((resp) => {
        setChatbotList(resp.sort(() => Math.random() - 0.5));
        setIsLoading(false);
      }).catch(console.error);
    }
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function CreateAiCompanion() {
    if (localStorage.getItem("oauthToken")) {
      navigate("/createaicompanion");
    }
    else {
      dispatch(showPopUpAction(true));
    }
  }

  return (
    <div className="FavoriteCompanions relative">
      <div className="CompanionShortsTitleWrap">
        <div className="titleText">
          <h2>{t("try_one_of_our_favorite_companions")}</h2>
          <p>Use any Smart AI clone to get answers for anything, generate images, code, or just to chit-chat. Smart AI clones are personalities with AI superpowers</p>
        </div>
        <div className="d-flex gap-2 desktopArrows">
          <div className="SliderPrevbtn" onClick={goToPreviousSlide}>
            <Icon icon="ep:arrow-left-bold" />
          </div>
          <div className="Slidernextbtn" onClick={goToNextSlide}>
            <Icon icon="ep:arrow-right-bold" />
          </div>
        </div>
      </div>
      {!isLoading &&
        <Slider ref={sliderRef} {...settings} className="CompanionShortsSlider tinderdesktop">
          {isAtTop ? <div>
            <div onClick={CreateAiCompanion} className="SliderAddCard">
              <LordIcon
                src="https://cdn.lordicon.com/zrkkrrpl.json"
                colors="primary:#53aab0,secondary:#53aab0"
                trigger="loop"
              />
              <h6>Create a voice AI <br /> assistant</h6>

            </div>
          </div> : ""}
          {chatbotList.map((chatbot) => {
            return <CompanionCard key={chatbot.chatbot_id} chatbotData={chatbot} />;
          })
          }
        </Slider>
      }
      <div className="d-flex gap-2 mobileArrows">
        <div className="SliderPrevbtn" onClick={goToPreviousSlide}>
          <Icon icon="ep:arrow-left-bold" />
        </div>
        <div className="Slidernextbtn" onClick={goToNextSlide}>
          <Icon icon="ep:arrow-right-bold" />
        </div>
      </div>
    </div>
  );
}

export default CompanionsSliderFirst;
