import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SiteMap.css'; // Import CSS file for styling

interface Chatbot {
  id: string;
  name: string;
}

const SiteMap: React.FC = () => {
  const [letter, setLetter] = useState('a');
  const [page, setPage] = useState(1);
  const [chatbots, setChatbots] = useState<Chatbot[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchChatbots();
  }, [letter, page]);

  const fetchChatbots = async () => {
    setLoading(true); // Start loading
    setChatbots([]); // Clear chatbots list when fetching new data

    try {
      const response = await axios.get(`https://test-server.orbofi.com/getOrderedChatbot?letter=${letter}&page=${page}&limit=10`);
      console.log(response);
      setChatbots(response.data); // Assume chatbots array is in response.data.chatbots
      setTotalPages(10000);
    //   setTotalPages(response.data.totalPages); // Assume total pages is provided in response
    } catch (error) {
      console.error("Error fetching chatbots:", error);
    } 
    finally {
        setLoading(false); // Stop loading
    }
  };

  const handleLetterChange = (newLetter: string) => {
    setLetter(newLetter);
    setPage(1); // Reset to first page on letter change
    // fetchChatbots();
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode(97 + i)); // a to z

  return (
    <div className="sitemap-container" style={{marginTop: '130px'}}>
      <h1 className="title">Sitemap of Chatbots</h1>

      {/* Letter Navigation */}
      <div className="letter-navigation">
        {alphabet.map((char) => (
          <button
            key={char}
            onClick={() => handleLetterChange(char)}
            className={`letter-button ${char === letter ? 'active' : ''}`}
          >
            {char.toUpperCase()}
          </button>
        ))}
      </div>
      {loading && <p className="loading-indicator">Loading...</p>}
      {/* Chatbot List */}
      <div className="chatbot-list">
        {chatbots.length > 0 ? (
          chatbots.map((chatbot) => (
            <div key={chatbot.id} className="chatbot-item">
              {chatbot.name}
            </div>
          ))
        ) : (
          <p className="no-chatbots">Loading data for "{letter.toUpperCase()}".</p>
        )}
      </div>

      {/* Pagination */}
      <div className="pagination">
        <button onClick={() => handlePageChange(page - 1)} disabled={page === 1} className="pagination-button">
          Previous
        </button>
        <span className="pagination-info">
          Page {page} of {totalPages}
        </span>
        <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages} className="pagination-button">
          Next
        </button>
      </div>
    </div>
  );
};

export default SiteMap;
