import axios from "axios";

export type ConversationData = {
    id: number;
    ChatHeader: string;
    ConversationId: number;
};

export type StatsData = {
    "sent_messages_audio": number,
    "chat_id": number | undefined,
    "creator_user_id": number | undefined,
    "user_id": number | undefined,
    "session_id": number,
    "conversation_id": number,
    "first": boolean,
    "last_message_sender": string | undefined,
    "last_message_chat": string,
    "delete_stat": boolean,
}
async function GetConversations(chatId: number): Promise<ConversationData> {
  const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
  try {
    const response = await axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}getConversations`, chatId, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching chat conversations: ", error);
    return [];
  }
}

export function CleanConversationJson()
{
    
}

export function InitConversationsHistory(chatbot) : ConversationData{
  if (chatbot.chatbot_id !== undefined) {
    GetConversations(chatbot.chatbot_id).then(
      (conversations: ConversationData) => {
        return conversations;
      }
    );
  } else if (chatbot.user_id !== undefined) {
    GetConversations(chatbot.id).then(
      (conversations: ConversationData) => {
        return conversations;
      }
    );
  }
}

/*
export async function LoadConversation(item, event)
{
  event.preventDefault(); // Prevent the default link behavior
  setStatsData((prev) => {
    console.log("Prev: ", prev);
    if (prev.sent_messages_audio > 0) {
      finalStatHistory({
        ...prev,
        conversation_id: item.ConversationId,
      });
    }
    return {
      ...prev,
      conversation_id: item.ConversationId,
      sent_messages_audio: 0,
    };
  });
  setMessagesCounter(0);

  // AJAX call to S3 bucket (production url version)
  const url = `https://orbofi-conversations.s3.ap-southeast-1.amazonaws.com/${item.ConversationId}.json`;

  try {
    const response = await axios.get(url);

    const newChatHistory = response.data.flatMap(
      (conver: Conver) => [
        {
          text: conver.sender_message,
          sender: "user",
          profile: "/images/profile.png",
          audioUrl: "",
        },
        {
          text: conver.response_message,
          sender: "system",
          profile: null,
          audioUrl: "",
        },
      ]
    );

    setChatHistory(newChatHistory);
  } catch (error) {
    console.error(
      "Error fetching data from S3: ",
      error
    );
  }
}
*/
export function StripHtmlTags(str: string) {
  if ((str === null) || (str === ""))
    return false;
  else
    str = str.toString();
  return str.replace(/<[^>]*>/g, "");
}