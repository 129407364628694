import { Link } from "react-router-dom";
import Products from "../../../../Models/Products";
import { Dispatch, SetStateAction, useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { FaPlus, FaMinus } from 'react-icons/fa'; // Assuming you're using react-icons or similar

function PremiumProductBoxFaq() {

  const [activeKey, setActiveKey] = useState("0");

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };
  return (
    <>
      <div className="faqmain mt-3">
        <Row className="mb-5 ">
          <Col lg={4}>
            <div className="faqtittle mb-3">
              <span>Answers to</span>
              <h3>Danny AI <br />Companion</h3>
            </div>
          </Col>
          <Col lg={8}>

            <Accordion activeKey={activeKey} onSelect={(key) => handleToggle(key)}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What is Danny AI and what can it do for me?
                  <span className="icon">{activeKey === "0" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>
                  Danny AI is an AI companion powered by advanced AI models, voiced by Hollywood actor Danny Trejo. It can answer questions, generate images, create long-form text, write code, and even design websites.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Is the voice of Danny AI really Danny Trejo?
                  <span className="icon">{activeKey === "1" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>
                  Yes, Danny AI uses synthetic voices created in collaboration with Danny Trejo, providing an authentic experience that feels like interacting with the actor himself.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  How do I purchase and activate Danny AI?
                  <span className="icon">{activeKey === "2" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>
                  You can purchase Danny AI through our website. After purchase, you will receive a license and instructions to activate your AI companion.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  What is included in the subscription for Danny AI?
                  <span className="icon">{activeKey === "3" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>
                  The subscription includes a license to use Danny AI and a set amount of compute credits, sufficient for approximately two months of usage across various requests and prompts.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  How do compute credits work and how often do I need to refill them?
                  <span className="icon">{activeKey === "4" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>
                  Compute credits are used for processing your requests. Once your initial credits are depleted, you will need to purchase additional credits to continue using Danny AI, similar to replacing batteries in a toy.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Can I get a refund if I am not satisfied with Danny AI?
                  <span className="icon">{activeKey === "5" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>
                We do not operate refunds if you have used Danny AI and have consumed more than 10% of the compute credits we offer.  we offer refunds for if you have consumed less that than 10% of your orbox
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  Is my data safe with Danny AI?
                  <span className="icon">{activeKey === "6" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>
                  Yes, we prioritize your privacy and data security. All interactions with Danny AI are encrypted and stored securely.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  Can I customize the type of tasks Danny AI can perform?
                  <span className="icon">{activeKey === "7" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>
                  Danny AI is highly versatile and can be customized to focus on specific types of tasks, such as answering questions, generating creative content, or coding.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  How can I contact support if I have issues or questions?
                  <span className="icon">{activeKey === "8" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>
                  Our support team is available 24/7 via email or chat on our website. You can also access a detailed help center with guides and troubleshooting tips.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  What happens if I run out of compute credits and don’t refill them immediately?
                  <span className="icon">{activeKey === "9" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>
                  If you run out of compute credits, Danny AI will pause until you purchase additional credits. You can easily refill credits through your account on our website.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

          </Col>

        </Row>
        <Row className="">
          <Col lg={4}>
            <div className="faqtittle mb-3">
              <span>Answers to</span>

              <h3>Additional FAQ for <br /> Danny AI Companion</h3>
            </div>
          </Col>
          <Col lg={8}>

            <Accordion activeKey={activeKey} onSelect={(key) => handleToggle(key)}>
              <Accordion.Item eventKey="10">
                <Accordion.Header>How does Danny AI compare to other AI companions on the market?
                  <span className="icon">{activeKey === "10" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>

                  Danny AI offers a unique experience with the voice of Danny Trejo, backed by powerful AI models that provide diverse capabilities from general inquiries to specialized tasks like coding and website creation.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>Can I use Danny AI on multiple devices?
                  <span className="icon">{activeKey === "11" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>

                  Yes, once you have a license, you can use Danny AI across multiple devices, including smartphones, tablets, and computers.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>Are there any limitations to the types of questions or tasks Danny AI can handle?
                  <span className="icon">{activeKey === "12" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>

                  While Danny AI is highly capable, certain complex or highly specialized tasks may require more advanced models or additional compute credits.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header>What types of AI models power Danny AI?

                  <span className="icon">{activeKey === "13" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>
                  Danny AI is powered by a combination of premium and open-source AI models, ensuring a balance of high performance and versatility for various tasks.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="14">
                <Accordion.Header>Can I integrate Danny AI with other apps or services?
                  <span className="icon">{activeKey === "14" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>

                  Yes, Danny AI can be integrated with various apps and services through APIs, allowing seamless interaction with your existing digital ecosystem.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="15">
                <Accordion.Header>How often are updates and improvements made to Danny AI?
                  <span className="icon">{activeKey === "15" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>

                  We continuously update Danny AI with new features, improvements, and bug fixes. Major updates are rolled out periodically to enhance functionality and user experience.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="16">
                <Accordion.Header>Is there a trial period available for Danny AI?
                  <span className="icon">{activeKey === "16" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>

                Yes, we do offer 3 free requests per user as a free trial 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="17">
                <Accordion.Header>Can I choose different voices for my AI companion?

                  <span className="icon">{activeKey === "17" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>
                  While Danny AI is specifically voiced by Danny Trejo, our platform offers other AI companions with different synthetic voices. Check our catalog for more options.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="18">
                <Accordion.Header>How do I monitor my usage and compute credit balance?
                  <span className="icon">{activeKey === "18" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>

                  You can monitor your usage and compute credit balance through your account dashboard on our website. It provides detailed insights and notifications to help you manage your credits.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="19">
                <Accordion.Header>Can Danny AI learn and adapt to my preferences over time?
                  <span className="icon">{activeKey === "19" ? <FaMinus /> : <FaPlus />}</span>
                </Accordion.Header>
                <Accordion.Body>

                  Yes, Danny AI is designed to learn from interactions and adapt to your preferences, providing more personalized and accurate responses as you use it.
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>

          </Col>
        </Row>
      </div>
    </>
  );
}


export default PremiumProductBoxFaq;


