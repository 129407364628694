import {useState, useEffect} from "react";
import {Button, Form, Image} from "react-bootstrap";
import {t} from "i18next";
import axios from "axios";
import Select from "react-select";
import {Icon} from "@iconify/react";

function LanguagePopup(props: any) {
  const [selected, setSelected] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}languages`)
      .then((response) => {
        setOptions(response.data.map((language) => ({value: language.id, label: language.LanguageName})));
      })
      .catch((error) => {
        console.error("Error fetching languages:", error);
      });
  }, []);

  const onSelect = (selectedOption: any): void => {
    setSelected(selectedOption.value);
  };

  const onNextStep = () => {
    const config = {headers: {Authorization: `Bearer ${localStorage.getItem("oauthToken")}`}};

    axios.post(
      `${import.meta.env.VITE_SERVER_ADDRESS}setLanguage`,
      {language_id: selected},
      config).then(() => {
      props.nextStep(props);
    }).catch((error) => {
      console.error("Error setting language:", error);
    });
  };

  const onPrevStep = () => {
    props.previousStep(props);
  };

    return (
        <div className="LanguagePopup">
            <Button variant="" onClick={onPrevStep} className="btn-wizard-back"><Icon
                icon="eva:arrow-back-fill"/></Button>
            <div className="login-info">
                <span><Image src="/assets/icons/orbofi-icon-white.svg"/></span>
                <h5>{t('whats_your_language_and_where_do_you_live')}</h5>
                <p>{t('this_helps_us_find_you_more_relevant_content_we_wo')}</p>
            </div>
            <Form className="LanguagePopup-form">
                <div className="react-flag-select">
                    <Select
                        options={options}
                        className="react-select  reactcateselect"
                        value={options.find(option => option.value === selected)}
                        onChange={onSelect}
                        placeholder={t("select_a_language")}
                        isSearchable
                    />
                </div>
                <Button variant="" onClick={onNextStep} className="btn-primary btn-full">{t('continue')}</Button>
            </Form>
        </div>
    );
}

export default LanguagePopup;

