import { Icon } from "@iconify/react";
import { Button, Col, Dropdown, Form, Image, Row } from "react-bootstrap";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
    ActionMeta,
    default as OptionTypeBase,
    default as Select,
    default as ValueType,
} from "react-select";
import Subcategory from "../../Models/Subcategory";
import { showPopUpAction } from "../../Redux/Slices/loginPopUpSlice";
import UploadAndGenerateImage from "./UploadAndGenerateImage";
import { Link } from "react-router-dom";
import {
    GetUserCategories,
    classifyLinkType,
    readFileAsDataURL,
} from "../../Utils/ChatbotUtils";
import axios, { AxiosError } from 'axios';
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import { parse } from 'node-html-parser';
import { RUNPOD_BASE_URL } from "../../Utils/Utils";

interface CustomOptionType extends OptionTypeBase {
    label: string;
    value: string;
    icon: JSX.Element;
}

interface DocumentDetails {
    documentType?: string;
    fileURL?: string;
    isRAG?: boolean;
}

function CreateAICompanionStepTwoStep(props: any,
    { activeOption, handleOptionClick, setIsChatScreenAiAgentImgClicked }) {
    const dispatch = useDispatch();
    const [botCategory, setBotCategory] = useState(0);

    const isUserLogged = !!localStorage.getItem("oauthToken");
    const handleChange = (
        selectedOption: ValueType<CustomOptionType>,
        actionMeta: ActionMeta<CustomOptionType>,
    ) => {
        setBotCategory(selectedOption.value);
    };
    // State to hold the textarea value
    const [chatbotName, setChatbotName] = useState("");
    const [aiCharacterBuilder, setAiCharacterBuilder] = useState("");
    const [documentDetails, setDocumentDetails] = useState([]);
    const [selectedGeneratedImage, setSelectedGeneratedImage] = useState("");
    const [previewurl, setPreviewUrl] = React.useState('');

    const [image, setImage] = useState(() => {
        return localStorage.getItem("image") || props?.data?.image;
    });
    useEffect(() => {
        if (props?.data?.image) {
            const newImage = props.data.image;
            setImage(newImage);
            // localStorage.setItem("image", newImage);
        }
    }, [props?.data]);
    // Handle change in textarea
    const handleTextareaChange = (e) => {
        if (!isUserLogged) {
            dispatch(showPopUpAction(true));
            return;
        }
        setAiCharacterBuilder(e.target.value);
    };
    useEffect(() => {
        if (props?.data) {
            setChatbotName(props?.data?.chatbotName);
            setAiCharacterBuilder(props?.data?.aiCharacterBuilder);
            setSelectedGeneratedImage(props?.data?.data?.selectedGeneratedImage);
            setUploadedFiles(props?.data?.data?.documentDetails);
        }
    }, [props?.data]);

    // Handle change in textarea
    const handleChatbotName = (e) => {
        if (!isUserLogged) {
            dispatch(showPopUpAction(true));
            return;
        }
        setChatbotName(e.target.value);
    };

    const onNextStep = async () => {
        if (!isUserLogged) {
            dispatch(showPopUpAction(true));
            return;
        }

        for (const detail of documentDetails) {
            if (
                detail.documentType === "link" &&
                detail.isRAG &&
                !detail.fileURL.includes("youtube.com") &&
                !detail.fileURL.includes("github.com") &&
                !detail.fileURL.includes("wiki")
            ) {
                const { canScrape, message } = await checkLinkContent(detail.fileURL);
                if (!canScrape) {
                    toast.error(`Cannot process content from the link: ${detail.fileURL}. Reason: ${message}`);
                    return; // Stop the next step if any link does not have sufficient scrapeable content
                }
            }
        }

        props.setActiveStep(100);
        props.nextStep({
            aiCharacterBuilder: aiCharacterBuilder,
            chatbotName: chatbotName,
            documentDetails: documentDetails,
            botCategory: botCategory,
            privateChatbot: privateChatbot,
            image: props?.data?.image,
        });
    };
    const [subcategories, setCategories] = useState<Subcategory[]>([]);


    useEffect(() => {
        GetUserCategories().then((newCategories: Subcategory[]) => {
            setCategories(newCategories);
        });
    }, []);


    const handleLinkChange = (index, event) => {
        const newLink = event.target.value;
        const newLinks = [...links];
        newLinks[index] = newLink;
        setLinks(newLinks);

        // Ensure there are enough entries in documentDetails
        const newDocumentDetails = [...documentDetails];
        while (newDocumentDetails.length <= index) {
            newDocumentDetails.push({
                fileURL: "",
                documentType: "link",
                isRAG: false,
            });
        }

        // Classify the link type
        const documentType = classifyLinkType(newLink);

        // Update the document details at the specific index
        newDocumentDetails[index] = {
            fileURL: newLink,
            documentType,
            isRAG: true,
        };

        setDocumentDetails(newDocumentDetails);
    };

    const [links, setLinks] = useState([""]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileUploadloading, setFileUploadLoading] = useState(false);
    const [selectedModalOption, setSelectedModalOption] = useState('Llama3');
    const selectModalOption = (event: any) => {
        setSelectedModalOption(event.target.value)
    }

    function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
        const files = Array.from(e.target.files); // Convert FileList to an array
        setFileUploadLoading(true);
        files.length > 0 && setPreviewUrl(URL.createObjectURL(files[0]));

        Promise.all(
            files.map((file) => {
                const preview = URL.createObjectURL(file); // Create object URL for image preview
                const formData = new FormData();
                formData.append("file", file);
                formData.append("fileType", file.type);

                return fetch(`${import.meta.env.VITE_SERVER_ADDRESS}uploadFileToPinecone`, {
                    method: "POST",
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.fileURL) {
                            const fileExtension = file.type.split("/").pop();
                            const documentType = fileExtension.toUpperCase();
                            toast.success("Successfully uploaded your files");

                            return {
                                fileURL: data.fileURL,
                                documentType: documentType,
                                isRAG: true,
                                preview: preview, // Use the created preview URL
                                name: file.name,
                                id: Date.now(),
                            };
                        }
                        throw new Error("Upload failed");
                    });
            })
        )
            .then((newFiles) => {
                setUploadedFiles((prevFiles) => [...(prevFiles || []), ...newFiles]); // Safeguard in case prevFiles is null
                setDocumentDetails((prevDetails) => [...(prevDetails || []), ...newFiles]); // Safeguard in case prevDetails is null
                setFileUploadLoading(false);
            })
            .catch((error) => {
                
                toast.error("Failed to upload files.");
                setFileUploadLoading(false);
            });
    }

    const handleAddLink = () => {
        setLinks([...links, ""]);
    };

    const handleRemoveLink = (index) => {
        const newLinks = [...links];
        newLinks.splice(index, 1);
        setLinks(newLinks);
    };

    const [selectedOption, setSelectedOption] = useState(""); // Initially, link is selected
    const [privateChatbot, setPrivateChatbot] = useState("Private");
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handlePrivateChange = (e) => {
        setPrivateChatbot(e.target.value);
    };

    const toggleDropdown = (isOpen: boolean) => {
        setDropdownOpen(isOpen);
    };

    const handleDelete = (id) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    };

    // logic to flag URLS when the site is scrapeable
    const MIN_CONTENT_LENGTH = 5000;
  

    const [linkCheckLoading, setLinkCheckLoading] = useState(Boolean);


    const checkLinkContent = async (url: string): Promise<{ canScrape: boolean; message: string }> => {
        try {
            setLinkCheckLoading(true);

            const response = await axios.get(`${RUNPOD_BASE_URL}/api/check-link?url=${encodeURIComponent(url)}`, {
                timeout: 20000 // 20 seconds timeout
            });

           

            return response.data;
        } catch (error) {
            console.error(`Failed to check link content for ${url}:`, error);
            return { canScrape: false, message: `An error occurred while checking the link: ${error.message}` };
        } finally {
            setLinkCheckLoading(false);
        }
    };
    const onPreviousStep = () => {
        props.setActiveStep(0)
        props.previousStep(props);
    };

 
    return (
        <>
            <div className="bg-AiCompanion pb-2">
                <button onClick={() => onPreviousStep()} className="back-wizard-btn" type="button"><Icon
                    icon="eva:arrow-back-outline" style={{ color: "#fff" }} /></button>
                <Row className="justify-content-center align-items-center">
                    <Col lg={6} className="AiCompanion-None">
                        <div className="AiCompanion-List-Info">
                            <span>Step 2</span>
                            <h5>Add any specific knowledge, and chat with it </h5>
                            <p>Add files, PDF, spreadsheets, videos, links, blogs, and other formats that you want your AI companion to know. it could be private or public</p>
                        </div>
                    </Col>
                    <Col lg={6} className="AiCompanion-WIdth-Scroll AiCompanion-WIdth-Step-Two">
                        <div className="AiCompanion-WIdth">
                            <Form className="CreateAICompanion-Main-Form">
                                <Form.Group className="c-form-control c-form-control2">
                                    <Form.Label><lord-icon src="https://cdn.lordicon.com/qtfrvamv.json" trigger="loop" stroke="bold" colors="primary:#ffffff,secondary:#ffc738,tertiary:#4bb3fd,quaternary:#7c98ff"></lord-icon> Upload your knowledge base (Optional)</Form.Label>
                                    <div className="UploadBaseWrap">
                                        <label className="UploadBaseWrap-item" htmlFor="link">
                                            <input
                                                type="radio"
                                                name="linkfile"
                                                value="link"
                                                checked={selectedOption === "link"}
                                                onChange={handleOptionChange}
                                                id="link"
                                            />
                                            {/* <span className="checkmark"></span> */}
                                            <p>
                                                <i><lord-icon src="https://cdn.lordicon.com/cbigqefp.json" trigger="loop" stroke="bold" colors="primary:#ffffff,secondary:#4bb3fd"></lord-icon></i>
                                                <small>Add Link</small>
                                            </p>
                                        </label>
                                        <label className="UploadBaseWrap-item" htmlFor="file">
                                            <input
                                                type="radio"
                                                name="linkfile"
                                                value="file"
                                                checked={selectedOption === "file"}
                                                onChange={handleOptionChange}
                                                id="file"
                                            />
                                            {/* <span className="checkmark"></span> */}
                                            <p>
                                                <i><lord-icon src="https://cdn.lordicon.com/lmkvlxgc.json" trigger="loop" stroke="bold" colors="primary:#ffffff,secondary:#4445C7,tertiary:#4bb3fd"></lord-icon></i>
                                                <small>Upload File</small>
                                            </p>
                                        </label>
                                    </div>
                                </Form.Group>

                                {selectedOption === "link" && (
                                    <>
                                        {links.map((link, index) => (
                                            <Form.Group key={index} className="c-form-control c-form-control2 mb-2 c-form-Linkss">
                                                <div className="d-flex">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Link"
                                                        value={link}
                                                        onChange={(e) => handleLinkChange(index, e)}
                                                    />
                                                    <div className="button-group">
                                                        {links.length > 1 && (
                                                            <button
                                                                type="button"
                                                                onClick={() => handleRemoveLink(index)}
                                                                className="btn-remove"
                                                            >
                                                                <Icon icon="fluent:delete-20-filled" />
                                                            </button>
                                                        )}
                                                        {index === links.length - 1 && (
                                                            <button
                                                                type="button"
                                                                onClick={handleAddLink}
                                                                className="btn-add"
                                                            >
                                                                <Icon icon="ic:round-add" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        ))}
                                    </>
                                )}

                                {selectedOption === "file" && (
                                    <Form.Group className="c-form-control mb-3">
                                        <label htmlFor="uploadVoice" className="UploadVoicBtn-Main">
                                            <input
                                                type="file"
                                                name="uploadVoice"
                                                id="uploadVoice"
                                                onChange={handleFileUpload}
                                                accept=".pdf,.csv,.jpg,.png,.svg"
                                                multiple
                                            />
                                            <div className="UploadVoiceBox">
                                                <span>
                                                    <Icon icon="solar:upload-bold" />
                                                </span>
                                                <h6>
                                                    Click to upload a file or drag and drop <small>PDF files, images,</small>
                                                </h6>
                                            </div>
                                        </label>
                                        {fileUploadloading ? (
                                            <div style={{ margin: "0 auto" }}>
                                                <div className="AiImageLoader">
                                                    <div>
                                                        <div className="nb-spinner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : uploadedFiles?.length > 0 ? (
                                            <div>
                                                <ul className="uploaded-files">
                                                    {uploadedFiles.map((file, index) => (
                                                        <li key={index}>
                                                            <div className="uploadedfiledetail">
                                                                {["SVG+XML", "JPEG", "PNG"].includes(file.documentType) ? (
                                                                    <Image
                                                                        src={file.fileURL}
                                                                        alt={file.name}
                                                                        style={{ width: "100px", height: "100px" }}
                                                                    />
                                                                ) : file.documentType === "PDF" ? (
                                                                    <div style={{ width: "100px", height: "100px" }}>
                                                                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                                                            <Viewer fileUrl={file.preview} />
                                                                        </Worker>
                                                                    </div>
                                                                ) : null}
                                                                <Link
                                                                    to="javascript:void(0)"
                                                                    className="imagedelete"
                                                                    onClick={() => handleDelete(file.id)}
                                                                >
                                                                    <lord-icon
                                                                        src="https://cdn.lordicon.com/skkahier.json"
                                                                        trigger="loop"
                                                                        colors="primary:#ffffff"
                                                                    ></lord-icon>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                )}


                                <Form.Group className="c-form-control c-form-control2">
                                    <Form.Label><lord-icon src="https://cdn.lordicon.com/fmjvulyw.json" trigger="loop"></lord-icon> Visbility</Form.Label>
                                    <div className="VisbilityWrap">
                                        <label htmlFor="Private" className="VisbilityItem">
                                            <input type="radio" name="Visbility" value="Private" id="Private" checked={privateChatbot === "Private"} onChange={handlePrivateChange} />
                                            <div>
                                                <i><lord-icon src="https://cdn.lordicon.com/fefnlrel.json" trigger="loop" stroke="bold" colors="primary:#ffffff,secondary:#ffc738,tertiary:#b4b4b4"></lord-icon></i>
                                                <p>Private</p>
                                                <small>Available only to you and those with the link</small>
                                            </div>
                                        </label>
                                        <label htmlFor="Public" className="VisbilityItem">
                                            <input type="radio" name="Visbility" value="Public" id="Public" checked={privateChatbot === "Public"} onChange={handlePrivateChange} />
                                            <div>
                                                <i><lord-icon src="https://cdn.lordicon.com/fefnlrel.json" trigger="loop" stroke="bold" colors="primary:#ffffff,secondary:#ffc738,tertiary:#b4b4b4"></lord-icon></i>
                                                <p>Public</p>
                                                <small>Available to anyone
                                                    to use</small>
                                            </div>
                                        </label>
                                    </div>
                                </Form.Group>
                                <div className={`category-list-main-div ${privateChatbot === "Public" ? "show-categories" : "hide-categories"}`}>
                                    <Form.Group className="c-form-control c-form-control2">
                                        <Form.Label><lord-icon src="https://cdn.lordicon.com/wacyfihi.json" trigger="loop"></lord-icon>Visbility</Form.Label>

                                        <ul className="category-list-main">
                                            {subcategories.map((subcategory) => (
                                                <li
                                                    key={subcategory.CategoryId}
                                                    onClick={() => handleChange({ label: subcategory.Name, value: subcategory.CategoryId })}
                                                    className="category-item-list"
                                                >
                                                    <label htmlFor={subcategory.Name}>
                                                        <input type="radio" name="category" id={subcategory.Name} />
                                                        <p><i><Icon icon="material-symbols:star" /></i> {subcategory.Name}</p>

                                                    </label>

                                                </li>
                                            ))}
                                        </ul>
                                    </Form.Group>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
            {linkCheckLoading ? (
                <div style={{ margin: "0 auto" }}>
                    {" "}
                    <div className="AiImageLoader">
                        <div>
                            <div className="nb-spinner"></div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="text-end">
                    <Button variant="" className="AiCompanion-btn btn-primary px-4 py-2 mx-auto" onClick={onNextStep}>{t("next_step")}<i><Icon icon="heroicons:sparkles-solid" /></i></Button>
                </div>
            )}
        </>
    );
}

export default CreateAICompanionStepTwoStep;