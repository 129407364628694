import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CommonBtn.css";

interface ComponentProps {
  child: React.ReactNode,
  className: string,
  linkName: string,
  onclick: string,
}

function PinkBtn(props: ComponentProps) {
  return (
    <div className={`btnImgwrap ${props.className}`}>
      <span><Image src="/assets/images/PinkBtn-after.svg" /></span>
      <Link className="btn-PinkBtn" to={props.linkName} onClick={props.onclick}>{props.child}</Link>
      <span><Image src="/assets/images/PinkBtn-before.svg" /></span>
    </div>
  );
}

export default PinkBtn;
