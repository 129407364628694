import { Col } from "react-bootstrap";
import CompanionsBox from "../../Components/CompanionsBox/CompanionsBox";
import { ChatbotData } from "../../Utils/ChatbotUtils";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useState } from "react";

function ChatbotMainItem({ chatbots, loadMore, records, hasMore }: { chatbots: ChatbotData[], loadMore: () => void, records: number, hasMore: boolean }) {
  const [showData, setShowData] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowData(true);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [records]);

  return (

    <InfiniteScroll
      dataLength={records}
      className="row"
      next={() => loadMore()}
      hasMore={hasMore}
      loader={
        <div className="AiImageLoader">
          <div>
            <div className="nb-spinner"></div>
          </div>
        </div>
      }
    >
      {chatbots.length > 0 ? (
        chatbots.map((chatbot, index) => (
          <Col lg={2} key={index}>
            <CompanionsBox chatbotData={chatbot} />
          </Col>
        ))
      ) : (
        <div className="text-center"></div>
      )}
    </InfiniteScroll>

  );
}

export default ChatbotMainItem;