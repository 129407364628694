// onboardingSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OnboardingState {
    open: boolean;
}

const initialState: OnboardingState = {
    open: false,
};

const onboardingSlice = createSlice({
    name: "onboarding",
    initialState,
    reducers: {
        openOnboarding(state) {
            state.open = true;
        },
        closeOnboarding(state) {
            state.open = false;
        },
    },
});

export const { openOnboarding, closeOnboarding } = onboardingSlice.actions;
export default onboardingSlice.reducer;