import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Image, Modal, Dropdown, Button } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import FCompanionsInfoMoreSetting from "./FCompanionsInfoMoreSetting";
import { t } from "i18next";
import { updateUserData, userDataSelector } from "../../Redux/Slices/userDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { ProfilePicture } from "../../Components/ProfilePicture/ProfilePicture";
import {
  ChatbotData,
  CheckIfUserPurchasedPremiumBot,
  GetChatbotVoiceByUrl,
  GetChatbotVoices, IsChatbotFavourite, ToggleFavouriteChatbot
} from "../../Utils/ChatbotUtils";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import IntroJs from "intro.js";
import "intro.js/introjs.css";
import axios from "axios";
import { dirtyFlagsSelector, updateDirtyFlags } from "../../Redux/Slices/dirtyFlagsSlice";
import { toast } from "react-hot-toast";
import { showPopUpAction } from "../../Redux/Slices/loginPopUpSlice";
import PrimaryBtn from "../../Components/CommonBtn/PrimaryBtn";
import { Logout } from "../../Utils/Utils";
import User from "../../Models/User";
import UserChatbot from "../../Models/UserChatbot";
import { VoiceOption } from "../../Utils/Types";
import { type } from "node:os";
import PopupModal from "../../Components/PopUps/PopUpBase";
import PricingTabs from "../PricingPage/Components/PricingTabs/PricingTabs";
import PricingPopup from "../PricingPage/PricingPopup";
import Products from "../../Models/Products";
interface ComponentProps {
  isChatScreenAiAgentImgClicked: number
  img_url: string | null
  name: string | undefined
  setOpenShare: (val: boolean) => void
  setIsDropdownOpen: (val: boolean) => void
  onClickChatScreenAiAgentImg: (val: number) => void
  chatbotData: ChatbotData | UserChatbot
  setOpenSetting: (val: boolean) => void
  resetChat: () => void
  setSelectedModelOption: any
  selectedModelOption: any
  modelOptions: any
  selectedLanguageOption: any
  setSelectedLanguageOption: any
  isResetDisable: any
}
function onSidebarToggle() {
  const bodyElement = document.querySelector("body");
  if (bodyElement) {
    bodyElement.classList.toggle("SidebarToggle");
  }
}
function ChatScreenHeader(props: ComponentProps): JSX.Element {
  const [show, setShow] = useState(false);
  const userData = useSelector(userDataSelector);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [introCompleted, setIntroCompleted] = useState<boolean>(localStorage.getItem("introBots") == "completed"); // Track if the intro.js tour has been completed
  const [initialVoice, setInitialVoice] = useState<string>("");
  const [initialVoiceLoaded, setInitialVoiceLoaded] = useState<boolean>(false);
  const dirtyFlags = useSelector(dirtyFlagsSelector);

  useEffect(() => {
    if (!props?.chatbotData?.audio_url) return;
    GetChatbotVoiceByUrl(props?.chatbotData?.audio_url).then((voice) => { setInitialVoice(voice.Name); setInitialVoiceLoaded(true);});
  }, [props?.chatbotData, dirtyFlags.BotDataLoaded]);

  useEffect(() => {
    if ((!localStorage.getItem("introBots") == "completed")) {
      const intro = IntroJs();
      intro.setOptions({
        steps: [
          {
            title: "<img src=\"/images/logo.png\"></img>",
            element: "#step1",
            intro: "Ask and Chat ",
          },
          {
            title: "<img src=\"/images/logo.png\"></img>",
            element: "#step2",
            intro: "Image Generation",
          },
          {
            title: "<img src=\"/images/logo.png\"></img>",
            element: "#step3",
            intro: "Website Generation",
          },
          {
            title: "<img src=\"/images/logo.png\"></img>",
            element: "#step4",
            intro: "Code Generation",
          },
          {
            title: "<img src=\"/images/logo.png\"></img>",
            element: "#step5",
            intro: "GPT 4",
          },
          {
            title: "<img src=\"/images/logo.png\"></img>",
            element: "#step6",
            intro: "Long-form Text",
          },
          {
            title: "<img src=\"/images/logo.png\"></img>",
            element: "#step7",
            intro: "Vision Power",
          },
          {
            title: "<img src=\"/images/logo.png\"></img>",
            element: "#step8",
            intro: "Default Layout",
          },
          {
            title: "<img src=\"/images/logo.png\"></img>",
            element: "#step9",
            intro: "Chatbot Layout",
          },
          {
            title: "<img src=\"/images/logo.png\"></img>",
            element: "#step10",
            intro: "Studio Layout",
          },
          {
            title: "<img src=\"/images/logo.png\"></img>",
            element: "#step11",
            intro: "Live Action",
          },
          {
            title: "<img src=\"/images/logo.png\"></img>",
            element: "#step12",
            intro: "Chat History",
          },

        ],
        exitOnOverlayClick: false,
        exitOnEsc: false,
        showStepNumbers: false,
        doneLabel: "Finish",
        showProgress: true,
        overlayOpacity: 0.5,
        tooltipClass: "custom-tooltip",
        scrollToElement: true,
        highlightClass: "highlight-step",
      });

      intro.start();
    }
  }, [introCompleted]);

  interface favChatbot {
    ChatbotId: number;
    Origin: number;
  }
  const [isActive, setIsActive] = useState(false);
  function toggleFavourite() {
    SetIsToggling(true);
    ToggleFavouriteChatbot(props.chatbotData).then((isFav) => {
      setIsActive(isFav);
      dispatch(updateDirtyFlags({FavouriteChatbotsDirty: true}));
      SetIsToggling(false);
    });
  }

  useEffect(() => {
    if (dirtyFlags.HaveFavouriteBotsLoaded) {
      console.log("ChatbotData", props.chatbotData);
      if(props.chatbotData === undefined) return;
      setIsActive(IsChatbotFavourite(props.chatbotData?.user_id && props.chatbotData?.user_id > 0 ? props.chatbotData?.chatbot_id : props.chatbotData?.id));
    }
  }, [dirtyFlags.HaveFavouriteBotsLoaded,  props.chatbotData?.user_id, props.chatbotData?.chatbot_id, props.chatbotData?.id]);

  const navigate = useNavigate();

  const handleGoBack = () => {
    console.log("click back");
    navigate(-1);
  };

  function OnOpenLoginPopup() {
    dispatch(showPopUpAction(true));
  }

  const [showModal, setShowModal] = useState(false);
  const [showPaymentScreen, setShowPaymentScreen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(new Products());
  const [selectedImage, setSelectedImage] = useState(null); // State to track the selected image for the modal

  const openModal = (image) => {
    setSelectedImage(image); // Set the selected image when user clicks on it
  };

  const closeModalimg = () => {
    setSelectedImage(null); // Close the modal by resetting selected image
  };
  const [showPreview, setShowPreview] = useState(false);
  const togglePreview = () => {
    setShowPreview(!showPreview);
  };
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleButtonClick = () => {
    setPopupVisible(true);
    setTimeout(() => {
      setPopupVisible(false);
    }, 2000); // 2000 milliseconds = 2 seconds
  };

  const [isToggling, SetIsToggling] = useState(false);
  const [canTalkWithPremiumBot, setCanTalkWithPremiumBot] = useState(false);
  const [openPremiumBotPopup, setOpenPremiumBotPopup] = useState(false);

  useEffect(() => {
    if (props?.chatbotData?.source === 4) {
      CheckIfUserPurchasedPremiumBot(props?.chatbotData?.chatbot_id).then((response: boolean) => { setCanTalkWithPremiumBot(response); });
    }
  }, [props?.chatbotData]);

  function CheckIfUserCanCall(): boolean {
    if (!localStorage.getItem("oauthToken")) {
      dispatch(showPopUpAction(true));
      return false;
    }

    if (props?.chatbotData?.source === 4 && !canTalkWithPremiumBot) {
      setOpenPremiumBotPopup(true);
      return false;
    }
    return true;
  }
  const setReturnData = () => {
    localStorage.setItem("premiumChatbotReturnName", props?.chatbotData?.string_id);
  };
  return (
    <>
      <div className='ChatScreenHeaderWrap2 ChatHeaderDesktop'>
        <div className='ChatScreenHeaderuserWrap'>

          <Link to="#" className="chatBackbtn" onClick={handleGoBack}><Icon icon="ic:round-arrow-back-ios" /></Link>

          <Dropdown className='ChatScreenHeaderDropDown' onToggle={(val: boolean) => props.setIsDropdownOpen(val)}>
            <h6 className='ChatScreenHeaderuser'><i><Image src={props.chatbotData?.img} /></i>{props.chatbotData?.name} <span className={props.isResetDisable ? "" : ''} onClick={() => {
              if(!props.isResetDisable) {
                props.resetChat()
              }
              }}><Icon icon="ep:refresh" /></span></h6>
          </Dropdown>
          <Button variant="" className='AIScreenSetting' onClick={handleShow}><Icon icon="carbon:settings" /> {t("show_settings")}</Button>
        </div>
        <ul className="ChatScreenOptionSetting">
          <li><Button id='step8' variant="" className={props.isChatScreenAiAgentImgClicked === 0 ? "active" : ""} onClick={() => props.onClickChatScreenAiAgentImg(0)}>
            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1085_34979)">
                <path d="M11 1H2.99999C1.89543 1 1 1.33119 1 1.73975V12.2603C1 12.6688 1.89543 13 2.99999 13H11C12.1046 13 13 12.6688 13 12.2603V1.73975C13 1.33119 12.1046 1 11 1Z" stroke="#828284" strokeWidth="0.98718" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18.4234 0.999298H15.4235C15.0094 0.999298 14.6736 1.33509 14.6736 1.74931V12.4158C14.6736 12.83 15.0094 13.1658 15.4235 13.1658H18.4234C18.8376 13.1658 19.1734 12.83 19.1734 12.4158V1.74931C19.1734 1.33509 18.8376 0.999298 18.4234 0.999298Z" stroke="#828284" strokeWidth="0.98718" strokeLinecap="round" strokeLinejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_1085_34979">
                  <rect width="20" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Button></li>
          <li><Button id='step9' variant="" className={props.isChatScreenAiAgentImgClicked === 1 ? "active" : ""} onClick={() => props.onClickChatScreenAiAgentImg(1)}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.142 8.69757C11.3712 8.46836 11.5 8.15749 11.5 7.83333V1.72222C11.5 1.39807 11.3712 1.08719 11.142 0.857981C10.9128 0.628769 10.6019 0.5 10.2778 0.5H1.72222C1.39807 0.5 1.08719 0.628769 0.857981 0.857981C0.628769 1.08719 0.5 1.39807 0.5 1.72222V11.5L2.94444 9.05556H10.2778C10.6019 9.05556 10.9128 8.92679 11.142 8.69757Z" strokeWidth="0.98718" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Button></li>
          <li><Button id='step10' variant="" className={props.isChatScreenAiAgentImgClicked === 2 ? "active" : ""} onClick={() => props.onClickChatScreenAiAgentImg(2)}>
            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1078_34778)">
                <path d="M9.00002 1H17C18.1046 1 19 1.33119 19 1.73975V12.2603C19 12.6688 18.1046 13 17 13H9.00002C7.89543 13 7 12.6688 7 12.2603V1.73975C7 1.33119 7.89543 1 9.00002 1Z" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1.57685 0.999298H4.5767C4.99089 0.999298 5.32666 1.33509 5.32666 1.74931V12.4158C5.32666 12.83 4.99089 13.1658 4.5767 13.1658H1.57685C1.16265 13.1658 0.826851 12.83 0.826851 12.4158V1.74931C0.826851 1.33509 1.16265 0.999298 1.57685 0.999298Z" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
              </g>

            </svg>
          </Button></li>
          <li><Button id='step11' variant="" className={props.isChatScreenAiAgentImgClicked === 3 ? "active" : ""} onClick={() => { if (CheckIfUserCanCall()) { props.onClickChatScreenAiAgentImg(3); } }}>
            <Icon icon="material-symbols:call" />
          </Button></li>
          <li><Button id='step12' variant="" className={props.isChatScreenAiAgentImgClicked === 4 ? "active" : ""} onClick={() => props.onClickChatScreenAiAgentImg(4)}>
            <Icon icon="iconamoon:history-light" />
          </Button></li>
        </ul>
        <ul className="ChatScreenHeaderOption">
          <li> <Button
            variant=""
            className={`FavoriteLink ${isActive ? "active" : ""}`}
            onClick={toggleFavourite}
            disabled={isToggling}
          >
            {t("favorite")} <Icon icon={isActive ? "mingcute:star-fill" : "mingcute:star-line"} />
          </Button></li>

          {localStorage.getItem("oauthToken") ?
            <li><Link className=' Header-spark-btn' to="javascript:void(0)" onClick={() => props.setOpenShare(true)}>{t("share")} <Icon icon="tabler:share" /></Link></li>
            : null}
          <li><Link to="/pricing" className='orboxChat Header-spark-btn'><i className="text-blue-shadow"><Icon icon="ph:plus-fill" /> {userData.Orbox + userData.PremiumOrbox} </i><span>  <Image src="/images/logo-coin.svg" className="orbofiicon" /></span></Link></li>
          <Dropdown className="profile-dropdown">
            <Dropdown.Toggle variant="" id="dropdown-basic">
              <div className="chatheaderprofile">
                {localStorage.getItem("oauthToken") ?
                  <ProfileDropdown />
                  :
                  <span onClick={OnOpenLoginPopup}>
                    <Icon icon="fa:user-circle" width="16px" height="16px" />
                  </span>
                }
              </div>
            </Dropdown.Toggle>
          </Dropdown>
        </ul>
      </div>
      <div className="ChatScreenHeaderWrap2 ChatHeaderMobile d-none">
        <div className="MobileChatLeft">
          <Button variant="" className="chat-menu-icon" onClick={onSidebarToggle}><Icon icon="ic:round-menu" /></Button>
          <Link className="chatBackbtn" to="#" onClick={handleGoBack}><Icon icon="ic:round-arrow-back-ios" /></Link>

          <Button variant="" className={props.isChatScreenAiAgentImgClicked === 3 ? "AIScreenSetting" : "AIScreenSetting"} onClick={() => props.onClickChatScreenAiAgentImg(3)}>
            <Icon icon="material-symbols:call" />
          </Button>
          <Button id='step11' variant="" className={props.isChatScreenAiAgentImgClicked === 4 ? "AIScreenSetting" : "AIScreenSetting"} onClick={() => props.onClickChatScreenAiAgentImg(4)}>
            <Icon icon="iconamoon:history-light" />
          </Button>
        </div>
        <div className="MobileChatProfileMain">
          {props.isChatScreenAiAgentImgClicked !== 3 ? <div className="MobileChatProfileImg">
            <span><i><Image src={props.chatbotData?.img} onClick={() => openModal(props.chatbotData?.img)} /></i></span>
          </div> : ""}
          <h6 className='ChatScreenHeaderuser'><small>{props.chatbotData?.name}</small>
            <Button variant="" className={`FavoriteMobileLink FavoriteLink p-0 ${isActive ? "active" : ""}`} onClick={()=>{ToggleFavouriteChatbot(props.chatbotData).then((isFav)=>{setIsActive(isFav); dispatch(updateDirtyFlags({ FavouriteChatbotsDirty: true }));} );}}><Icon icon={isActive ? "mingcute:star-fill" : "mingcute:star-line"} /></Button>
          </h6>
        </div>
        <div className="ChatHeaderRight d-flex align-items-center">
          {/* <Link to="javascript:void(0)" className='orboxChat MobileorboxChat'><span onClick={props.resetChat}><Icon icon="ep:refresh" style={{color: "white"}} /></span></Link> */}
          <Link to="javascript:void(0)" onClick={() => setShowModal(true)} className='orboxChat MobileorboxChat'><i className="text-blue-shadow"><Icon icon="ph:plus-fill" /> {userData.Orbox + userData.PremiumOrbox} </i><span><Image src="/images/logo-coin.svg" /></span></Link>
          <Button variant="" className='AIScreenSetting' onClick={handleShow}><Icon icon="carbon:settings" /></Button>
        </div>
      </div>
      <div className="ChatHeaderMobile ChatHeaderMobile2">
        <div className="MobileChatLeft">
          <Button variant="" className="chat-menu-icon" onClick={onSidebarToggle}><Icon icon="ic:round-menu" /></Button>
          <Link className="chatBackbtn" to="#" onClick={handleGoBack}><Icon icon="ic:round-arrow-back-ios" /></Link>
        </div>
        <div className="MobileChatProfileMain MobileChatProfileMain2">
          {props.isChatScreenAiAgentImgClicked !== 3 ? <div className="MobileChatProfileImg">
            <span><i><Image src={props.chatbotData?.img} onClick={() => openModal(props.chatbotData?.img)} /></i></span>
          </div> : ""}
          <h6 className='ChatScreenHeaderuser'><small>{props.chatbotData?.name}</small>
            <div className="callandHistoryBtns">
              <Button variant="" className={props.isChatScreenAiAgentImgClicked === 3 ? "AIScreenSetting AIScreenCall" : "AIScreenSetting"} onClick={() => props.onClickChatScreenAiAgentImg(3)}>
                {/* <lord-icon
                  src="https://cdn.lordicon.com/srsgifqc.json" colors="primary:#ffffff"
                  trigger="loop">
              </lord-icon> */}
              <Icon icon="mage:phone-call-fill" />
              </Button>
              <Button  variant="" className={props.isChatScreenAiAgentImgClicked === 0 ? "AIScreenSetting AIScreenMessage" : "AIScreenSetting"} onClick={() => props.onClickChatScreenAiAgentImg(0)}>
                <Icon icon="tabler:message-filled" />
              </Button>
              <Button id='step11' variant="" className={props.isChatScreenAiAgentImgClicked === 4 ? "AIScreenSetting AIScreenMessage" : "AIScreenSetting"} onClick={() => props.onClickChatScreenAiAgentImg(4)}>
                <Icon icon="ic:round-history" />
              </Button>
            </div>
          </h6>
        </div>
        <div className="ChatHeaderRight d-flex align-items-center">
          {/* <Link to="javascript:void(0)" className='orboxChat MobileorboxChat'><span onClick={props.resetChat}><Icon icon="ep:refresh" style={{color: "white"}} /></span></Link> */}
          <Link to="javascript:void(0)" onClick={() => setShowModal(true)} className='orboxChat MobileorboxChat'><span><Image src="/images/logo-coin.svg" /></span><i className="text-blue-shadow"><Icon icon="ph:plus-fill" className="d-none" /> {userData.Orbox + userData.PremiumOrbox} </i></Link>
          <Button variant="" className={`FavoriteMobileLink FavoriteLink p-0 m-0 ${isActive ? "active" : ""}`} onClick={()=>{ToggleFavouriteChatbot(props.chatbotData).then((isFav)=>{setIsActive(isFav); dispatch(updateDirtyFlags({ FavouriteChatbotsDirty: true }));} );}}><Icon icon={isActive ? "mingcute:star-fill" : "mingcute:star-line"} /></Button>
          <Button variant="" className='AIScreenSetting' onClick={handleShow}><Icon icon="carbon:settings" /></Button>
        </div>
      </div>
      <Modal show={openPremiumBotPopup} backdrop="static" keyboard={false} onHide={() => { setOpenPremiumBotPopup(false); }} className="login-popup default-popup chatmodal" centered>
        <Modal.Header className="default-modal-header">
          <Button variant="" onClick={() => { setOpenPremiumBotPopup(false); }}><Icon icon="ion:close-circle-outline" /></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="UpgradeCreateMoreAIBg-Info chataimodal OpenPremiumBotPopup">
            <Image src={props?.chatbotData?.img} />
            <i><Icon icon="heroicons:sparkles-solid" /></i>
            <h5>Get Your Premium AI assistant</h5>
            <ul className="DannyAIPopuplist">
              <li>
                <i><Icon icon="fluent:person-voice-24-filled" /></i>
                <p>One of the most powerful AI assistants, with Danny Trejo’s personality and voice</p>
              </li>
              <li>
                <i><Icon icon="fluent:premium-person-24-regular" /></i>
                <p>Get answers for anything and generate anything. Powered by Premium AI models, like GPT 4-o, and Claude 3.5</p>
              </li>
              <li>
                <i><Icon icon="ri:chat-voice-fill" /></i>
                <p>Voice-powered, and real-time AI generation and interaction with Danny Trejo AI</p>
              </li>
            </ul>
            <PrimaryBtn child={<><i className="mb-0"><lord-icon src="https://cdn.lordicon.com/colzhtfe.json" colors="primary:#ffffff,secondary:#ffffff" trigger="loop"></lord-icon></i>{t("upgrade_your_account")} </>} className={"BtnPremiumPopup"} linkName={"/pricing?premium=true"} onClick={setReturnData} />
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} centered size="lg" className='default-modal parametermodal'>
        <div className='default-modal-header'>
          <h2>{t("choose_parameters")}</h2>
          <Button variant="" onClick={handleClose}><Icon icon="ion:close-circle-outline" /></Button>
        </div>
        <Modal.Body>
          {initialVoiceLoaded && (
            <FCompanionsInfoMoreSetting
              chatbotData={props.chatbotData}
              selectedModelOption={props.selectedModelOption}
              setSelectedModelOption={props.setSelectedModelOption}
              modelOptions={props.modelOptions}
              initialVoiceParam={initialVoice}
              setSelectedLanguageOption={props.setSelectedLanguageOption}
              selectedLanguageOption={props.selectedLanguageOption}
            />
          )}        </Modal.Body>
      </Modal>
      <Modal show={!!selectedImage} onHide={closeModalimg} className="imageviewmodal" centered>
        <Modal.Body>
          <img
            src={selectedImage}
            alt="Selected Image"
            style={{ maxWidth: "100%", height: "auto" }}
          />
          <Link to="javascript:void(0)" className="CloseBtn" onClick={closeModalimg}>
            <Icon icon="solar:close-circle-linear" />
          </Link>
        </Modal.Body>

      </Modal>
      {
        showPreview && (
          <div className="modal profileview" onClick={togglePreview}>
            <div className="modal-content">
              <img src={props.chatbotData?.img} alt="Chatbot Preview" />
            </div>
          </div>
        )
      }
      {localStorage.getItem("oauthToken") ? (
        <>
          <PopupModal
            show={showModal}
            handleClose={() => { setShowModal(false); }}
            className={`default-popup login-popup  ${!showPaymentScreen ? "PricingPopupMain" : "PricingPaymentPopup"
            }`}
            backdrop={undefined}
          >
            {!showPaymentScreen ? (
              <Link
                to="javascript:void(0)"
                className="CloseBtn"
                onClick={() => { setShowModal(false); }}
              >
                <Icon icon="solar:close-circle-linear" />
              </Link>
            ) : (
              ""
            )}
            {!showPaymentScreen ? (
              <PricingTabs
                isFaq={false}
                onPaymentToggle={() => setShowPaymentScreen(true)}
                setCurrentProduct={setCurrentProduct}
                isPopup={true}
              />
            ) : (
              <PricingPopup
                onPaymentToggle={() => setShowPaymentScreen(false)}
                product={currentProduct}
              />
            )}
          </PopupModal>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default ChatScreenHeader;


function ProfileDropdown(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(userDataSelector);

  function OnHandleLogout() {
    Logout();
    const userData = new User();
    dispatch(updateUserData(userData));
    navigate("/");
  }


  return (
    <Dropdown className="profile-dropdown">
      <Dropdown.Toggle variant="" id="dropdown-basic">
        <div className="Header-profile-main">
          <span>
            <ProfilePicture />
          </span>
          <h6>{userData.Name} <i><Icon icon="ep:arrow-down-bold" /></i></h6>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="profile-dropdown-main">
        <div className="dropdown-bg">
          <ul className="profile-dropdown-list">
            <li className="pb-3">
              <ConnectButton label={t("connect_wallet")} />
            </li>
            <li>
              <Link to={"/profile/" + userData.Name}><Icon icon="ep:user" />{t("my_profile")}</Link>
            </li>
            <li>
              <Link to={"/profile/" + userData.Name}><Icon icon="carbon:user-profile" />{t("my_agents")}</Link>
            </li>
            <li>
              <Link to="javascript:void(0)" onClick={() => props.setOpenSetting(true)}><Icon icon="lets-icons:setting-line-light" />{t("settings")}</Link>
            </li>
            <li>
              <Link to="javascript:void(0)" onClick={OnHandleLogout}><Icon icon="mdi-light:logout" />{t("logout")}</Link>
            </li>
          </ul>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}


