class Products {
  id: number;
  Name: string;
  Description: string;
  Category: string;
  SubCategory: string;
  Price: number;
  Discount: number;
  StripeId: string;
  StripeRecurrentId: string;
  TestStripeId: string;
  TestStripeRecurrentId: string;
  Level: number;
  ProductId: number;
  OrboxPrice: number;
  OrboxRewarded: number;
  OrboxPremiumRewarded: number;
  MinSubLevel: number;
  Uses: number;
  FeaturesArray: string[];
  PublicSale: boolean;

  constructor(
    id: number = -1,
    name: string = "",
    description: string = "",
    category: string = "",
    subCategory: string = "",
    price: number = -1,
    discount: number = 0,
    stripeId: string = "",
    stripeRecurrentId: string = "",
    testStripeId: string = "",
    testStripeRecurrentId: string = "",
    level: number = -1,
    productId: number = -1,
    orboxPrice: number = -1,
    orboxRewarded: number = -1,
    orboxPremiumRewarded: number = -1,
    minSubLevel: number = -1,
    uses: number = 0,
    featuresArray: string[] = [],
    publicSale: boolean = false
  ) {
    this.id = id;
    this.Name = name;
    this.Description = description;
    this.Category = category;
    this.SubCategory = subCategory;
    this.Price = price;
    this.Discount = discount;
    this.StripeId = stripeId;
    this.StripeRecurrentId = stripeRecurrentId;
    this.TestStripeId = testStripeId;
    this.TestStripeRecurrentId = testStripeRecurrentId;
    this.Level = level;
    this.ProductId = productId;
    this.OrboxPrice = orboxPrice;
    this.OrboxRewarded = orboxRewarded;
    this.OrboxPremiumRewarded = orboxPremiumRewarded;
    this.MinSubLevel = minSubLevel;
    this.PublicSale = publicSale;
    this.Uses = uses;
    this.FeaturesArray = featuresArray;
  }
}

export default Products;


