import { Icon } from "@iconify/react";
import PrimaryBtn from "../../CommonBtn/PrimaryBtn";
import "./ExploreBanner.css";
import { Image } from "react-bootstrap";
import { t } from "i18next";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import { useEffect, useState } from "react";
import { ChatbotSearchData, GetSemanticSearchList } from "../../../Utils/ChatbotUtils";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { showPopUpAction } from "../../../Redux/Slices/loginPopUpSlice";
import { useDispatch } from "react-redux";



const ChatbotButton = (isLogin : any) => (
  <Link to="/createaicompanion" className="btn btn-primary m-auto w-50 text-center justify-content-center"><span><i><Icon icon="heroicons:sparkles-solid" /></i></span> Create personalized bot</Link>
  
);

type ChatbotOption = {
  value: ChatbotSearchData,
  label: string
}

var queriedString = "";
const ExploreBanner = () => {
  const [isLoadBarLoading, setIsLoadBarLoading] = useState(true);
  const [displayedChatbotNames, setDisplayedChatbotNames] = useState<ChatbotOption[]>([]);
  const dispatch = useDispatch();
  const options: CustomOptionType[] = [
    { label: "Orbofi", value: "Orbofi" },
    { label: "Coffee", value: "coffee" },
    { label: "Apple", value: "apple" },
  ];
  useEffect(() => {
    onLoadMoreData();
  }, []);
  const handleChange = (
    selectedOption: ValueType<CustomOptionType>,
    actionMeta: ActionMeta<CustomOptionType>
  ) => {
  };
  const initialOptions = [
    { 'Unnamed: 0.3': '0', 'name': 'The Beatles', 'img': '', 'description': "", 'id': '', 'priority': '', 'subcategory': '', 'Unnamed: 0': 'nan', 'name_lowercase': 'the beatles' },
    // Add more initial options as needed
  ];
  const onLoadMoreData = () => {
    return GetSemanticSearchList(queriedString).then((response: ChatbotSearchData[]) => {
      const optionsChatbots = [] as ChatbotOption[];
      for (const chatbot of response) {
        optionsChatbots.push({ value: chatbot, label: chatbot.name });
      }
      setIsLoadBarLoading(false);
      setDisplayedChatbotNames(optionsChatbots.slice(0, 35)); // Initially display only the first 5 chatbots
      return optionsChatbots
    });
  }
  const handleInputChange = (inputValue: string) => {
    const lowerCaseInputValue = inputValue.toLowerCase();
    setSearchString(lowerCaseInputValue);
    queriedString = lowerCaseInputValue;
  };
  function OpenSelectedChatbot(selectedOption: ChatbotOption) {
    if (selectedOption === null) {
      return;
    }
    const selectedChatbot = selectedOption?.value;
    window.location.href = `/chat/${selectedChatbot?.string_id}`;

  }
  const [searchString, setSearchString] = useState("aang");
  const navigate = useNavigate();
  const handleChatbotSearchClick = (e: any) => {
    if (e.key === "Enter") {
      navigate(`/search/${searchString}`);
    }

  };
  function OnOpenLoginPopup() {
    dispatch(showPopUpAction(true));
  }
  if (location.pathname.toLowerCase().split("/")[1] !== "chat") {
    return (
      <div className="ExploreBanner-Main">
        <div className="ExploreBanner-Info">
          <h1>{t("explore_the_ai_agents")}</h1>
          <div className="GenerateAnything-Search-box">
            <div className="searchmain">
              <AsyncSelect
                isLoading={isLoadBarLoading}
                isClearable={true}
                value={[]}
                defaultOptions={initialOptions}
                loadOptions={onLoadMoreData}
                isSearchable={true}
                placeholder="Search AI clones and agents"
                onChange={(selectedOption) => OpenSelectedChatbot(selectedOption)}
                onInputChange={handleInputChange}
                onKeyDown={handleChatbotSearchClick}
                formatOptionLabel={({ value, label }) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {value && value.img && <img src={value.img} style={{ marginRight: "10px", width: "32px", height: "32px" }} />}
                    {label}
                  </div>
                )}
                styles={{
                  input: (provided) => ({
                    ...provided,
                    color: "white",
                    backgroundColor: "transparent",
                  }),
                }}
                components={{
                  Menu: props => (
                    <components.Menu {...props} className="searchmenu">
                      {props.children}
                      <div className="ChatCreateBtnSearch">
                        {localStorage.getItem("oauthToken") ? <ChatbotButton isLogin={true}/> : <Link to="#" className="btn btn-primary m-auto w-50 text-center justify-content-center" onClick={OnOpenLoginPopup}><span><i><Icon icon="heroicons:sparkles-solid" /></i></span> Create personalized bot</Link>}
                      </div>
                    </components.Menu>
                  )
                }}
              />
            </div>
            <PrimaryBtn child={<> <i><Icon icon="iconamoon:search" /></i></>} className={'HomeStartAI-btn ExploreSearch'} linkName={'/'} />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ExploreBanner;
