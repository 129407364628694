import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter} from "react-router-dom";
//import { PersistGate } from "redux-persist/integration/react";
import {Provider} from "react-redux";
import ScrollToTop from "./Utils/ScrollToTop";
import "./index.css";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import {store} from "./Redux/store";

import spanish_translations from "./Texts/es/spanish_translations.json";
import english_translations from "./Texts/en/english_translation.json";
import "./Assets/NewFonts/style.css";
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
    .use(LanguageDetector)
    .init({
        interpolation:
            {
                escapeValue: false
            },
        lng: "en",
        fallbackLng: 'en',
        resources:
            {
                es:
                    {
                        translation: spanish_translations,
                    },
                en:
                    {
                        translation: english_translations,
                    },
            },
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'],
        }
    });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <I18nextProvider i18n={i18next}>
        <Provider store={store}>
            {/*<PersistGate loading={<div>Loading...</div>} persistor={persistor}	>*/}
            <BrowserRouter>
                <ScrollToTop/>
                <App/>
            </BrowserRouter>
            {/*</PersistGate>*/}
        </Provider>
    </I18nextProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();