import {bsc_test_payment_contract, dummy_token_bsc_test, obi_token_eth_mainnet,eth_main_payment_contract, bsc_main_payment_contract, obi_token_bsc_mainnet} from "../Contracts/payments/address";
import paymentContract from "../Contracts/payments/paymentsMainABI.json";

import OBIBscMainTokenContract from "../Contracts/payments/OBITokenBSCMAINABI.json";



 export interface NetworkConfig {
  paymentContractAddress: string;
  tokenAddress: string;
  tokenABI: any[];
  paymentABI: any[];
}

const networkConfigs: { [key: number]: NetworkConfig } = {
  1: { // Ethereum Mainnet
    paymentContractAddress: eth_main_payment_contract,
    tokenAddress: obi_token_eth_mainnet,
    tokenABI: OBIBscMainTokenContract.abi,
    paymentABI: paymentContract.abi
  },
  97: { // BSC Testnet
    paymentContractAddress: bsc_test_payment_contract,
    tokenAddress: dummy_token_bsc_test,
    tokenABI: OBIBscMainTokenContract.abi,
    paymentABI: paymentContract.abi
  },
  
  56: { // BSC Testnet
    paymentContractAddress: bsc_main_payment_contract,
    tokenAddress: obi_token_bsc_mainnet,
    tokenABI: OBIBscMainTokenContract.abi,
    paymentABI: paymentContract.abi
  },
};

export const getNetworkConfig = (chainId: number): NetworkConfig | null => {
  return networkConfigs[chainId] || null;
};



