
import { Icon } from "@iconify/react";
import { t } from "i18next";
import PrimaryBtn from "../../CommonBtn/PrimaryBtn";
import "./ExploreBanner.css";

const UpgradeCreateMoreExploreCTA = () => {

  return (
    <div className="UpgradeCreateMoreAIBg">
      <div className="UpgradeCreateMoreAIBg-Info">
        <i><Icon icon="heroicons:sparkles-solid" /></i>
        <h5>Upgrade</h5>
        <p>{t("Upgrade_to_create_and_discover_more_powerful_and_more_personalized_AI_agents")}</p>
        <PrimaryBtn child={<>{t("upgrade_your_account")} </>} className={""} linkName={"/"} onClick={() => { }} />
      </div>
    </div>
  );
};

export default UpgradeCreateMoreExploreCTA;
