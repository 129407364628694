import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { t } from "i18next";
import axios from "axios";

interface InterestsProps {
  CategoryId: number;
  Name: string;
  checked: boolean;
}

function InterestsPopup(props: any) {

  const [allInterestsList, setAllInterestsList] = useState<InterestsProps[]>([]);
  const [selectedInterests, setSelectedInterests] = useState<number[]>([]);

  const onNextStep = () => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` } };

    axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}setUserCategories`, { category_ids: selectedInterests }, config)
      .then(() => {
        // Handle success if needed
      }).catch((error) => {
        console.error("Error setting categories:", error);
      });

    props.nextStep(props);
  };

  const onPrevStep = () => {
    props.previousStep(props);
  };
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}categories`)
      .then((response) => {
        setAllInterestsList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);
  const handleSelectInterest = (index: number) => {
    const intArray = [...allInterestsList];
    intArray[index].checked = !intArray[index].checked;
    setAllInterestsList(intArray);

    const selectedIds = intArray.filter(item => item.checked).map(item => item.CategoryId);
    setSelectedInterests(selectedIds);
  };
  return (
    <div className="InterestsPopup">
      <Button variant="" onClick={onPrevStep} className="btn-wizard-back"><Icon
        icon="eva:arrow-back-fill" /></Button>
      <div className="login-info">
        <span><Image src="/assets/icons/orbofi-icon-white.svg" /></span>
        <h5>{t("interests")}</h5>
        <p>{t("pick_things_youd_like_to_see")}</p>
      </div>
      <Form className="InterestsPopup-form">
        <div className="InterestsPopupList">
          <h5></h5>   
          <ul>
            <li>
              <label htmlFor="Interests01">
                <input type="checkbox" id="Interests01" />
                <p>Chat, and ask AI agents</p>
              </label>
            </li>
            <li>
              <label htmlFor="Interests02">
                <input type="checkbox" id="Interests02" />
                <p>create AI agents and AI clones</p>
              </label>
            </li>
            <li>
              <label htmlFor="Interests03">
                <input type="checkbox" id="Interests03" />
                <p>generate images</p>
              </label>
            </li>
            <li>
              <label htmlFor="Interests003">
                <input type="checkbox" id="Interests003" />
                <p>search for information</p>
              </label>
            </li>
            <li>
              <label htmlFor="Interests04">
                <input type="checkbox" id="Interests04" />
                <p>generate long-form text for marketing, or school</p>
              </label>
            </li>
            <li>
              <label htmlFor="Interests05">
                <input type="checkbox" id="Interests05" />
                <p>use AI for work</p>
              </label>
            </li>
            <li>
              <label htmlFor="Interests06">
                <input type="checkbox" id="Interests06" />
                <p>generate code</p>
              </label>
            </li>
            <li>
              <label htmlFor="Interests07">
                <input type="checkbox" id="Interests07" />
                <p>create apps</p>
              </label>
            </li>
          </ul> 
        </div>
        <hr />
        <div className="InterestsPopupList">
          <h5>{t("alltime_favorites")}</h5>
          <ul>
            {allInterestsList.map((item, index) => (
              <li key={`Interests-${index}`}>
                <label htmlFor={`Interests-${index}`}>
                  <input type="checkbox" onChange={() => handleSelectInterest(index)}
                    name={`Interests-${index}`} id={`Interests-${index}`}
                    checked={item.checked} />
                  <p>{item.Name}</p>
                </label>
              </li>
            ))}
          </ul>
        </div>
        <Button variant="" onClick={onNextStep} className="btn-primary btn-full">{t("continue")}</Button>
      </Form>
    </div>
  );
}

function InterestItem({ index, category }: { index: number, category: Category }): JSX.Element {
  return (
    <li>
      <label htmlFor={"Interests-" + index}>
        <input type="checkbox" name="Interests" id={"Interests-" + index} onChange={ToggleInterests} />
        <p>{category.Name}</p>
      </label>
    </li>
  );
}

function ToggleInterests(e: React.ChangeEvent<HTMLInputElement>) {

}

function UploadInterests(categories: Category[]) {

}



export default InterestsPopup;
